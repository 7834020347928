<template>
  <div class="d-flex justify-content-end min-w-40px">
    <button
      class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
      :disabled="isSettingDisabled"
      ref="menuDropdown"
      @click="openMenu"
    >
      <KTIcon icon-name="setting-4" icon-class="fs-3" />
    </button>

    <!--begin::Menu-->
    <div
      v-if="isMenuVisible || isMenuPreload"
      class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-4 w-200px mt-2 fs-6"
      data-kt-menu="true"
    >
      <!--begin::Menu search-->
      <div class="menu-item px-5">
        <div class="menu-content text-start text-muted pb-2 px-5 fs-7 text-uppercase">
          {{ $t('actions.edit') }}
        </div>
      </div>
      <!--end::Menu search-->
      
      <!--begin::Menu edit-->
      <div
        v-if="dataStore && dataStore.id"
        class="menu-item px-5"
      >
        <div 
          data-bs-toggle="modal"
          data-bs-target="#modal_items"
          class="menu-link px-4"
          @click="handleModal('main')"
        > 
          <KTIcon icon-name="file-sheet" icon-class="fs-3 me-3" />
          {{ $t('pages.items.modal.stepper.main') }}
        </div>
      </div>
      <!--end::Menu edit-->
  
      <!--begin::Menu links-->
      <div class="menu-item px-5">
        <div
          id="target-button-links"
          data-bs-toggle="modal"
          data-bs-target="#modal_items"
          class="menu-link px-4"
          @click="handleModal('links')"
        > 
          <KTIcon icon-name="mouse-square" icon-class="fs-3 me-3" />
          {{ $t('pages.items.modal.stepper.links') }}
        </div>
      </div>
      <!--end::Menu links-->
      
      <!--begin::Menu filter-->
      <div class="menu-item px-5">
        <div 
          data-bs-toggle="modal"
          data-bs-target="#modal_items"
          class="menu-link px-4"
          @click="handleModal('filters')"
        > 
          <KTIcon icon-name="filter" icon-class="fs-3 me-3" />
          {{ $t('pages.items.modal.stepper.filters') }}
        </div>
      </div>
      <!--end::Menu filter-->
      
      <!--begin::Menu separator-->
      <div class="separator my-3"></div>
      <!--end::Menu separator-->
      
      <!--begin::Menu clear-->
      <div class="menu-item px-5">
        <div class="menu-content text-start text-muted pb-2 px-5 fs-7 text-uppercase">
          {{ $t('actions.clear') }}
        </div>
      </div>
      <!--end::Menu clear-->
      
      <!--begin::Products clear-->
      <div class="menu-item px-5">
        <ClearImage 
          class="menu-link px-4"
          :itemData="dataStore"
        >
          <KTIcon icon-name="picture" icon-class="fs-3 me-3" />
          {{ $t('pages.searchId.actionsMenu.image') }}
        </ClearImage>
      </div>
      <!--end::Products clear-->
        
      <template v-if="!onlySearch">
        <!--begin::Products clear-->
        <div class="menu-item px-5">
          <ClearItems 
            class="menu-link px-4"
            :itemData="dataStore"
            @item-clear-products="$emit('data-clear-products')"
          >
            <KTIcon icon-name="tablet-text-down" icon-class="fs-3 me-3" />
            {{ $t('pages.searchId.actionsMenu.products') }}
          </ClearItems>
        </div>
        <!--end::Products clear-->

        <!--begin::Analytics clear-->
        <div class="menu-item px-5">
          <ClearAnalytics 
            class="menu-link px-4"
            :itemData="dataStore"
            @item-clear-analytics="$emit('data-clear-analytics')"
          >
            <KTIcon icon-name="chart-pie-simple" icon-class="fs-3 me-3" />
            {{ $t('pages.searchId.actionsMenu.analytics') }}
          </ClearAnalytics>
        </div>
        <!--end::Analytics clear-->
      </template>
      
      <div class="separator my-3"></div>
      
      <!--begin::Menu archive-->
      <div class="menu-item px-5">
        <ArchiveSearch 
          class="menu-link px-4"
          :itemData="dataStore"
        >
          <KTIcon icon-name="save-deposit" icon-class="fs-3 me-3" />
          {{ archiveLabel }}
        </ArchiveSearch>
      </div>
      <!--end::Menu archive-->
      
      <!--begin::Menu delete-->
      <div class="menu-item px-5">
        <DeleteItem 
          class="menu-link text-danger px-4"
          :data="dataStore"
          type="item"
        >
          <KTIcon icon-name="trash" icon-class="fs-3 me-3 text-danger" />
          {{ $t('actions.delete') }}
        </DeleteItem>
      </div>
      <!--end::Menu delete-->

    </div>
    <!--end::Menu-->
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useMenu } from "@/composables/useMenu";
import { useI18n } from "vue-i18n";

import ArchiveSearch from "@/components/actions/ArchiveSearch.vue";
import DeleteItem from "@/components/actions/DeleteItem.vue";
import ClearImage from "@/components/actions/ClearImage.vue";
import ClearItems from "@/components/actions/ClearItems.vue";
import ClearAnalytics from "@/components/actions/ClearAnalytics.vue";

export default defineComponent({
  name: "actions-search-dropdown",
  components: {
    ArchiveSearch,
    DeleteItem,
    ClearImage,
    ClearItems,
    ClearAnalytics
  },
  props: {
    dataStore: {
      type: Object,
      required: true
    },
    onlySearch: {
      type: Boolean,
      required: false,
      default: false
    },
    isSettingDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isMenuPreload: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  emits: [
    'data-modal',
    'data-clear-products',
    'data-clear-analytics'
  ],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { isMenuVisible, menuDropdown, openMenu } = useMenu();

    const archiveLabel = computed(() => {
      return props.dataStore.archive == 1 ? t('actions.dearchive') : t('actions.archive');
    });

    const handleModal = (step) => {
      emit('data-modal', { item: props.dataStore, step: step });
    };
        
    return {
      isMenuVisible,
      menuDropdown,
      openMenu,
      archiveLabel,
      handleModal,
    };
  }
});
</script>
