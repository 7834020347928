<template>
  <div class="row g-4">
    <div v-for="(oauth, index) in userStore.oauthStore" :key="index" class="col">
      <div
        :ref="setOAuthRef"
        @click="toogleOauth(oauth, index)"
        class="btn btn-flex flex-center btn-light btn-lg w-100"
      >
        <img
          :src="getOAuthIconPath(oauth.icon)"
          class="h-15px me-3"
          :alt="`Logo ${oauth.name}`"
        />
        {{ oauth.name }}
        
        <span class="indicator-progress">
          <span class="spinner-border spinner-border-sm align-middle ms-3"></span>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { getAssetPath } from "@/core/helpers/assets";
import { useAuthStore } from "@/stores/auth";
import { useThemeStore } from "@/stores/theme";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { addToast } from '@/utils/toastManager';

export default defineComponent({
  name: "oauth",
  setup() {
    const { t } = useI18n();
    const userStore = useAuthStore();
    const themeStore = useThemeStore();
    const router = useRouter();
    
    const oAuthRefs = ref<(HTMLElement | null)[]>([]);
    
    const setOAuthRef = (el: HTMLElement | null) => {
      oAuthRefs.value.push(el);
    };
    
    const errorSwal = (error?: string) => {
      Swal.fire({
        text: error || t('messages.text.error.oauth'),
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: t('messages.button.okGotIt'),
        heightAuto: false,
        customClass: {
          confirmButton: 'btn btn-danger',
        },
      });
    };

    const getOAuthIconPath = (id: string) => {
      if (id === 'google') {
        return getAssetPath('media/svg/brand-logos/google-icon.svg');
      }
      if (id === 'telegram') {
        return getAssetPath('media/svg/brand-logos/telegram.svg');
      }
      if (id === 'apple') {
        return themeStore.resolvedMode === 'dark' 
          ? getAssetPath('media/svg/brand-logos/apple-black-dark.svg') 
          : getAssetPath('media/svg/brand-logos/apple-black.svg');
      }
      return '';
    };
    
    const toogleOauth = async (oauth: any, index: number) => {
      const spinnerLink = oAuthRefs.value[index];
      spinnerLink.setAttribute("data-kt-indicator", "on");
      
      // Сохраняем объект в sessionStorage в виде строки JSON
      sessionStorage.setItem('oauthData', JSON.stringify({
          type: 'sign-in',
          provider: oauth.name,
          backurl: { path: '/dashboard' }
        }));
      
      window.location.href = oauth.link;
      
      spinnerLink.setAttribute("data-kt-indicator", "off");
    };
    
    onMounted(async () => {
      try {
        await userStore.oauthList();
      } catch (error) {
        errorSwal(error as string);
      }
    });
    
    return {
      getOAuthIconPath,
      toogleOauth,
      setOAuthRef,
      userStore,
    };
  },
});
</script>