<template>
  <div class="d-flex flex-column">
    <div class="text-gray-800 fs-2 fw-bold me-1 mb-2">
      {{ item.title }}
    </div>
  
    <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
      <GroupLink
        :item="item"
        :group="group"
        class="me-5"
      />
      <WishPriceDisplay
        :item="item"
        class="me-5"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import GroupLink from '@/components/items/GroupLink.vue';
import WishPriceDisplay from '@/components/items/WishPriceDisplay.vue';

export default defineComponent({
  name: 'item-header',
  components: {
    GroupLink,
    WishPriceDisplay,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
  },
});
</script>
