<template>
  <div @click="confirmAction">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue';
import { useI18n } from "vue-i18n";
import { useItemStore } from "@/stores/item";
import { addToast } from '@/utils/toastManager';
import Swal from "sweetalert2/dist/sweetalert2.js";

const props = defineProps({
  itemData: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();
const itemStore = useItemStore();

// Получаем объект функций из родителя
const actions = inject('actions');

const actionType = computed(() => props.itemData.archive == 1 ? 'dearchive' : 'archive');
const archiveToggle = computed(() => props.itemData.archive == 1 ? 'off' : 'on');

const confirmAction = () => {
  Swal.fire({
    text: t(`messages.text.warning.${actionType.value}`, { title: props.itemData.title }),
    icon: 'warning',
    buttonsStyling: false,
    showCancelButton: true,
    confirmButtonText: t(`messages.button.${actionType.value}`),
    cancelButtonText: t('messages.button.noCancel'),
    heightAuto: false,
    customClass: {
      confirmButton: 'btn btn-danger',
      cancelButton: 'btn btn-secondary',
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        await itemStore.archiveSearch({ id: props.itemData.id, toggle: archiveToggle.value }, actionType.value);
        
        if (!Object.keys(itemStore.errors).length) {
          // После подтверждения выполняем функцию из родителя
          const action = actions['item-archived'];
          action(props.itemData.id);

          addToast(t('messages.any.success'), t(`messages.text.success.${actionType.value}`), 'success');
        } else {
          handleError(itemStore.errors);
        }
      } catch (error) {
        handleError(error)
      }
    }
  });
};

// Константа для обработки ошибок
const handleError = (error) => {
  Swal.fire({
    text: error,
    icon: "error",
    buttonsStyling: false,
    confirmButtonText: t('messages.button.okGotIt'),
    heightAuto: false,
    customClass: {
      confirmButton: "btn btn-danger",
    },
  }).then(() => {
    itemStore.errors = [];
  });
}
</script>
