<template>
  <div
    class="modal fade"
    id="modal_offer_unique_words"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <div class="modal-header flex-stack border-bottom-0 px-lg-10 pt-lg-10 pb-0">
          <h2>{{ $t('messages.text.warning.deleteAnalyticsItems') }}</h2>
          <div
            class="btn btn-sm btn-icon btn-light btn-active-color-primary" 
            data-bs-dismiss="modal"
          >
            <KTIcon icon-name="cross" icon-class="fs-1" />
          </div>
        </div>

        <div class="modal-body p-lg-10 pt-lg-3">
          <!--begin::Wrapper-->
          <div class="d-flex flex-column w-100">
            <!--begin::Alert-->
            <div class="alert alert-dismissible bg-light-success d-flex align-items-center p-5 mt-5 mb-8">
                <KTIcon icon-name="like-shapes" icon-class="fs-2hx text-success me-4" />
                <div class="d-flex flex-column">
                    <h4 class="mb-1 text-gray-700">
                      {{ $t('pages.searchId.layoutAnalytics.modal.uniqueWords.title') }}
                    </h4>
                    <span class="fw-semibold text-gray-700">
                      {{ $t('pages.searchId.layoutAnalytics.modal.uniqueWords.subtitle') }}
                    </span>
                </div>
            </div>
            <!--end::Alert-->
            
            <!-- Поле для обязательных слов -->
            <div class="mb-5 mb-md-7 fv-row">
              <label class="form-label fw-bold">
                {{ $t('pages.items.modal.label.requiredWords') }}
              </label>
              <Tagify
                mode="input"
                class="form-control mb-2"
                :placeholder="$t('pages.items.modal.placeholder.requiredWords')"
                name="required_words"
                v-model="uniqueWords['required_words']"
                ref="tagifyRequired"
              />
              <div class="text-muted fs-7">
                {{ $t('pages.items.modal.subLabel.requiredWords') }}
              </div>
              
              <div class="pt-3" v-if="newUniqueWords['required_words']?.length > 0">
                <div class="text-gray-700 fw-semibold">
                  {{ $t('pages.searchId.layoutAnalytics.modal.uniqueWords.requiredWords') }}
                </div>
                <div class="">
                  <span
                    v-for="(word, index) in newUniqueWords['required_words']"
                    :key="index"
                    class="btn btn-light-primary fs-6 fw-semibold py-1 px-3 mt-3 me-2"
                    @click="addTagToRequired(word)"
                  >{{ word }}</span>
                </div>
              </div>
            </div>
            
            <!-- Поле для исключенных слов -->
            <div class="mt-2 mb-5 mb-md-7 fv-row">
              <label class="form-label fw-bold">{{ $t('pages.items.modal.label.excludedWords') }}</label>
              <Tagify
                mode="input"
                class="form-control mb-2"
                :placeholder="$t('pages.items.modal.placeholder.excludedWords')"
                name="excluded_words"
                v-model="uniqueWords['excluded_words']"
                ref="tagifyExcluded"
              />
              <div class="text-muted fs-7">
                {{ $t('pages.items.modal.subLabel.excludedWords') }}
              </div>
              
              <div class="pt-3" v-if="newUniqueWords['excluded_words']?.length > 0">
                <div class="text-gray-700 fw-semibold">
                  {{ $t('pages.searchId.layoutAnalytics.modal.uniqueWords.excludedWords') }}
                </div>
                <div class="">
                  <span
                    v-for="(word, index) in newUniqueWords['excluded_words']"
                    :key="index"
                    class="btn btn-light-primary fs-6 fw-semibold py-1 px-3 mt-3 me-2"
                    @click="addTagToExcluded(word)"
                  >{{ word }}</span>
                </div>
              </div>
            </div>

          </div>
          <!--end::Wrapper-->          
          
          <!--begin::Actions-->
          <div class="d-flex flex-center pt-5">
            <!--begin::Wrapper-->
            <button
              type="button"
              class="btn btn-danger me-3"
              data-bs-dismiss="modal"
              @click="onSubmit"
            >
              {{ $t('messages.button.delete') }}
            </button>
            <!--end::Wrapper-->
            
            <!--begin::Wrapper-->
            <button
              type="button"
              class="btn btn-secondary me-3"
              data-bs-dismiss="modal"
            >
              {{ $t('messages.button.noCancel') }}
            </button>
            <!--end::Wrapper-->
          </div>
          <!--end::Actions-->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { valuesToString } from "@/utils/helpers/itemHelpers";
import Tagify from '@/components/Tagify.vue';

export default defineComponent({
  name: "offer-unique-words-modal",
  components: {
    Tagify,
  },
  props: {
    newUniqueWords: {
      type: Object,
      required: true,
    },
    nowUniqueWords: {
      type: Object,
      required: true,
    },
  },
  emits: ['remove-items-modal'],
  setup(props, { emit }) {
    const tagifyRequired = ref();
    const tagifyExcluded = ref();
    
    const uniqueWords = ref({
      required_words: props.nowUniqueWords['required_words'] || [],
      excluded_words: props.nowUniqueWords['excluded_words'] || []
    });

    // Функция для добавления тега в Tagify для обязательных слов
    const addTagToRequired = (tag: string) => {
      tagifyRequired.value?.addTags([tag]);
    };
    
    // Функция для добавления тега в Tagify для исключенных слов
    const addTagToExcluded = (tag: string) => {
      tagifyExcluded.value?.addTags([tag]);
    };
    
    const onSubmit = async() => {
      // Отправка событий с обработанными словами
      await emit(
        'remove-items-modal',
        valuesToString(uniqueWords.value['required_words']),
        valuesToString(uniqueWords.value['excluded_words']),
        true
      );
      
      // Устанавливаем таймер для сброса uniqueWords через 1 секунду
      setTimeout(() => {
        uniqueWords.value = {
          required_words: props.nowUniqueWords['required_words'],
          excluded_words: props.nowUniqueWords['excluded_words']
        };
      }, 1000);
    };
    
    // Следим за изменениями props.nowUniqueWords и обновляем Tagify
    watch(() => props.nowUniqueWords, (newVal) => {
      uniqueWords.value = {
        required_words: newVal['required_words'],
        excluded_words: newVal['excluded_words']
      };

      tagifyRequired.value?.resetTags(uniqueWords.value['required_words']);
      tagifyExcluded.value?.resetTags(uniqueWords.value['excluded_words']);
    });
    
    return {
      uniqueWords,
      addTagToRequired,
      addTagToExcluded,
      tagifyRequired,
      tagifyExcluded,
      onSubmit,
    };
  },
});
</script>