const cn = {
  loadingText: "加载中...",
  returnHome: "返回主页",
  goHome: "转到主页",
  offline: "没有互联网连接",

  theme: {
    light: "浅色",
    dark: "深色",
    system: "系统"
  },

  info: "信息",
  // terms: "条款",
  about: "关于项目",
  contact: "联系",
  support: "技术支持",
  userAgreement: "用户协议",
  privacyPolicy: "隐私政策",

  city: "城市",
  phone: "电话",
  email: "电子邮箱",
  avatar: "头像",
  search: "搜索",

  actions: {
    edit: "编辑",
    archive: "归档",
    dearchive: "取消归档",
    delete: "删除",
    clear: "清除"
  },
  buttons: {
    discard: "放弃",
    cancel: "取消",
    submit: "提交",
    save: "保存",
    wait: "请稍候...",
    continue: "继续",
    back: "返回",
    connect: "连接",
    allow: "允许",
    update: "更新",
    change: "更改",
    agree: "同意",
    close: "关闭",
    remove: "删除"
  },
  messager: {
    telegram: "Telegram",
    google: "Google",
    apple: "Apple"
  },
  cookieMessage: {
    introText: "我们使用",
    cookieText: "cookie"
  },
  messages: {
    text: {
      success: {
        signIn: "您已成功登录！",
        signUp: "注册成功！",
        passwordReset: "邮件已发送到您的邮箱！",
        passwordChange: "您已成功更改密码！",
        emailChange: "您已成功更改电子邮箱！",
        userUpdate: "个人资料已更新！",

        addGroup: "组添加成功！",
        editGroup: "组修改成功！",
        deleteGroup: "组删除成功！",

        addSearch: "搜索添加成功！",
        editSearch: "搜索修改成功！",
        archive: "搜索已归档！",
        dearchive: "搜索已取消归档！",
        deleteSearch: "搜索已删除！",

        clearItems: "商品列表已清空！",
        clearAnalytics: "分析已清空！",
        
        deleteAnalyticsItems: "商品已删除！",

        switchOnNotices: "已成功开启 {messager} 的通知。",
        switchOffNotices: "已成功关闭 {messager} 的通知。",
        switchOffAccount: "帐户 {service} 已成功断开。",
        switchOnAccount: "帐户 {service} 已成功连接。"
      },
      error: {
        oauth: "验证错误，请重试！",

        addGroup: "添加组时出错，请重试！",
        editGroup: "修改组时出错，请重试！",
        deleteGroup: "删除组时出错，请重试！",

        addSearch: "添加搜索时出错，请重试！",
        editSearch: "修改搜索时出错，请重试！",
        archive: "归档搜索时出错，请重试！",
        dearchive: "取消归档搜索时出错，请重试！",
        deleteSearch: "删除搜索时出错，请重试！",

        clearItems: "清空商品列表时出错，请重试！",
        clearAnalytics: "清空分析时出错，请重试！",

        deleteItems: "删除商品时出错，请重试！",
        
        switchOnNotices: "启用 {messager} 的通知时出错，请重试！",
        switchOffNotices: "禁用 {messager} 的通知时出错，请重试！",
        switchOnAccount: "连接 {service} 帐户时出错，请重试！",
        switchOffAccount: "断开 {service} 帐户时出错，请重试！",
        
        geoCoords: "获取坐标时出错！",
        geoCity: "获取城市时出错！",
        geoCityNotFound: "找不到城市"
      },
      warning: {
        passwordChangeFail: "请使用邮件中的链接来重置密码！",

        addGroup: "同名的组已存在！",
        invalidGroupName: "组名不能为数字！",
        maxLengthExceeded: "组名不能超过 {maxLength} 个字符！",

        deleteGroup: "确定要删除组：{title} 吗？",
        deleteSearch: "确定要删除搜索：{title} 吗？",
        archive: "确定要归档搜索：{title} 吗？",
        dearchive: "确定要取消归档搜索：{title} 吗？",
        clearItems: "确定要清空 {title} 的商品列表吗？",
        clearAnalytics: "确定要清空 {title} 的分析数据吗？",
        
        deleteAnalyticsItems: "删除选中的商品？",

        switchOffNotices: "确定要关闭 {messager} 的通知吗？",
        switchOffAccount: "确定要断开 {service} 帐户吗？",
        geoNotAvailable: "地理定位不可用！"
      }
    },
    button: {
      okGotIt: "好的，知道了！",
      noCancel: "不，取消",
      tryAgain: "再试一次！",
      archive: "归档！",
      dearchive: "取消归档！",
      delete: "删除！",
      clear: "清除！",
      switchOff: "关闭"
    },
    any: {
      success: "成功",
      warning: "警告",
      error: "错误"
    }
  },
  menu: {
    home: "主页",
    dashboard: "仪表盘",
    layoutBuilder: "布局构建器",
    groups: "组",
    search: "搜索",
    itemsId: "商品",
    profile: "个人资料",
    setting: "设置",
    userAccount: {
      profile: "个人资料",
      language: "语言",
      logs: "会话记录",
      notifications: "通知",
      settings: "设置",
      signOut: "登出"
    }
  },
  navbar: {
    alert: {
      notifications: "通知"
    }
  },
  toolbar: {
    upgradePlan: "订阅"
  },
  pages: {
    authentication: {
      title: "快速高效的个人助手",
      subtitle: "Palert 是您的在线购物个人助手，为您节省大件商品的购买开支。通过我们的应用程序，您可以随时了解您喜欢商品的最佳优惠，不会错过任何一个划算的交易！",
      signIn: {
        pageTitle: "登录",
        subtitle: "通过服务",
        noAccount: "没有帐户？",
        signUp: "注册",
        forgotPassword: "重置密码",
        button: "登录"
      },
      signUp: {
        pageTitle: "注册",
        subtitle: "通过服务",
        alreadyAccount: "已有帐户？",
        signIn: "登录",
        firstName: "名字",
        lastName: "姓氏",
        textPassword: "使用8个或更多字符：字母、数字和符号。",
        textAgree: "我接受 ",
        textTermsUserAgreement: "Palert 系统和个人助手的用户协议",
        textAgreePrefix: " 并阅读了 ",
        textTermsPrivacyPolicy: "隐私政策",
        button: "注册"
      },
      passwordReset: {
        pageTitle: "重置密码",
        subtitle: "输入您的电子邮箱以重置密码",
        button: "重置",
        wait: "重置中..."
      },
      passwordChange: {
        pageTitle: "设置新密码",
        subtitle: "您已重置密码？"
      },
      passwordConfirmation: {
        pageTitle: "密码已更改",
        title: "密码已更改",
        subtitle: "您的密码已成功更改！"
      },
      welcome: {
        pageTitle: "欢迎",
        title: "欢迎使用 Palert",
        subtitle: "欢迎您加入我们的项目。您的帐户已成功创建。激活链接和后续说明已发送到您注册时提供的邮箱。"
      },
      verifyEmail: {
        pageTitle: "邮箱验证",
        title: "邮箱已验证",
        titleError: "邮箱验证错误",
        subtitle: "您的邮箱已成功验证！",
        processGoHome: "{second} 秒后将跳转到主页。"
      },
      acceptAgreement: {
        pageTitle: "接受用户协议",
        title: "接受用户协议",
        subtitle: "使用 Palert 需要您接受协议。"
      },
      accountDeactivated: {
        pageTitle: "帐户已停用",
        title: "您的帐户已停用",
        subtitle: "感谢您长久以来的陪伴！"
      },
      error404: {
        pageTitle: "错误 404",
        title: "哎呀！",
        subtitle: "我们无法找到该页面。"
      },
      error500: {
        pageTitle: "错误 500",
        title: "系统错误",
        subtitle: "出了点问题！请稍后重试。"
      },
      email: "电子邮箱",
      password: "密码",
      confirmPassword: "确认密码",
      or: "或者",
      wait: "请稍候...",
      errorOAuthLink: "获取身份验证链接时出错："
    },
    dashboard: {
      breadcrumbs: "仪表盘",
      pageTitle: "仪表盘",
      platforms: {
        titleCard: "市场",
        subTitleCard: "最新数据",
        formatter: {
          single: "链接",
          few: "链接",
          many: "链接"
        }
      }
    },
    account: {
      breadcrumbs: {
        items: "个人资料"
      },
      pageTitleOverview: "个人资料",
      pageTitleSettings: "个人资料设置",
      pageTitleNotifications: "通知",
      pageTitleLogs: "日志",
      nav: {
        overview: "概览",
        settings: "设置",
        notifications: "通知",
        logs: "日志"
      },
      layoutOverview: {
        profileDetails: "个人资料详情",
        fullName: "全名",
        mobilePhone: "手机",
        mobileTitle: "电话号码必须有效",
        avatarTitle: "上传适合在 Palert 上显示的图片",
        communication: "沟通"
      },
      layoutSettings: {
        linkedAccounts: "关联帐户",
        numberPhone: "电话号码",
        signInMethod: "登录方式",
        changeEmail: "更改电子邮箱",
        newEmail: "新电子邮箱",

        place: "位置",
        coordinates: "坐标",
        latitude: "纬度",
        longitude: "经度",
        filled: "自动填充",
        filledTitle: "{label} 字段在添加城市时自动填充。",

        updatePassword: "更新密码",
        currentPassword: "当前密码",
        newPassword: "新密码",
        confirmNewPassword: "确认新密码",
        accountNotConnected: "帐户未连接",

        deactivateAccount: "停用帐户",
        deactivateWrapperTitle: "您正在停用您的帐户",
        deactivateWrapperText: "为确保安全，停用帐户需要验证您的电子邮箱。",
        deactivateConfirm: "确认停用帐户"
      },
      layoutNotifications: {
        notifyChannels: "通知渠道",
        notifications: "通知",
        notifyNotLabel: "无",

        infoNotifiTGTitle: "连接 Telegram 通知",
        infoNotifiTGText: "请访问我们的 Telegram 机器人 {telegramBot} 并点击 \"<strong>Start</strong>\"。这样可以让我们向您发送 Telegram 通知。",

        infoConnectedTGTitle: "连接 Telegram 帐户",
        infoConnectedTGText: "请在 ",

        infoConnectedPhoneTitle: "提供电话号码",
        infoConnectedPhoneText: "请在 ",

        infoConnectedLink: "个人资料设置中提供",

        notifyNotDisturb: "勿扰模式",
        notifyNotDisturbText: "启用“勿扰模式”，暂停所有通知，专注完成任务。",
        notifyNotDisturbButton: "暂停通知",

        label: {
          items: "商品",
          bills: "账单",
          newDevice: "新设备",
          news: "新闻"
        },
        subLabel: {
          items: "价格达到期望值时通知",
          bills: "新账单和未付款账单通知",
          newDevice: "新设备登录通知",
          news: "发布新消息时通知"
        }
      },
      layoutLogs: {
        loginSessions: "会话记录",
        titleTable: {
          device: "设备",
          location: "位置",
          browser: "浏览器",
          ip: "IP 地址",
          date: "日期"
        },
        select: {
          day1: "1 天",
          day7: "7 天",
          day30: "30 天"
        }
      }
    },
    groups: {
      breadcrumbs: {
        groups: "组"
      },
      pageTitle: "组管理",
      addGroup: "添加",
      titleCard: "组",
      titleTable: {
        groups: "名称",
        status: "状态",
        action: "操作"
      },
      formatter: {
        total: "总计",
        single: "组",
        few: "组",
        many: "组"
      },
      modal: {
        title: {
          new: "添加新组",
          edit: "编辑组"
        },
        label: {
          description: "描述"
        },
        placeholder: {
          title: "组名称",
          description: "输入描述"
        },
        subLabel: {
          title: "使用易记的名称。",
          description: "请编写描述，以便更好地理解组的内容。",
          pict: "由 ChatGPT 生成"
        },
        other: {
          checkImage: "上传"
        }
      }
    },
    items: {
      breadcrumbs: {
        items: "搜索"
      },
      pageTitle: "监控商品列表",
      addItem: "添加",
      filterItem: "筛选",
      filter: {
        title: "筛选选项",
        groups: "组",
        allGroups: "所有组",
        platforms: "平台",
        wishprice: "期望价格",
        executed: "已执行",
        status: "状态",
        active: "激活",
        archive: "存档",
        any: "任意",
        reset: "重置"
      },
      titleCard: "搜索",
      titleCardLast: "最近搜索",
      titleTable: {
        search: "搜索",
        price: "最低价格",
        platform: "平台",
        items: "商品",
        updated: "更新",
        chart: "图表",
        status: "状态",
        action: "操作"
      },
      formatter: {
        total: "总计",
        single: "商品",
        few: "商品",
        many: "商品"
      },
      active: "激活",
      deactive: "未激活",
      archive: "已归档",
      isLinks: "没有链接",
      modal: {
        title: {
          new: "添加新搜索",
          edit: "编辑搜索"
        },
        stepper: {
          main: "参数",
          links: "链接",
          filters: "筛选器"
        },
        label: {
          requiredWords: "必须包含的词",
          excludedWords: "排除的词",
          link: "链接或 SKU",
          wishprice: "期望价格",
          group: "组"
        },
        placeholder: {
          title: "搜索名称",
          requiredWords: "请输入词",
          excludedWords: "请输入词",
          wishprice: "10,000 ₽",
          group: {
            add: "输入组名称",
            select: "选择组",
            emptyList: "组列表为空",
            notFound: "未找到组"
          }
        },
        subLabel: {
          title: "请输入搜索名称，AI 将根据此名称过滤市场商品。",
          requiredWords: "请输入必须包含在商品名称中的词。",
          excludedWords: "请输入不应出现在商品名称中的词。",
          link: "链接或 {platform} 上的 SKU。",
          wishprice: "请输入达到此价格时通知您的价格。",
          group: {
            add: "创建组以添加搜索。",
            select: "选择组以添加搜索。"
          },
          pict: "从商品中导入"
        },
        other: {
          optionGroup: "选择组...",
          checkImage: "上传"
        }
      }
    },
    searchId: {
      breadcrumbs: {
        items: "商品"
      },
      pageTitleList: "商品列表",
      pageTitleAnalytics: "商品分析",
      bestOffer: "最佳报价",
      added: "已添加",
      modified: "已修改",
      requiredWords: "必须包含的词",
      excludedWords: "排除的词",
      itemRemoved: "商品已删除",
      actionsMenu: {
        search: "搜索",
        products: "商品",
        analytics: "分析"
      },
      nav: {
        list: "列表",
        analytics: "分析"
      },
      layoutList: {
        total: "总计",
        active: "所有",
        allPlatforms: "所有平台",
        titleCard: "商品",
        titleTable: {
          items: "名称",
          price: "价格",
          platform: "平台",
          brand: "品牌",
          rating: "评分",
          ai_score: "AI 评分",
          sku: "SKU",
          date: "更新日期"
        }
      },
      layoutAnalytics: {
        bestGraph: {
          title: "价格历史",
          subtitle: "范围：{range}",
          period: {
            year: "年",
            month: "月",
            week: "周"
          }
        },
        modal: {
          uniqueWords: {
            title: "推荐",
            subtitle: "为避免在搜索中再次出现相似商品，添加必须包含或排除的词。",
            requiredWords: "推荐添加到必须包含的词中",
            excludedWords: "推荐添加到排除的词中"
          }
        }
      }
    }
  },
  modal: {
    label: {
      title: "标题",
      thumbnail: "图片",
      status: "状态",
      active: "激活",
      ai_score: "AI 评分"
    },
    subLabel: {
      status: "立即激活",
      ai_filter: "输入最低评分，低于此评分的商品将被排除在搜索之外。AI 根据您选择的评分帮助过滤此类商品。"
    },
    upgradePlan: {
      title: "订阅升级",
      subtitle: "选择适合您的订阅方案，享受实时价格追踪的便利。",
      currentPlan: "当前订阅",
      question: "订阅 {plan}",
      nav: {
        monthly: "月度",
        annual: "年度"
      },
      month: "月",
      year: "年",
      btn: {
        findPrice: "查看价格",
        cancel: "取消",
        upgrade: "升级订阅"
      }
    }
  },
  validation: {
    required: "此字段为必填项",
    requiredField: "字段 {field} 为必填项",
    requiredConsent: "您必须接受条款",
    url: "无效的链接",
    urlOrSKU: "无效的链接或 SKU",

    email: "请输入有效的电子邮箱",
    min: "最小长度 {min}",
    max: "最大长度 {max}",

    passwordsMustMatch: "密码必须匹配",
    passwordMustBeDifferent: "新密码不得与当前密码相同",
    emailMustBeDifferent: "新电子邮箱不得与当前邮箱相同"
  },
  components: {
    ImageUploader: {
      subLabel_1: "图片将裁剪为正方形。",
      subLabel_2: "仅接受 *.png, *.jpg, *.jpeg 和 *.webp 格式的图片文件。",
      change: "更改图片",
      remove: "删除图片"
    }
  },
  error: {
    noResponseServer: "服务器无响应，请检查您的互联网连接！",
    generalError: "发生错误，请稍后重试！",
    unknown: "未知错误，请稍后重试！",
    server: "服务器错误，请稍后重试！",

    AUTH_FAILED: "授权失败，请稍后重试！",
    REGISTER_FAILED: "注册失败，请稍后重试！",
    PASSWORD_FAILED: "更改密码时出错，请稍后重试！",
    EMAIL_FAILED: "更改电子邮箱时出错，请稍后重试！",

    USER_BLOCKED: "用户帐户被锁定，请联系客服。",
    USER_NOT_FOUND: "找不到用户帐户，请使用邮件中的链接！",
    EMAIL_ALREADY_VERIFIED: "用户邮箱已验证。",
    VERIFY_CODE_MISSING: "邮箱验证码丢失，请使用邮件中的链接！",
    VERIFY_CODE_INVALID: "邮箱验证码无效，请使用邮件中的链接！",
    VERIFY_EMAIL_FAILED: "邮箱验证时出错，请联系客服。",

    PASSWORD_MUST_DIFFERENT: "新密码不得与当前密码相同。",
    EMAIL_MUST_DIFFERENT: "新电子邮箱不得与当前邮箱相同。",
    EMAIL_ALREADY_EXISTS: "新电子邮箱已被使用。",

    AGREEMENT_NOT_ACCEPTED: "您必须接受信息系统的使用协议。",
    AGREEMENT_ACCEPT_FAILED: "接受协议时出错，请联系客服。",

    AUTH_INVALID_CREDENTIALS: "无效的邮箱或密码。",
    AUTH_INVALID_EMAIL_FORMAT: "无效的电子邮箱格式。",
    AUTH_INVALID_PASSWORD: "当前密码无效。",
    AUTH_INVALID_PASSWORD_LENGTH: "密码必须至少包含8个字符。",
    AUTH_REQUIRED_FIELDS_MISSING: "邮箱和密码字段为必填项。",
    AUTH_REQUIRED_FIELDS_EMAIL: "邮箱字段为必填项。",
    AUTH_REQUIRED_FIELDS_PASSWORD: "密码字段为必填项。",
    AUTH_USER_ALREADY_EXISTS: "该电子邮箱的用户已注册。",
    AUTH_USER_NOT_FOUND: "该电子邮箱的用户未注册。",
    AUTH_SEND_PASSWORD_FAILED: "发送密码重置邮件时出错，请稍后重试！",
    USER_UPDATE_FAILED: "更新个人资料时出错，请重试！",
    USER_UPDATE_NOTIFY_FAILED: "更改通知渠道时出错，请重试！",

    GROUP_LIMIT_EXCEEDED: "已超过组数量限制。",
    SEARCH_LIMIT_EXCEEDED: "已超过搜索数量限制。",

    DEMO_ACCESS_DENIED_UPDATE_USER: "在演示帐户中无法更改个人资料。",
    DEMO_ACCESS_DENIED_ADD_GROUP: "在演示帐户中无法添加新组。",
    DEMO_ACCESS_DENIED_EDIT_GROUP: "在演示帐户中无法更改现有组。",
    DEMO_ACCESS_DENIED_DELETE_GROUP: "在演示帐户中无法删除组。",
    DEMO_ACCESS_DENIED_ADD_SEARCH: "在演示帐户中无法添加新搜索。",
    DEMO_ACCESS_DENIED_EDIT_SEARCH: "在演示帐户中无法更改搜索参数。",
    DEMO_ACCESS_DENIED_ARCHIVE_SEARCH: "在演示帐户中无法归档搜索。",
    DEMO_ACCESS_DENIED_DELETE_SEARCH: "在演示帐户中无法删除搜索。",
    DEMO_ACCESS_DENIED_CLEAR_ITEMS: "在演示帐户中无法清空商品列表。",
    DEMO_ACCESS_DENIED_CLEAR_ANALYTICS: "在演示帐户中无法清空分析数据。"
  },
  dataTable: {
    emptyTable: "未找到任何内容",
    zeroRecords: "没有匹配的记录",
    paginate: {
      first: "第一页",
      previous: "上一页",
      next: "下一页",
      last: "最后一页"
    },
    lengthMenu: "显示 _MENU_ 条记录",
    info: "显示 _START_ 到 _END_ 条记录，共 _TOTAL_ 条记录",
    infoEmpty: "显示 0 到 0 条记录，共 0 条记录",
    infoFiltered: "(从 _MAX_ 条记录中筛选)",
    search: "搜索：",
    processing: "处理中...",
    loadingRecords: "正在加载记录...",
    aria: {
      sortAscending: ": 启用升序排序",
      sortDescending: ": 启用降序排序"
    },
    select: {
      rows: {
        _: "选中 %d 条记录",
        0: "单击记录进行选择",
        1: "已选中 1 条记录"
      }
    },
    buttons: {
      print: "打印",
      colvis: "列可见性",
      copy: "复制",
      copyTitle: "已复制到剪贴板",
      copySuccess: {
        _: "已复制 %d 行",
        1: "已复制 1 行"
      }
    },
    decimal: ".",
    thousands: ",",
    datetime: {
      previous: "上一页",
      next: "下一页",
      hours: "小时",
      minutes: "分钟",
      seconds: "秒",
      amPm: ["上午", "下午"],
      unknown: "未知"
    }
  }
};

export default cn;
