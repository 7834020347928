<template>
  <div class="card card-flush" :class="className">
    <div class="card-header pt-5">
      <div class="card-title">
        <h3 class="card-title align-items-start flex-column">
          <router-link
            :to="{ name: 'search' }"
            class="card-label fw-bold fs-3 mb-1"
          >
            {{ $t('pages.items.titleCardLast') }}
          </router-link>
          <span class="text-muted mt-1 fw-semibold fs-7">
            {{ $t('pages.items.formatter.total') }} 
            <SmoothCounter :targetValue="itemStore.countSearch" />
            <EndingFormatter
              :number="itemStore.countSearch"
              :single="$t('pages.items.formatter.single')"
              :few="$t('pages.items.formatter.few')"
              :many="$t('pages.items.formatter.many')"
            />
          </span>
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#modal_items"
          class="btn btn-sm btn-light-primary btn-active-primary"
          :disabled="isAddSearchButtonDisabled"
        >
          <KTIcon icon-name="plus" icon-class="fs-3" />
            {{ $t('pages.items.addItem') }}
        </button>
      </div>
    </div>

    <ItemsModal @data-updated="handleDataUpdated" />

    <div class="card-body pt-0 pt-sm-4 pb-5 mt-3">
      <template v-if="isMobile">
        <template v-if="itemStore.countSearch > 0">
          <div
            v-for="(item, index) in searchs"
            :key="item.id"
            :class="['position-relative pt-5 pb-8', { 'border-bottom': index !== searchs.length - 1 }]"
          >
            <ItemCard
              :item="item"
              :group="itemStore.groups"
            />
          </div>
        </template>
        
        <PlaceholderMobile v-else :length="length" :platform="false" />
      </template>
      
      <div v-else class="table-responsive">
        <table class="table table-row-dashed align-middle gy-4 my-0">
          <thead>
            <tr class="fs-7 fw-bold text-gray-500 border-bottom-0 text-uppercase d-table-row">
              <th class="p-0 pb-3 min-w-100px text-start">
                {{ $t('pages.items.titleTable.search') }}
              </th>
              <th class="p-0 pb-3 min-w-80px text-end">
                {{ $t('pages.items.titleTable.price') }}
              </th>
              <th class="p-0 pb-3 min-w-80px text-end">
                {{ $t('pages.items.titleTable.chart') }}
              </th>
              <th class="p-0 pb-3 min-w-100px text-end d-md-none d-lg-table-cell">
                {{ $t('pages.items.titleTable.status') }}
              </th>
            </tr>
          </thead>

          <tbody v-if="itemStore.countSearch > 0">
            <template v-for="(item, i) in searchs" :key="i">
              <tr class="border-bottom-0">
                <td colspan="4" class="pb-0 pt-4">
                  <router-link
                    :to="`/search/${item.id}/items`"
                    :class="[
                      'fw-bold mb-2 fs-5 text-hover-primary', 
                      { 
                        'text-gray-800': !itemStore.priceComparison(item),
                        'text-success': itemStore.priceComparison(item)
                      }
                    ]"
                  >
                    {{ item.title }}
                  </router-link>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex align-items-center mb-3">
                    <router-link
                      :to="`/search/${item.id}/items`"
                      class="symbol symbol-60px"
                      :aria-label="item.title"
                    >
                      <DynamicSymbol 
                        :title="item.title" 
                        :image="item.image" 
                        fontSizeClass="fs-3" 
                      />
                      <SymbolStatus
                        :status="item.status"
                        :archive="item.archive"
                        :links="item.isLinks"
                        :mobile="false"
                        class="d-block d-sm-none d-md-block d-lg-none"
                      />
                    </router-link>

                    <div class="d-flex flex-column justify-content-start ms-4">
                      <div class="flex-column d-flex">
                        <GroupLink
                          :item="item"
                          :group="itemStore.groups"
                          class="mt-2 me-4"
                        />
                        <WishPriceDisplay
                          :item="item"
                          class="mt-2"
                        />
                      </div>
                    </div>
                  </div>
                </td>

                <td class="text-end pe-0">
                  <BestOffer :item="item" class="mb-3" />
                </td>
                
                <td class="text-end pe-0 pb-7">
                  <SearchChart
                    :chart-data="item.chart"
                    alignClass="align-items-end"
                  />
                </td>

                <td class="text-end d-md-none d-lg-table-cell">
                  <StatusBadge
                    :status="item.status"
                    :archive="item.archive"
                    :links="item.isLinks"
                  />
                </td>
              </tr>
            </template>
          </tbody>
          
          <PlaceholderLastSearch v-else :length="length" />
          
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from "vue";
import { useRouter } from 'vue-router';
import { useAuthStore } from "@/stores/auth";
import { useItemStore } from "@/stores/item";
import { isMobile } from "@/core/helpers/mobile";

import ItemCard from "@/components/items/ItemCard.vue";
import EndingFormatter from "@/components/formatters/EndingFormatter.vue";
import DynamicSymbol from '@/components/formatters/DynamicSymbol.vue';
import SearchChart from "@/components/chart/SearchChart.vue";
import ItemsModal from "@/components/modals/wizards/ItemsModal.vue";
import SymbolStatus from '@/components/items/SymbolStatus.vue';
import StatusBadge from "@/components/items/StatusBadge.vue";
import BestOffer from '@/components/items/BestOffer.vue';
import GroupLink from '@/components/items/GroupLink.vue';
import WishPriceDisplay from '@/components/items/WishPriceDisplay.vue';
import PlaceholderLastSearch from '@/components/placeholder/DashboardLastSearch.vue';
import PlaceholderMobile from '@/components/placeholder/PagesSearchMobile.vue';
import SmoothCounter from '@/components/formatters/SmoothCounter.vue'

export default defineComponent({
  name: "default-dashboard-last-search",
  components: {
    ItemCard,
    EndingFormatter,
    DynamicSymbol,
    SearchChart,
    ItemsModal,
    SymbolStatus,
    StatusBadge,
    BestOffer,
    GroupLink,
    WishPriceDisplay,
    PlaceholderLastSearch,
    PlaceholderMobile,
    SmoothCounter,
  },
  props: {
    className: { type: String, required: false },
  },
  setup() {
    const userStore = useAuthStore();
    const itemStore = useItemStore();
    const router = useRouter();
    const length = 3;

    const searchs = computed(() => itemStore.searchs?.slice(0, length));
    
    const isAddSearchButtonDisabled = computed(() => 
      userStore.isSubscription('search', itemStore.searchs?.length)
    );

    const handleDataUpdated = () => {
      router.push({ name: 'search' });
    };

    onMounted( async() => {
      await Promise.all([
        itemStore.fetchSearchPartData({ limit: length }),
        itemStore.groups.length > 0 ? Promise.resolve() : itemStore.fetchGroupData(),
      ]);
    });
    
    return {
      isMobile,
      searchs,
      length,
      itemStore,
      handleDataUpdated,
      isAddSearchButtonDisabled,
    };
  },
});
</script>
