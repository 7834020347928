<template>
  <div
    v-if="groupData"
    class="modal fade"
    id="modal_groups"
    tabindex="-1"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div :class="[
      'modal-dialog modal-dialog-centered',
      isMobile ? 'modal-fullscreen' : 'modal-lg'
    ]">
      <div class="modal-content">
        <VForm
          class="form"
          id="kt_modal_groups_form"
          :validation-schema="groupSchema"
          v-slot="{ errors }"
          @reset="onReset"
          @submit="onSubmit"
        >
          <div class="modal-header flex-stack border-bottom-0 px-lg-10 pt-lg-10 pb-0" id="kt_modal_groups_header">
            <h2>{{ modalTitle }}</h2>
            <div
              class="btn btn-sm btn-icon btn-light btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <KTIcon icon-name="cross" icon-class="fs-1" />
            </div>
          </div>
          <div class="modal-body p-lg-10">
            <div class="d-flex flex-column form-floating mb-5 mb-md-7 fv-row">
              <Field
                type="text"
                class="form-control mb-2"
                :class="errors.title ? 'is-invalid' : ''"
                :placeholder="$t('pages.groups.modal.placeholder.title')"
                name="title"
                v-model="groupData.title"
                @focus="isFocused.title = true"
                @blur="isFocused.title = false"
                maxLength="25"
              />
              <label class="form-label required">{{ $t('modal.label.title') }}</label>
              <div v-if="isFocused.title" class="text-muted fs-7">
                {{ $t('pages.groups.modal.subLabel.title') }}
              </div>
              
              <MaxLengthIndicator
                v-if="isFocused.title"
                :currentLength="typedCharsTitle"
                :maxLength="25"
              />
              
              <ErrorMessage class="invalid-feedback" name="title" />
            </div>
            
            <div class="d-flex flex-column form-floating mb-5 mb-md-7 fv-row">
              <Field
                as="textarea"
                rows="3"
                class="form-control mb-2"
                :class="errors.descr ? 'is-invalid' : ''"
                style="height: 100px"
                :placeholder="$t('pages.groups.modal.placeholder.description')"
                name="descr"
                v-model="groupData.descr"
                @focus="isFocused.descr = true"
                @blur="isFocused.descr = false"
              />
              <label class="form-label">{{ $t('pages.groups.modal.label.description') }}</label>
              <div v-if="isFocused.descr" class="text-muted fs-7">
                {{ $t('pages.groups.modal.subLabel.description') }}
              </div>
            </div>
            
            <div class="d-flex flex-column mb-7 fv-row">
              <div class="d-flex flex-stack">
                <div class="me-5">
                  <label class="form-label">{{ $t('modal.label.thumbnail') }}</label>              
                  <div class="text-muted fs-7">
                    {{ $t('pages.groups.modal.subLabel.pict') }}
                  </div>
                </div>
                <label class="form-check form-switch form-check-custom form-check-solid">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :checked="groupData.image_load == 1"
                    @change="toggleImageLoad"
                  />
                  <span class="form-check-label fw-semibold text-gray-500">
                    {{ $t('pages.groups.modal.other.checkImage') }}
                  </span>
                </label>
              </div>
            </div>
            
            <div
              v-if="groupData.image_load != '1'"
              class="card card-flush mb-7"
            >
              <div class="card-body text-center">
                <ImageUploader 
                  ref="imageUploaderRef"
                  v-model="groupData.image"
                  @file-change="handleFileChange"
                />
              </div>
            </div>
            
            <div class="d-flex justify-content-between align-items-center pt-5">
              <button
                type="reset"
                id="kt_modal_group_cancel"
                class="btn btn-light"
              >
                {{ $t('buttons.discard') }}
              </button>
              <div class="d-flex">
                <button
                  type="button"
                  class="btn btn-light me-3 d-none d-sm-block"
                  data-bs-dismiss="modal"
                >
                  {{ $t('buttons.cancel') }}
                </button>
<!-- 
                <button
                  type="button"
                  class="btn btn-icon btn-light me-3 d-block d-sm-none"
                  data-bs-dismiss="modal"
                >
                  <KTIcon icon-name="cross" icon-class="fs-2x" />
                </button>
 -->
                <button
                  ref="submitButtonRef"
                  id="kt_modal_group_submit"
                  class="btn btn-primary"
                >
                  <span class="indicator-label">
                    {{ $t('buttons.submit') }}
                  </span>
                  <span class="text-nowrap indicator-progress">
                    {{ $t('buttons.wait') }}
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </VForm>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onBeforeUnmount } from "vue";
import { ErrorMessage, Field, Form as VForm } from "vee-validate";
import { hideModal } from "@/core/helpers/modal";
import { useI18n } from "vue-i18n";
import { isMobile } from "@/core/helpers/mobile";
import { useItemStore } from "@/stores/item";
import { initGroupData, disableSubmitButton, enableSubmitButton, cleanString } from "@/utils/helpers/itemHelpers";

import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ImageUploader from "@/components/files/ImageUploader.vue";
import MaxLengthIndicator from "@/components/form/MaxLengthIndicator.vue";
import { addToast } from '@/utils/toastManager';

export default defineComponent({
  name: "group-modal",
  components: {
    ErrorMessage,
    Field,
    VForm,
    ImageUploader,
    MaxLengthIndicator,
  },
  props: {
    editMode: {
      type: Boolean,
      required: true
    },
    groupData: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const dataStore = useItemStore();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const imageUploaderRef = ref<null | { resetImage: () => void }>(null);
    const selectedFile = ref<File | null>(null);
    const modalEl = ref<HTMLElement | null>(null);
    const isFocused = ref({
      title: false,
      descr: false,
    });

    const createGroupSchema = () => {
      return Yup.object().shape({
        title: Yup.string()
          .required(t('validation.requiredField', { field: t('modal.label.title') }))
          .max(25, t('validation.max', { max: 25 })),
      });
    };
    
    const groupSchema = computed(() => createGroupSchema());
    const typedCharsTitle = computed(() => props.groupData.title?.length ?? 0);
    const modalTitle = computed(() => props.editMode ? t('pages.groups.modal.title.edit') : t('pages.groups.modal.title.new'));

    const handleFileChange = (file: File) => {
      selectedFile.value = file;
    };

    const onReset = async () => {
      Object.assign(props.groupData, initGroupData());
      selectedFile.value = null;
      imageUploaderRef.value?.resetImage();
    };
    
    const IMAGE_LOAD_ENABLED = '1';
    const toggleImageLoad = () => {
      props.groupData.image_load = props.groupData.image_load === IMAGE_LOAD_ENABLED ? '0' : IMAGE_LOAD_ENABLED;
    };

    const onSubmit = async () => {
      try {
        disableSubmitButton(submitButtonRef);
        
        const formData = createFormData();
        await (props.editMode ? dataStore.editGroup(formData) : dataStore.addGroup(formData));

        if (!Object.keys(dataStore.errors).length) {
          handleSuccess();
        } else {
          handleError(dataStore.errors);
        }
      } catch (error) {
        handleError(error);
      } finally {
        enableSubmitButton(submitButtonRef);
      }
    };

    // Константа для создания объекта FormData
    const createFormData = () => {
      const formData = new FormData();
      
      if (props.groupData.id) {
        formData.append("ID", props.groupData.id);
      }
      if (props.groupData.title !== undefined) {
        formData.append("UF_TITLE", cleanString(props.groupData.title));
      }
      if (props.groupData.descr !== undefined) {
        formData.append("UF_DESCR", cleanString(props.groupData.descr));
      }
      formData.append("UF_IMAGE_LOAD", props.groupData.image_load);
      formData.append("UF_STATUS", props.groupData.status);
      if (selectedFile.value) {
        formData.append("FILE_IMAGE", selectedFile.value);
      }
      return formData;
    }

    // Константа для обработки успешного завершения
    const handleSuccess = () => {
      addToast(
        t('messages.any.success'),
        t(`messages.text.success.${props.editMode ? 'editGroup' : 'addGroup'}`),
        'success'
      );
      emit("data-updated");
      hideModal(modalEl.value);
      
      setTimeout(() => {
        onReset();
      }, 300);
    }

    // Константа для обработки ошибок
    const handleError = (error) => {
      Swal.fire({
        text: error,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t('messages.button.okGotIt'),
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-danger",
        },
      }).then(() => {
        dataStore.errors = [];
      });
    }
    
    // Инициализация обработчика сброса модального окна
    const initModalResetHandler = () => {
      modalEl.value = document.getElementById('modal_groups');
      modalEl.value?.addEventListener('hidden.bs.modal', handleModalHidden);
    };
    
    // Обработчик события скрытия модального окна
    const handleModalHidden = () => {
      const formEl = document.getElementById('kt_modal_groups_form');
      formEl?.reset();
    };

    onMounted(() => {
      initModalResetHandler();
    });

    onBeforeUnmount(() => {
      modalEl.value?.removeEventListener('hidden.bs.modal', handleModalHidden);
    });

    return {
      isMobile,
      groupSchema,
      modalTitle,
      onReset,
      onSubmit,
      submitButtonRef,
      handleFileChange,
      imageUploaderRef,
      toggleImageLoad,
      isFocused,
      typedCharsTitle,
    };
  },
});
</script>
