<template>
  <div class="card card-flush">
    <div class="card-header flex-nowrap py-5 gap-0 gap-md-5">
      <div class="card-title">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bold fs-3 mb-1">
            {{ $t('pages.searchId.layoutList.titleCard') }}
          </span>
          <span class="text-muted mt-1 fw-semibold fs-7">
            {{ $t('pages.searchId.layoutList.total') }} 
            <SmoothCounter :targetValue="countData" />
          </span>
        </h3>
      </div>
      
      <!-- Панель инструментов карточки -->
      <div class="card-toolbar">
        <div
          v-if="selectedIds.length === 0"
          class="d-flex flex-nowrap"
        >
          <label class="form-check form-switch form-check-custom mx-6">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="toggleStatus"
            />
            <span class="form-check-label fw-semibold text-gray-500">
              {{ $t('pages.searchId.layoutList.active') }}
            </span>
          </label>
        
          <select
            class="form-select form-select-white form-select-sm fw-bold"
            name="platform"
            v-model="selectedPlatform"
            :aria-label="$t('pages.searchId.layoutList.titleTable.platform')"
          >
            <option value="0" selected>
              {{ $t('pages.searchId.layoutList.allPlatforms') }}
            </option>
            <option
              v-if="itemStore.platforms.length > 0"
              v-for="platform in itemStore.platforms"
              :key="platform.id"
              :value="platform.id"
            >
              {{ platform.title }}
            </option>
          </select>
        </div>
        
        <!-- Панель действий при выбранных элементах -->
        <div v-else class="d-flex justify-content-end align-items-center">
          <!-- Показ количества выбранных элементов -->
          <div class="mx-5 text-nowrap">
            <EndingFormatter
              :number="selectedIds.length"
              :showNumber="true"
              :single="$t('pages.items.formatter.single')"
              :few="$t('pages.items.formatter.few')"
              :many="$t('pages.items.formatter.many')"
            />
          </div>
          <!-- Кнопка удаления -->
          <button
            type="button"
            ref="deleteButton"
            class="btn btn-sm btn-danger text-nowrap"
            @click="deleteFewItems()"
          >
            <span class="indicator-label"> {{ $t('buttons.remove') }} </span>
            <span class="indicator-progress">
              {{ $t('buttons.wait') }}
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
        </div>

      </div>
    </div>

    <div class="card-body pt-0 pb-5">
      <Datatable
        v-if="!isLoading"
        class="table align-middle table-row-dashed gy-3"
        :header="tableHeader"
        :data="sortedFilteredItems"
        :items-per-page="itemsPerPage"
        :items-per-page-dropdown-enabled="itemsPerPageDropdown"
        :preload-count="10"
        :checkbox-enabled="true"
        :emptyTableText="$t('dataTable.emptyTable')"
        @on-sort="handleSort"
        @on-items-per-page-change="handleItemsPerPage"
        @on-items-select="onItemSelect"
      >
        <template v-slot:title="{ row: item }">
          <div class="d-flex align-items-center min-w-200px">
            <a
              :href="item.link"
              :aria-label="item.title"
              class="symbol symbol-50px"
              target="_blank"
            >
              <span 
                class="symbol-label" 
                :style="`background-image: url('${item.image ? item.image : ''}');`">
                {{item.image ? '' : item.title[0]}}
              </span>
            </a>
            <div class="d-flex flex-column ms-5">
              <a
                :href="item.link"
                target="_blank"
                rel="noopener noreferrer"
                :class="[
                  'fw-bold text-hover-primary fs-5 mb-1',
                  isDateWithin24Hours(item.timestamp_x) ? 'text-gray-800' : 'text-gray-500' 
                ]"
                :title="item.title"
              >
                {{ truncateTitle(item.title) }}
              </a>
              <div class="text-muted fs-7">
                {{ getPlatformTitle(item.platformId) }}
              </div>
            </div>
          </div>
        </template>
        
        <template v-slot:rating="{ row: item }">
          <RatingStars
            v-if="item.rating > 0"
            :rating="Number(item.rating)"
            mode="short"
          />
          <div v-else class="text-gray-400">–</div>
        </template>

        <template v-slot:price="{ row: item }">
          <div class="d-flex flex-column d-inline-flex">
            <a
              :href="item.link"
              target="_blank"
              rel="noopener noreferrer"
              :class="[
                'fw-bold text-hover-primary fs-6',
                isDateWithin24Hours(item.timestamp_x) ? 'text-gray-700' : 'text-gray-400' 
              ]"
            >
              <PriceFormatter :price="item.price" class="text-nowrap" />
            </a>
            
            <div v-if="item.sberspasibo > 0">
              <div
                :class="[
                  'badge align-items-center mt-1',
                  isDateWithin24Hours(item.timestamp_x) ? 'badge-light-secondary text-gray-600' : 'badge-light text-gray-400' 
                ]"
              >
                <PriceFormatter :price="item.sberspasibo" :showCurrency="false"  class="text-nowrap fw-semibold fs-7" />
                <svg
                  :class="[
                    'ms-1 w-10px h-10px text-success align-self-baseline',
                    isDateWithin24Hours(item.timestamp_x) ? 'text-success' : 'text-gray-400' 
                  ]"
                >
                  <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#i-sber-spasibo"></use>
                </svg>
              </div>
            </div>
            
            <div v-else-if="item.price_orig > 0 && item.price !== item.price_orig">
              <div
                :class="[
                  'fw-bold text-hover-primary',
                  isDateWithin24Hours(item.timestamp_x) ? 'text-gray-500' : 'text-gray-400' 
                ]"
              >
                <PriceFormatter :price="item.price_orig" class="text-nowrap text-decoration-line-through fs-7" />
              </div>
            </div>

          </div>
        </template>

        <template v-slot:brand="{ row: item }">
          {{ item.brand }}
        </template>

        <template v-slot:quantity="{ row: item }">
          <div v-if="item.quantity >= 100" class="text-muted fw-semibold">
            {{ $t('pages.searchId.template.many') }}
          </div>
          <div v-else-if="item.quantity > 0" class="text-muted fw-semibold">
            {{ item.quantity }} {{ $t('pages.searchId.template.pcs') }}
          </div>
          <div v-else class="text-gray-400">–</div>
        </template>

        <template v-slot:sku="{ row: item }">
          <div class="text-muted fw-semibold">
            {{ item.skuId }}
          </div>
        </template>

        <template v-slot:ai_score="{ row: item }">
          <div class="text-muted fw-semibold">
            {{ item.ai_score }}
          </div>
        </template>
        
        <template v-slot:delivery_date="{ row: item }">
          <div
            v-if="item.delivery_date"
            class="d-flex flex-column"
          >
            <DateFormatter 
              :date="item.delivery_date" 
              :format="'D, j M'"
              class="text-nowrap text-muted fw-semibold text-gray-600"
            />
          </div>
          <div v-else class="text-gray-400">–</div>
        </template>

        <template v-slot:timestamp_x="{ row: item }">
          <div class="d-flex flex-column">
            <DateFormatter 
              :date="item.timestamp_x" 
              :format="'d/m/Y'"
              :class="[
                'text-nowrap fw-bold',
                isDateWithin24Hours(item.timestamp_x) ? 'text-gray-800' : 'text-gray-500' 
              ]"
            />
            <DateFormatter 
              :date="item.timestamp_x" 
              :format="'в H ч. i мин.'" 
              class="text-nowrap text-muted fw-semibold text-muted fs-7"
            />
          </div>
        </template>

      </Datatable>
      
      <Placeholder
        v-else
        :length="5"
        :table-header="tableHeader"
        :items-per-page="itemsPerPage"
      />
    </div>
  </div>
  
  <OfferUniqueWordsModal
    :new-unique-words="newUniqueWords"
    :now-unique-words="nowUniqueWords"
    @remove-items-modal="handleIsConfirmed"
  />
  
  <div class="d-none">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <symbol viewBox="0 0 16 16" id="i-sber-spasibo" fill="currentColor">
        <path d="M8 0C3.6 0 0 3.6 0 8v8h8c4.4 0 8-3.6 8-8s-3.6-8-8-8zm.066 12.267C5.734 12.267 3.8 10.333 3.8 8s1.934-4.266 4.266-4.266c1.334 0 2.533.6 3.334 1.6L9.666 6.732A2.028 2.028 0 008.066 6c-1.133 0-2 .933-2 2 0 1.133.934 2 2 2 .6 0 1.2-.266 1.6-.8l1.8 1.4c-.8 1.066-2.066 1.667-3.4 1.667z" />
      </symbol>
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted } from "vue";
import { useRoute } from 'vue-router';
import { useI18n } from "vue-i18n";
import { useConfigStore } from "@/stores/config";
import { useItemStore } from "@/stores/item";
import { truncateTitle, filterByWords, dataStoreSearchs, uniqueWordsInDataChartNotInItems } from "@/utils/helpers/listHelpers";
import { addToast } from '@/utils/toastManager';
import { showModal } from "@/core/helpers/modal";

import Swal from "sweetalert2/dist/sweetalert2.js";
import arraySort from 'array-sort';
import Datatable from "@/components/kt-datatable/KTDataTable.vue";
import PriceFormatter from '@/components/formatters/PriceFormatter.vue';
import DateFormatter from '@/components/formatters/DateFormatter.vue';
import RatingStars from '@/components/RatingStars.vue';
import Placeholder from '@/components/placeholder/ItemsList.vue';
import SmoothCounter from '@/components/formatters/SmoothCounter.vue'
import EndingFormatter from "@/components/formatters/EndingFormatter.vue";
import OfferUniqueWordsModal from "@/components/modals/general/OfferUniqueWordsModal.vue";

export default defineComponent({
  name: "search-id-list",
  components: {
    Datatable,
    PriceFormatter,
    DateFormatter,
    RatingStars,
    Placeholder,
    SmoothCounter,
    EndingFormatter,
    OfferUniqueWordsModal,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const bodyStore = useConfigStore();
    const itemStore = useItemStore();
    
    const sortState = ref({ label: null, order: 'asc' });
    const selectedPlatform = ref('0');
    const selectedIds = ref<Array<number>>([]);
    const toggleStatus = ref(false);
    const deleteButton = ref<null | HTMLButtonElement>(null);
    const newUniqueWords = ref({});
    const nowUniqueWords = ref({});
    const itemsPerPage = ref(bodyStore.getLayoutConfig('datatable.itemsPerPage'));

    const tableHeader = ref([
      {
        columnName: t('pages.searchId.layoutList.titleTable.items'),
        columnLabel: "title",
        sortEnabled: true,
        columnClass: "min-w-225px",
      },
      {
        columnName: t('pages.searchId.layoutList.titleTable.rating'),
        columnLabel: "rating",
        columnClass: "min-w-100px text-end pe-0",
        sortEnabled: true,
      },
      {
        columnName: t('pages.searchId.layoutList.titleTable.price'),
        columnLabel: "price",
        columnClass: "min-w-100px text-end pe-0",
        sortEnabled: true,
      },
      {
        columnName: t('pages.searchId.layoutList.titleTable.quantity'),
        columnLabel: "quantity",
        columnClass: "min-w-100px text-end pe-0",
        sortEnabled: true,
      },
/*       {
        columnName: t('pages.searchId.layoutList.titleTable.brand'),
        columnLabel: "brand",
        columnClass: "min-w-100px text-end pe-0",
        sortEnabled: true,
      }, */
/*       {
        columnName: t('pages.searchId.layoutList.titleTable.sku'),
        columnLabel: "sku",
        columnClass: "min-w-125px text-end pe-0",
        sortEnabled: false,
      }, */
      {
        columnName: t('pages.searchId.layoutList.titleTable.delivery'),
        columnLabel: "delivery_date",
        columnClass: "min-w-125px text-end pe-0",
        sortEnabled: true,
      },
      {
        columnName: t('pages.searchId.layoutList.titleTable.ai_score'),
        columnLabel: "ai_score",
        columnClass: "min-w-100px text-end pe-0",
        sortEnabled: true,
      },
      {
        columnName: t('pages.searchId.layoutList.titleTable.date'),
        columnLabel: "timestamp_x",
        columnClass: "min-w-125px",
        sortEnabled: true,
      },
    ]);
    
    const searchId = computed(() => Number(route.params.id) || 0);

    const handleSort = (sort) => {
      sortState.value = sort;
    };
    
    const itemsPerPageDropdown = computed(() => {
      return sortedFilteredItems.value?.length >= 10;
    });
    
    const isDateWithin24Hours = (datetime) => {
      const now = new Date();
        const timestamp = new Date(datetime);
        const diffInMilliseconds = now.getTime() - timestamp.getTime();
        return diffInMilliseconds <= 24 * 60 * 60 * 1000;
    };

    // Фильтруем items на основе условий
    const filteredItems = computed(() => {
      return itemStore.items.filter(item => {
        // Проверка платформы
        const platformMatch = selectedPlatform.value === '0' || item.platformId === selectedPlatform.value;
    
        // Проверка статуса (если переключатель включен или дата в пределах 24 часов)
        const statusMatch = toggleStatus.value || (!toggleStatus.value && isDateWithin24Hours(item.timestamp_x));

        // Проверка aiScore (если aiScore не null и >= item.ai_score)
        const aiScoreMatch = toggleStatus.value || aiScore.value <= item.ai_score;
    
        // Все условия должны быть выполнены
        return platformMatch && statusMatch && aiScoreMatch && filterByWords(searchData.value, item.title);
      });
    });
    
    // Вычисляемое свойство для фильтрации и сортировки
    const sortedFilteredItems = computed(() => {
      const items = filteredItems.value.slice(); // копировать массив перед сортировкой
      
      if (sortState.value.label && items.length > 0) {
        const reverse = sortState.value.order === 'desc';
        const firstItem = items[0][sortState.value.label];
        const isNumeric = !isNaN(parseFloat(firstItem)) && isFinite(firstItem);
    
        if (isNumeric) {
          // Сортируем числа с помощью arraySort
          return arraySort(items, (a, b) => {
            const aValue = parseFloat(a[sortState.value.label]);
            const bValue = parseFloat(b[sortState.value.label]);
    
            return reverse ? bValue - aValue : aValue - bValue;
          });
        } else {
          // Используем arraySort для всех остальных типов данных
          return arraySort(items, sortState.value.label, { reverse });
        }
      }
      
      return items;
    });

    const searchData = computed(() => {
      const itemIndex = itemStore.searchs.findIndex(item => {
        return Number(item.id) === searchId.value;
      });
      
      return itemIndex !== -1 ? itemStore.searchs[itemIndex] : null;
    });
    
    const aiScore = computed(() => {
      return searchData.value ? searchData.value?.ai_score : null;
    });
    
    const isLoading = computed(() => {
      return (itemStore.items.length > 0 && searchData.value?.ai_score && itemStore.items[0].parentId == searchId.value) ? false : true;
    });
    
    const countData = computed(() => {
      return !isLoading.value ? filteredItems.value.length : 0;
    });
    
    const onItemSelect = (selectedItems: Array<number>) => {
      selectedIds.value = selectedItems;
    };

    const getPlatformTitle = (platformId) => {
      const platform = itemStore.platforms.find(p => p.id === platformId);
      return platform ? platform.title : '';
    };

    const fetchListData = async () => {
      await itemStore.fetchItemData({ id: searchId.value });
    };
        
    const deleteFewItems = async() => {
      if (!deleteButton.value) {
        return;
      }
    
      try {
        deleteButton.value!.disabled = true;
        deleteButton.value?.setAttribute("data-kt-indicator", "on");
        
        await Promise.all([
          itemStore.searchs.length > 0 ? Promise.resolve() : itemStore.fetchSearchPartData({ id: searchId.value }),
          itemStore.items.some(item => item.parentId === searchId.value) 
            ? Promise.resolve() 
            : itemStore.fetchItemData({ id: searchId.value })
        ]);
                
        if (itemStore.searchs.length > 0 && itemStore.items.length > 0) {
          
          const dataStoreById = dataStoreSearchs(itemStore.searchs, searchId.value);
          
          nowUniqueWords.value = {
            'required_words': dataStoreById['required_words'],
            'excluded_words': dataStoreById['excluded_words'],
          };
          
          newUniqueWords.value = uniqueWordsInDataChartNotInItems(
            sortedFilteredItems.value,
            selectedIds.value,
            itemStore,
            searchId.value
          );
        }
        
        if (
          newUniqueWords.value['required_words']?.length > 0 ||
          newUniqueWords.value['excluded_words']?.length > 0
        ) {
          const modalElement = document.getElementById('modal_offer_unique_words');
          // Ожидаем закрытия модального окна
          await new Promise<void>((resolve) => {
            const handleModalClose = () => {
              modalElement.removeEventListener('hidden.bs.modal', handleModalClose);
              resolve();
            };
            modalElement.addEventListener('hidden.bs.modal', handleModalClose);
            showModal(modalElement);
          });
        } else {
          await Swal.fire({
            text: t('messages.text.warning.deleteAnalyticsItems'),
            icon: 'warning',
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: t('messages.button.delete'),
            cancelButtonText: t('messages.button.noCancel'),
            heightAuto: false,
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-secondary',
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              await handleIsConfirmed();              
            }
          });
        }
      } catch (error) {
        handleError(error);
      } finally {
        if (deleteButton.value) {
          deleteButton.value.removeAttribute("data-kt-indicator");
          deleteButton.value.disabled = false;
        }
      }
    };
    
    const deleteItem = (id: number) => {
      const index = itemStore.items?.findIndex(item => item.id === id);
      if (index !== -1) {
        itemStore.items?.splice(index, 1);
      }
    };
    
    // Константа для обработки успешного завершения
    const handleSuccess = () => {
      selectedIds.value.forEach((item) => {
        deleteItem(item);
      });
      selectedIds.value.length = 0;
    
      addToast(
        t('messages.any.success'),
        t(`messages.text.success.deleteAnalyticsItems`),
        'success'
      );
    }
    
    // Константа для обработки ошибок
    const handleError = (error) => {
      Swal.fire({
        text: error,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t('messages.button.okGotIt'),
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-danger",
        },
      }).then(() => {
        itemStore.errors = [];
      });
    }
    
    const handleIsConfirmed = async (required_words?: string, excluded_words?: string, modalWords?: boolean) => {
      const payload: Payload = {
        searchId: searchId.value,
        ids: selectedIds.value,
      };
      
      // Добавляем uniqueWords, если required_words или excluded_words не пусты, либо modalWords === true
      if (required_words?.trim() || excluded_words?.trim() || modalWords) {
        payload.uniqueWords = {
          required_words: required_words?.trim() || "",
          excluded_words: excluded_words?.trim() || "",
        };
      }

      await itemStore.deleteListItems(payload);
      itemStore.fetchSearchPartData({ id: searchId.value });
          
      if (!Object.keys(itemStore.errors).length) {
        handleSuccess();
      } else {
        handleError(itemStore.errors);
      }
    };
    
    const handleItemsPerPage = (val) => {
      bodyStore.setLayoutConfigProperty("datatable.itemsPerPage", val);
    };

    onMounted(() => {
      fetchListData();
    });
    
    watch(
      () => route.params.id,
      async (newId, oldId) => {
        if (newId !== oldId && newId) {
          await fetchListData(newId);
        }
      }
    );

    watch(() => route.query.clear,
      async (newCleared, oldCleared) => {
        if (newCleared !== oldCleared && newCleared) {
          await fetchListData();
        }
      }
    );
    
    return {
      tableHeader,
      countData,
      itemStore,
      itemsPerPageDropdown,
      getPlatformTitle,
      selectedPlatform,
      sortedFilteredItems,
      handleSort,
      handleItemsPerPage,
      onItemSelect,
      truncateTitle,
      isLoading,
      itemsPerPage,
      isDateWithin24Hours,
      toggleStatus,
      selectedIds,
      deleteButton,
      deleteFewItems,
      newUniqueWords,
      nowUniqueWords,
      handleIsConfirmed,
    };
  },
});
</script>