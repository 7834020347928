<template>
  <!--begin::Step 3-->
  <div data-kt-stepper-element="content">
    <!--begin::Wrapper-->
    <div class="d-flex flex-column w-100">
      
      <!-- Поле для обязательных слов -->
      <div class="mb-5 mb-md-7 fv-row">
        <label class="form-label">{{ $t('pages.items.modal.label.requiredWords') }}</label>
        <Tagify
          mode="input"
          class="form-control mb-2"
          :placeholder="$t('pages.items.modal.placeholder.requiredWords')"
          name="required_words"
          v-model="localItemData.required_words"
        />
        <div class="text-muted fs-7">
          {{ $t('pages.items.modal.subLabel.requiredWords') }}
        </div>
      </div>

      <!-- Поле для исключенных слов -->
      <div class="mb-5 mb-md-7 fv-row">
        <label class="form-label">{{ $t('pages.items.modal.label.excludedWords') }}</label>
        <Tagify
          mode="input"
          class="form-control mb-2"
          :placeholder="$t('pages.items.modal.placeholder.excludedWords')"
          name="excluded_words"
          v-model="localItemData.excluded_words"
        />
        <div class="text-muted fs-7">
          {{ $t('pages.items.modal.subLabel.excludedWords') }}
        </div>
      </div>

      <!-- Поле для AI фильтра -->
      <div class="d-flex flex-column my-5 mb-md-7 fv-row">
        <div class="d-flex flex-stack my-2">
          <label class="form-label text-nowrap mb-0 me-5">
            {{ $t('modal.label.ai_score') }}
          </label>
          
          <!-- Ползунок для AI фильтра -->
          <div class="w-50 mx-5">
            <div id="ai_filter_slider" class="noUi-sm"></div>
          </div>
          
          <!-- Переключатель для включения/отключения AI фильтра -->
          <label class="form-check form-switch form-check-custom form-check-solid ms-5">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="localItemData.ai_status == 1"
              @change="toggleAI"
            />
            <span class="form-check-label fw-semibold text-gray-500 d-none d-md-block">
              {{ $t('modal.label.active') }}
            </span>
          </label>
        </div>

        <!-- Текстовое описание AI фильтра с текущим значением -->
        <div class="text-muted fs-7">
          {{ $t('modal.subLabel.ai_filter', { value: localItemData.ai_score }) }}
        </div>
      </div>

    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Step 3-->
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed, onMounted, onBeforeUnmount } from "vue";
import { ErrorMessage, Field } from "vee-validate";
import { useI18n } from "vue-i18n";

import Tagify from '@/components/Tagify.vue';
import noUiSlider from 'nouislider/dist/nouislider.js';

export default defineComponent({
  name: "item-modal-step3",
  components: {
    ErrorMessage,
    Field,
    Tagify,
  },
  props: {
    localItemData: {
      type: Object,
      required: true
    },
    onReset: {
      type: Function,
      required: true
    },
  },
  setup(props) {
    const { t } = useI18n();
    const aiFilterSlider = ref<HTMLElement | null>(null); // Ссылка на ползунок AI фильтра

    // Функция для переключения AI фильтра (включение/выключение)
    const toggleAI = () => {
      props.localItemData.ai_status = props.localItemData.ai_status === '1' ? '0' : '1';
    };
    
    // Инициализация ползунка для AI фильтра
    const initAIFilter = () => {
      aiFilterSlider.value = document.getElementById("ai_filter_slider");
      if (aiFilterSlider.value) {
    
        // Удаляем предыдущие события и слайдер, если они есть
        aiFilterSlider.value.noUiSlider?.off();
        aiFilterSlider.value.noUiSlider?.destroy();
        
        // Создаем новый слайдер
        noUiSlider.create(aiFilterSlider.value, {
            start: [props.localItemData.ai_score],
            tooltips: true,
            connect: [false, true],
            range: { "min": 0.2, "max": 1 }
        });
        
        // Обновляем локальные данные при изменении ползунка
        aiFilterSlider.value.noUiSlider?.on('update', function (value) {
          props.localItemData.ai_score = value[0];
        });
      }
    }

    onMounted(async () => {
      initAIFilter(); // Инициализация слайдера
    });

    // Удаляем слайдер и события при размонтировании компонента
    onBeforeUnmount(() => {
      aiFilterSlider.value?.noUiSlider?.off(); 
      aiFilterSlider.value?.noUiSlider?.destroy();
    });
    
    // Обновляем значения слайдера при сбросе
    const handleReset = () => {
      aiFilterSlider.value?.noUiSlider?.updateOptions({
        start: [props.localItemData.ai_score]
      });
    };
    
    // Отслеживаем сброс данных и вызываем handleReset
    watch(() => props.onReset, (newVal) => {
      if (newVal) {
        handleReset();
      }
    });

    // Отслеживаем изменения в статусе AI фильтра и обновляем слайдер
    watch(() => props.localItemData.ai_status, (newStatus) => {
      if (aiFilterSlider.value) {
        handleReset();
        newStatus === '1' ? aiFilterSlider.value.noUiSlider?.enable() : aiFilterSlider.value.noUiSlider?.disable();
      }
    }, { deep: true });

    return {
      toggleAI,
      };
    },
  });
</script>
