<template>
  <div :class="statusClass"></div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useThemeStore } from '@/stores/theme';

export default defineComponent({
  name: 'symbol-status',
  props: {
    status: {
      type: [Number, String],
      required: true,
    },
    archive: {
      type: [Number, String, Boolean], // Разрешаем Number, String и Boolean
      required: false,
      default: false,
    },
    mobile: {
      type: Boolean,
      required: false,
      default: true,
    },
    links: {
      type: [Number, String],
      required: false,
      default: 1,
    },
  },
  setup(props) {
    const themeStore = useThemeStore();
    
    // Проверка, является ли элемент архивированным
    const isArchived = computed(() => {
      // Если archive задан как boolean, использовать его значение напрямую
      // Если archive задан как строка или число, приводим к числу и сравниваем с 1
      return typeof props.archive === 'boolean' 
        ? props.archive 
        : Number(props.archive) === 1;
    });
    
    // Проверка, есть ли ссылки
    const isLinks = computed(() => Number(props.links) === 0);

    // Определение класса бейджа в зависимости от archive и status
    const statusColor = computed(() => {
      if (isArchived.value) {
        return 'bg-warning';
      }
      if (isLinks.value) {
        return 'bg-info';
      }
      return props.status == 1 ? 'bg-success' : 'bg-danger';
    });

    const statusClass = computed(() => {
      const baseClass = 'position-absolute translate-middle ms-5 rounded-circle border border-3';
      const sizeClass = 'h-15px w-15px';
      const borderColor = `border-card-${themeStore.resolvedMode}`;
      const mobileClass = props.mobile ? 'd-block d-sm-none' : '';
    
      return `${baseClass} ${sizeClass} ${statusColor.value} ${borderColor} ${mobileClass}`;
    });

    return {
      statusClass,
    };
  },
});
</script>