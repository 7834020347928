<template>
  <div v-if="platform && hasCount" class="mt-2">
    <PriceFormatter :price="calculateSum(platform.count)" :showCurrency="false"  class="text-nowrap text-gray-600" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import PriceFormatter from '@/components/formatters/PriceFormatter.vue';

export default defineComponent({
  name: 'platform-count',
  components: {
    PriceFormatter,
  },
  props: {
    platform: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const hasCount = computed(() => 
      props.platform.count && Object.keys(props.platform.count).some(key => key.length > 0)
    );

    const calculateSum = (counts: Record<string, string | number>) => {
      return Object.values(counts)
        .map(value => Number(value)) // Преобразуем строки в числа
        .reduce((acc, curr) => acc + curr, 0); // Суммируем
    };

    return {
      hasCount,
      calculateSum,
    };
  },
});
</script>
