<template>
  <!--begin::Notification Channels-->
  <div :class="['card mb-5 mb-xl-10', { 'overlay overlay-block' : isLoading }]" id="notification_channels">
    <!--begin::Card header-->
    <div
      class="card-header collapsible cursor-pointer rotate min-h-60px"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_notification_channels"
      aria-expanded="true"
      aria-controls="kt_account_notification_channels"
    >
      <h3 class="card-title fw-bold m-0">
        {{ $t('pages.account.layoutNotifications.notifyChannels') }}
      </h3>
      <div class="card-toolbar rotate-180">
        <i class="ki-duotone ki-down fs-1"></i>
      </div>
    </div>
    <!--end::Card header-->
  
    <!--begin::Content-->
    <div id="kt_account_notification_channels" :class="['collapse show', { 'overlay-wrapper': isLoading }]">
      <!--begin::Card body-->
      <div class="card-body p-9">
        <!--begin::Items Loop-->
        <div v-for="(notify, index) in userStore.notifyChannels" :key="index">

          <div class="d-flex flex-stack">
            <div class="d-flex align-items-center">
              <div class="symbol me-6">
                <svg class="stroke-gray-300 fill-gray-100" fill="none" height="48" viewbox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506 
                  18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937 
                  39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                  </path>
                  <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506 
                  18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937 
                  39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                  </path>
                </svg>
                <div class="symbol-badge badge top-50 start-50">
                  <i :class="`ki-outline ki-${notify.icon} fs-1 text-gray-500`"></i>
                </div>
              </div>
              <div class="d-flex flex-column me-5">
                <div class="fs-5 text-gray-900 fw-bold">
                  {{ notify.name }}
                </div>
                <div class="fs-6 fw-semibold text-gray-500">
                  <span v-if="notify.oauth">
                    {{ getViewName(notify.type) }}
                  </span>
                  <span v-else>
                    {{ userStore.user[notify.field]?.length > 0 ? userStore.user[notify.field] : $t('pages.account.layoutNotifications.notifyNotLabel') }}
                  </span>
                </div>
              </div>
            </div>
            
            <!--begin::Actions-->
            <div class="d-flex justify-content-end">
              <!--begin::isLoading-->
              <div v-if="isLoading"></div>
              <!--end::isLoading-->

              <!--begin::Button Telegram Connected-->
              <button
                v-else-if="notify.oauth && notify.type === 'telegram' && !userStore.messageStoreTG?.connected"
                class="btn btn-sm btn-light"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_collapse_connected_messageTG"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                {{ $t('buttons.connect') }}
              </button>
              <!--end::Button Telegram Connected-->

              <!--begin::Button Telegram Access-->
              <button
                v-else-if="notify.oauth && notify.type === 'telegram' && !userStore.messageStoreTG?.access"
                class="btn btn-sm btn-light"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_collapse_access_messageTG"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                {{ $t('buttons.allow') }}
              </button>
              <!--end::Button Telegram Access-->

              <!--begin::Button Phone-->
              <button
                v-else-if="notify.type === 'phone' && !userStore.user[notify.field]"
                class="btn btn-sm btn-light"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_collapse_connected_phone"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                {{ $t('buttons.connect') }}
              </button>
              <!--end::Button Phone-->

              <!--begin::Checkbox-->
              <div v-else class="form-check form-switch">
                <input
                  class="form-check-input w-40px h-25px"
                  type="checkbox"
                  :checked="notify.checked"
                  @change="(event) => toogleChannels(event.target.checked, notify, event)"
                />
              </div>
              <!--end::Checkbox-->
            </div>
          </div>
          <!--end::Actions-->
          
          <!--begin::Collapse Telegram Connected-->
          <div
            v-if="notify.oauth && notify.type === 'telegram' && !userStore.messageStoreTG?.connected"
            class="collapse"
            id="kt_collapse_connected_messageTG"
          >
            <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mt-6">
              <KTIcon
                icon-name="information-5"
                icon-class="fs-2tx text-warning me-4"
              />
              <div class="d-flex flex-stack flex-grow-1">
                <div class="fw-semibold">
                  <h4 class="text-gray-800 fw-bold">
                    {{ $t('pages.account.layoutNotifications.infoConnectedTGTitle') }}
                  </h4>
                  <div class="fs-6 text-gray-600">
                    {{ $t('pages.account.layoutNotifications.infoConnectedTGText') }}
                    <router-link
                      :to="{ path: '/account/settings', hash: '#oauth' }"
                    >
                      {{ $t('pages.account.layoutNotifications.infoConnectedLink') }}
                    </router-link>.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::Collapse Telegram Connected-->
          
          <!--begin::Collapse Telegram Access-->
          <div
            v-if="notify.oauth && notify.type === 'telegram' && !userStore.messageStoreTG?.access" 
            class="collapse"
            id="kt_collapse_access_messageTG"
          >
            <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mt-6">
              <KTIcon
                icon-name="information-5"
                icon-class="fs-2tx text-warning me-4"
              />
              <div class="d-flex flex-stack flex-grow-1">
                <div class="fw-semibold">
                  <h4 class="text-gray-800 fw-bold">
                    {{ $t('pages.account.layoutNotifications.infoNotifiTGTitle') }}
                  </h4>
                  <div v-html="formattedInfoTGText" class="fs-6 text-gray-600"></div>
                </div>
              </div>
            </div>
          </div>
          <!--end::Collapse Telegram Access-->

          <!--begin::Collapse Phone-->
          <div
            v-if="notify.type === 'phone' && !userStore.user[notify.field]" 
            class="collapse"
            id="kt_collapse_connected_phone"
          >
            <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mt-6">
              <KTIcon
                icon-name="information-5"
                icon-class="fs-2tx text-warning me-4"
              />
              <div class="d-flex flex-stack flex-grow-1">
                <div class="fw-semibold">
                  <h4 class="text-gray-800 fw-bold">
                    {{ $t('pages.account.layoutNotifications.infoConnectedPhoneTitle') }}
                  </h4>
                  <div class="fs-6 text-gray-600">
                    {{ $t('pages.account.layoutNotifications.infoConnectedPhoneText') }}
                    <router-link
                      :to="{ path: '/account/settings' }"
                    >
                      {{ $t('pages.account.layoutNotifications.infoConnectedLink') }}
                    </router-link>.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::Collapse Phone-->

          <div v-if="index < userStore.notifyChannels.length - 1" class="separator my-5"></div>
        </div>
        <!--end::Items Loop-->          
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->

    <div v-if="isLoading" class="overlay-layer card-rounded">
      <div class="spinner-border text-primary" role="status"></div>
    </div>
  </div>
  <!--end::Notification Channels-->
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useI18n } from "vue-i18n";
import { addToast } from '@/utils/toastManager';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { botNameTG, linkTG } from "@/utils/telegramAuth";

export default defineComponent({
  name: "account-settings-notification-channels",
  setup() {
    const { t } = useI18n();
    const userStore = useAuthStore();
    
    const isLoading = computed(() => !userStore.notifyChannels[0]?.id );
    
    const formattedInfoTGText = computed(() => 
      t('pages.account.layoutNotifications.infoNotifiTGText', {
        botNameTG: `<a href="${linkTG}" target="_blank">${botNameTG}</a>`
      })
    );

    const getViewName = (type: string) => {
      // Преобразуем первую букву в заглавную
      const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1);
      const account = userStore.oauthStore.find(account => account.id === capitalizedType);
      // Проверяем, что account найден и существует user
      return account?.user?.view_name || t('pages.account.layoutSettings.accountNotConnected');
    };
    
    const errorSwal = (notifyName, switchState) => {
      const messageKey = switchState === 'on' ? 'messages.text.error.switchOnNotices' : 'messages.text.error.switchOffNotices';
    
      Swal.fire({
        text: t(messageKey, { messager: notifyName }),
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: t('messages.button.okGotIt'),
        heightAuto: false,
        customClass: {
          confirmButton: 'btn btn-danger',
        },
      });
    };

    const toogleChannels = async (isChecked: boolean, notify: any, event: Event) => {
      // Сохранение текущего состояния чекбокса
      const originalChecked = !isChecked;
      
      if (userStore.isDemo) {
        setTimeout(() => {
          revertCheckboxState(event, originalChecked);
        }, 3000); // Задержка в 3 секунды
        return;
      }

      if (!isChecked) {
        await handleSwitchOff(notify, event, originalChecked);
      } else if (isChecked) {
        await handleSwitchOn(notify, event, originalChecked);
      }
    };
    
    // Вспомогательная функция для переключения на выключение
    async function handleSwitchOff(notify: any, event: Event, originalChecked: boolean) {
      Swal.fire({
        text: t('messages.text.warning.switchOffNotices', { messager: notify.name }),
        icon: 'warning',
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: t('messages.button.switchOff'),
        cancelButtonText: t('messages.button.noCancel'),
        heightAuto: false,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          event.target.disabled = true;
          try {
            const success = await userStore.fetchNotifyChannels({ type: notify.type, action: 'off' });
            if (success) {
              addToast(
                t('messages.any.success'),
                t('messages.text.success.switchOffNotices',
                { messager: notify.name }),
                'success'
              );
              await fetchNotifyData();
            } else {
              handleError(notify.name, 'off', event, originalChecked);
            }
          } catch (error) {
            handleError(notify.name, 'off', event, originalChecked);
          } finally {
            event.target.disabled = false;
          }
        } else {
          revertCheckboxState(event, originalChecked);
        }
      });
    }
    
    // Вспомогательная функция для переключения на включение
    async function handleSwitchOn(notify: any, event: Event, originalChecked: boolean) {
      event.target.disabled = true;
      try {
        const success = await userStore.fetchNotifyChannels({ type: notify.type, action: 'on' });
        if (success) {
          addToast(
            t('messages.any.success'),
            t('messages.text.success.switchOnNotices',
            { messager: notify.name }),
            'success'
          );
          await fetchNotifyData();
        } else {
          handleError(notify.name, 'on', event, originalChecked);
        }
      } catch (error) {
        handleError(notify.name, 'on', event, originalChecked);
      } finally {
        event.target.disabled = false;
      }
    }
    
    // Вспомогательная функция для обработки ошибок
    function handleError(notifyName: string, action: 'on' | 'off', event: Event, originalChecked: boolean) {
      errorSwal(notifyName, action);
      revertCheckboxState(event, originalChecked);
    }
    
    // Вспомогательная функция для возврата состояния чекбокса
    function revertCheckboxState(event: Event, originalChecked: boolean) {
      (event.target as HTMLInputElement).checked = originalChecked;
    }
    
    const fetchNotifyData = async () => {      
      await userStore.getSendMessageTG();
      await userStore.fetchNotifyChannels();
    };
    
    onMounted(async () => {
      await fetchNotifyData();
      await userStore.oauthList();
    });

    return {
      userStore,
      isLoading,
      getViewName,
      toogleChannels,
      formattedInfoTGText,
    };
  },
});
</script>
