import { defineStore } from 'pinia';
import ApiService from '@/core/services/ApiService';
import { handlerApiError, updateOrAddItems } from '@/utils/helpers/storeHelpers';

export const useAlertStore = defineStore('alert', {
  state: () => ({
    items: [] as any[],
    error: null as string | null,
    isLooked: false as boolean,
  }),

  actions: {
    setLooked(value: boolean) {
      this.isLooked = value;
    },

    async fetchData(params: FetchParams): Promise<void> {
      try {
        const { data } = await ApiService.get('pulse.table.alert.list.json', params);

        const items = data.result || [];
        updateOrAddItems(this.items, items, 'id');
        
        // Проверка элементов на наличие looked, отличного от '1'
        if (this.items.some(item => item.looked !== '1')) {
          this.setLooked(true);
        }
      } catch (error) {
        handlerApiError(this.errors, error);
      }
    },
    
    async checkView() {
      const ids = this.items.map(item => item.id);
      await ApiService.post('pulse.table.alert.check', { ids });
    },
  },
});
