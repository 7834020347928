<template>
  <div v-if="!shownCookieMessage" class="cookie-message">
    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="40" viewBox="0 0 39 40" fill="none" class="cookie-message__img">
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="2" width="39" height="38">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6266 2.81653C6.21529 4.91287 0 12.3087 0 21.1107C0 31.5428 8.73045 39.9996 19.5 39.9996C30.2696 39.9996 39 31.5428 39 21.1107C39 17.8965 38.1712 14.8698 36.7094 12.2202C35.7503 12.9189 34.5578 13.3329 33.2654 13.3329C31.9143 13.3329 30.6723 12.8804 29.6921 12.1232C28.5303 12.8868 27.128 13.333 25.6181 13.333C21.6901 13.333 18.4902 10.3132 18.3577 6.53952C16.4801 6.11284 15.0126 4.65504 14.6266 2.81653Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask0)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6266 2.81653C6.21529 4.91287 0 12.3087 0 21.1107C0 31.5428 8.73045 39.9996 19.5 39.9996C30.2696 39.9996 39 31.5428 39 21.1107C39 17.8965 38.1712 14.8698 36.7094 12.2202C35.7503 12.9189 34.5578 13.3329 33.2654 13.3329C31.9143 13.3329 30.6723 12.8804 29.6921 12.1232C28.5303 12.8868 27.128 13.333 25.6181 13.333C21.6901 13.333 18.4902 10.3132 18.3577 6.53952C16.4801 6.11284 15.0126 4.65504 14.6266 2.81653Z" fill="#B85F04"/>
      <ellipse cx="22.1757" cy="19.9999" rx="18.3529" ry="17.7778" fill="#D08333"/>
      <ellipse cx="32.8831" cy="21.481" rx="3.82353" ry="3.7037" fill="#D99740"/>
      <ellipse cx="13.0003" cy="14.0738" rx="3.82353" ry="3.7037" fill="#D89740"/>
      <ellipse cx="20.6453" cy="30.7405" rx="3.82353" ry="1.85185" fill="#D89740"/>
      <ellipse rx="2.67647" ry="1.48148" transform="matrix(-1 0 0 1 25.6175 31.8515)" fill="#572617"/>
      <ellipse rx="2.29412" ry="1.48148" transform="matrix(-1 0 0 1 6.88264 20.7402)" fill="#572617"/>
      </g>
      <path d="M16.2285 22.9629C16.794 26.9135 23.8149 26.9135 24.3999 22.9629" stroke="#181818" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14 17.0371C14.7429 17.1606 16.3771 17.7038 16.9714 18.889C16.1048 19.0124 14.7429 19.2593 14 20.0001" stroke="#181818" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M27.0005 17.4072C26.2576 17.5307 24.6233 18.0739 24.0291 19.2591C24.8957 19.3825 26.2576 19.6294 27.0005 20.3702" stroke="#181818" stroke-linecap="round" stroke-linejoin="round"/>
      <ellipse cx="28.296" cy="6.66654" rx="1.52941" ry="1.48148" fill="#D99740"/>
      <ellipse cx="21.0299" cy="2.59256" rx="1.14706" ry="1.11111" fill="#D99740"/>
      <ellipse cx="34.4128" cy="2.22222" rx="2.29412" ry="2.22222" fill="#D99740"/>
    </svg>
    <div class="cookie-message__text">
      {{ $t('cookieMessage.introText') }} 
      <span
        class="links"
        data-bs-toggle="modal"
        data-bs-target="#kt_modal_cookie_policy"
      >{{ $t('cookieMessage.cookieText') }}</span>
    </div>
    <button class="btn btn-sm btn-primary" @click="acceptCookies">
      {{ $t('buttons.agree') }}
    </button>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { CookieComponent } from "@/assets/ts/components";

const shownCookieMessage = ref(true);  // Изначально скрываем сообщение

const acceptCookies = () => {
  // Устанавливаем куку для подтверждения согласия пользователя
  CookieComponent.set('acceptCookies', true, { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) }); // Кука истекает через 1 год
  shownCookieMessage.value = true;  // Скрываем сообщение после согласия
};

onMounted(() => {
  const cookieValue = CookieComponent.get('acceptCookies');
  
  if (cookieValue !== 'true') {
    // Добавляем задержку в 5 секунд перед показом сообщения
    setTimeout(() => {
      shownCookieMessage.value = false;
    }, 5000);
  }
});
</script>

<style>
.cookie-message {
  position: fixed;
  bottom: 24px;
  left: 24px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  white-space: nowrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  height: auto;
  margin: 0 auto;
  padding: 14px 16px;
  white-space: nowrap;
  border-radius: 10px;
  box-shadow: var(--bs-tooltip-box-shadow);
  background: var(--bs-app-bg-color);
  visibility: visible;
  opacity: 1;
  z-index: 1030;
}

.cookie-message__img {
  width: 30px;
  height: auto;
}

.cookie-message__text {
  margin: 0 16px;
  color: var(--bs-gray-800);
  font-size: 1.03rem;
  font-weight: 500;
  line-height: 24px;
}

.cookie-message__text .links {
  color: var(--bs-primary);
  cursor: pointer;
}

@media (max-width: 575px) {
  .cookie-message {
    left: 50%;
    transform: translateX(-50%);
  }
  .cookie-message__text {
    margin: 0 12px;
  }
  .cookie-message__img {
    width: 26px;
  }
}
</style>