<template>
  <!--begin::Form-->
  <VForm
    class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
    @submit="onSubmitForgotPassword"
    id="kt_login_password_reset_form"
    :validation-schema="forgotPassword"
  >
    <!--begin::Heading-->
    <div class="text-center mb-10">
      <!--begin::Title-->
      <h1 class="text-gray-900 fw-bolder mb-3">
        {{ $t('pages.authentication.passwordReset.pageTitle')}}
      </h1>
      <!--end::Title-->
      <!--begin::Subtitle-->
      <div class="text-gray-500 fw-semibold fs-6">
        {{ $t('pages.authentication.passwordReset.subtitle')}}
      </div>
      <!--end::Subtitle--->
    </div>
    <!--begin::Heading-->

    <!--begin::Input group-->
    <div class="fv-row mb-8">
      <Field
        class="form-control form-control-solid"
        type="email"
        :placeholder="$t('pages.authentication.email')"
        name="email"
        autocomplete="off"
        :validate-on-input="false"
      />
      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          <ErrorMessage name="email" />
        </div>
      </div>
    </div>
    <!--end::Input group-->

    <!--begin::Actions-->
    <div class="d-flex flex-nowrap justify-content-center pb-lg-0">
      <button
        type="submit"
        id="kt_password_reset_submit"
        class="btn btn-lg btn-primary fw-bold w-100 me-4"
        :data-kt-indicator="isSubmitting ? 'on' : null"
        :disabled="isSubmitting"
      >
        <span v-if="!isSubmitting" class="indicator-label"> {{ $t('pages.authentication.passwordReset.button')}} </span>
        <span v-else class="indicator-progress">
          {{ $t('pages.authentication.passwordReset.wait')}} 
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      </button>
    
      <router-link to="/sign-in" class="btn btn-lg btn-light-primary fw-bold w-100">
        {{ $t('buttons.cancel')}}
      </router-link>
    </div>
    <!--end::Actions-->
  </VForm>
  <!--end::Form-->
</template>

<script lang="ts" setup>
import { defineComponent, ref, computed } from "vue";
import { ErrorMessage, Field, Form as VForm } from "vee-validate";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";

const { t } = useI18n();
const store = useAuthStore();
const router = useRouter();

const isSubmitting = ref<boolean>(false);

const createForgotPasswordSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
    .required(t('validation.requiredField', { field: t('pages.authentication.email') }))
    .email(t('validation.email'))
    .min(6, t('validation.min', { min: 6 })),
  });
};

const forgotPassword = computed(() => createForgotPasswordSchema());

// Функция отправки формы
const onSubmitForgotPassword = async (values: any) => {
  values = values as string;

  isSubmitting.value = true; // Активируем индикатор загрузки

  // Отправляем запрос на восстановление пароля
  await store.forgotPassword(values);

  const error = Object.values(store.errors);

  if (error.length === 0) {
    Swal.fire({
      text: t('messages.text.success.passwordReset'),
      icon: "success",
      buttonsStyling: false,
      confirmButtonText: t('messages.button.okGotIt'),
      heightAuto: false,
      customClass: {
        confirmButton: "btn fw-semibold btn-light-primary",
      },
    }).then(() => {
      // Переход на страницу после успешного восстановления пароля
      router.push({ name: "sign-in" });
    });
  } else {
    Swal.fire({
      text: error[0] as string,
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: t('messages.button.tryAgain'),
      heightAuto: false,
      customClass: {
        confirmButton: "btn fw-semibold btn-light-danger",
      },
    }).then(() => {
      store.errors = {};
    });
  }

  isSubmitting.value = false; // Деактивируем индикатор загрузки
};
</script>
