import { defineStore } from 'pinia';
import ApiService from '@/core/services/ApiService';
import { handlerApiError } from '@/utils/helpers/storeHelpers';

interface PriceChartData {
  date: string[];
  price: (number | null)[];
}

interface FetchParams {
  id: number;
  period: string;
}

interface ItemsData {
  id: number;
  item_id: number;
  price: number;
  ai_score: number;
  timestamp_x: string;
  title: string;
  image: string;
  link: string;
  rating: number;
  platformId: number;
}

export const usePriceChartStore = defineStore('priceChart', {
  state: () => ({
    dataObj: {
      date: [] as string[],
      price: [] as (number | null)[]
    } as PriceChartData,
    items: [] as ItemsData[],
    errors: [] as string[],
    isLoading: true as boolean,
  }),

  actions: {
    /**
     * Асинхронно запрашивает и обрабатывает данные цен и связанных элементов из API.
     *
     * @param params - Параметры для запроса, содержащие информацию, необходимую для получения данных.
     * @returns `void`
     *
     * После выполнения запроса:
     * - Сохраняет в `dataObj.date` даты из полученных данных.
     * - Преобразует данные цен `fetchedData.price`, заменяя пустые строки и `NaN` на `null`, 
     *   и сохраняет их в `dataObj.price`.
     * - Обновляет массив `items`, содержащий элементы, связанные с данными цен.
     * - В случае ошибки вызовет `handlerApiError` для обработки ошибок.
     * - В `finally` блоке устанавливает `isLoading` в `false`, указывая, что загрузка завершена.
     */
    async fetchData(params: FetchParams): Promise<void> {
      return await ApiService.get("pulse.table.price.list.json", params)
        .then(({ data }) => {
          const fetchedData = data.result;
          // Обработка данных: замена пустых значений или NaN на null
          const processedData = fetchedData.price.map((price: string) =>
            price === "" || isNaN(Number(price)) ? null : Number(price)
          );
          
          this.dataObj.date = fetchedData.date;
          this.dataObj.price = processedData;
          this.items = fetchedData.items;
        })
        .catch(error => handlerApiError(this.errors, error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    
    /**
     * Асинхронно удаляет элементы на сервере по идентификатору поиска и списку идентификаторов элементов.
     *
     * @param searchId - Идентификатор поиска, по которому выполняется удаление.
     * @param ids - Массив идентификаторов элементов, которые необходимо удалить.
     * @param uniqueWords - Необязательный массив строк, содержащий уникальные слова, связанные с удаляемыми элементами.
     * @returns `Promise<boolean>` - Возвращает `true`, если удаление прошло успешно, и `false` в случае ошибки.
     *
     * Использует метод `delete` из `ApiService` для отправки запроса на удаление. 
     * В случае ошибки вызывает `handlerApiError` с указанием специфического сообщения об ошибке.
     */
    async deleteItems(searchId: number, ids: number[], uniqueWords?: string[]): Promise<boolean> {
      return await ApiService.delete(
        "pulse.table.price.delete", searchId, ids, uniqueWords ?? []
      )
        .then(({ data }) => {
          return data.result ? true : false;
        })
        .catch(error => handlerApiError(this.errors, error, "deleteItems"));
    },
    
    resetLoading() {
      this.isLoading = true;
    },
  },
});
