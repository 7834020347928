<template>
  <!-- Используем v-if для отложенной загрузки компонента -->
  <div
    v-if="chartData" 
    :class="['d-flex flex-column', alignClass]"
  >
    <apexchart
      ref="chartRef"
      :key="chartData"
      :class="['h-35px w-50px', chartClass]"
      :options="chart"
      :series="series"
      type="area"
    ></apexchart>

    <template v-if="stat">
      <span v-if="stat.new">
        <KTIcon icon-name="arrows-circle" icon-class="fs-7 text-gray-500 align-middle" />
        <span class="fs-8 text-gray-500 ms-1">{{ stat.num }} %</span>
      </span>
      <span v-else-if="stat.up">
        <KTIcon icon-name="arrow-down" icon-class="fs-7 text-success align-middle" />
        <span class="fs-8 text-success ms-1">{{ stat.num }} %</span>
      </span>
      <span v-else>
        <KTIcon icon-name="arrow-up" icon-class="fs-7 text-danger align-middle" />
        <span class="fs-8 text-danger ms-1">{{ stat.num }} %</span>
      </span>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useThemeStore } from "@/stores/theme";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import type { ApexOptions } from "apexcharts";
import type VueApexCharts from "vue3-apexcharts";

export default defineComponent({
  name: "search-chart",
  props: {
    chartData: {
      type: Object,
      required: false,
      default: [""]
    },
    chartClass: {
      type: String,
      required: false
    },
    alignClass: {
      type: String,
      required: false,
      default: 'align-items-center'
    }
  },
  setup(props) {
    const chartRef = ref<typeof VueApexCharts | null>(null);
    const chart = ref<ApexOptions>({});
    const series = ref([ { name: "", data: [] } ]);
    const initialStat = { up: true, num: '0.00', new: false };
    const stat = ref({ ...initialStat });
    
    const store = useThemeStore();
    const themeMode = computed(() => store.mode);
    const chartColor = ref<string>("success");

    const removeNulls = (data: string[]) => {
      return data.filter(value => value !== '').map(value => Number(value));
    };
    
    const resetStat = () => {
      // Сброс значений stat перед каждым обновлением данных
      stat.value = { ...initialStat };
    };
    
    const setStatAndColor = (isUp: boolean) => {
      stat.value.up = isUp;
      chartColor.value = isUp ? "success" : "danger";
    };
    
    const fetchChartData = () => {
      resetStat(); // Сбрасываем stat перед загрузкой новых данных
    
      if (props.chartData?.length > 0) {
        const filledPrices = removeNulls(props.chartData);
        const seriesData = series.value[0].data; // Create a reference to series data
    
        seriesData.length = 0; // Clear the existing data
    
        filledPrices.forEach((item, index) => {
          seriesData.push({ x: index, y: item });
        });
    
        if (seriesData.length === 0) {
          // Генерация случайного графика
          for (let x = 0; x <= 10; x++) {
            seriesData.push({ x, y: Math.floor(Math.random() * 6) + 1 });
          }
          
          /*
          seriesData.push(
            { x: 0, y: 1 },
            { x: 1, y: 1 },
            { x: 2, y: 3 },
            { x: 3, y: 2 },
            { x: 4, y: 3 },
            { x: 5, y: 3 },
            { x: 6, y: 2 },
          );
          */

          chartColor.value = "gray-400"
          stat.value.new = true;
          chart.value = chartOptions(chartColor.value);
          return;
        }
    
        if (seriesData.length > 1) {
          const firstValue = seriesData[0].y; // Первое значение
          const lastValue = seriesData[seriesData.length - 1].y; // Последнее значение
    
          // Предотвращение деления на ноль
          if (firstValue !== 0) {
            // Вычисляем разницу в процентах между первым и последним значением
            const percentChange = ((Math.abs(lastValue - firstValue) / firstValue) * 100).toFixed(2);
            stat.value.num = percentChange;
    
            setStatAndColor(lastValue <= firstValue);
          } else {
            setStatAndColor(true); // Если firstValue равно 0
          }
        } else {
          setStatAndColor(true); // Если данных меньше двух
        }
      } else {
        setStatAndColor(true); // Если данных нет
      }
    
      chart.value = chartOptions(chartColor.value);
    };

    onMounted(() => {      
      fetchChartData();
    });
    
    watch(() => props.chartData, () => {
      fetchChartData();
    }, { deep: true });
        
    watch(themeMode, () => {
      if (!chartRef.value) {
        return;
      }
      
      if (props.chartData && props.chartData.length > 0) {
        chartRef.value.updateOptions(chartOptions(chartColor.value));
      }
    });
    
    return {
      chart,
      chartRef,
      series,
      stat,
    };
  },
});

const chartOptions = (
  color: string = "success",
  height: string = "auto",
): ApexOptions => {
  const strokeColor = getCSSVariableValue("--bs-gray-300");
  const baseColor = getCSSVariableValue(`--bs-${color}`);
  const lightColor = getCSSVariableValue(`--bs-body-bg`);

  return {
    chart: {
        fontFamily: 'inherit',
        type: 'area',
        height: height,
        toolbar: {
            show: false
        },
        zoom: {
            enabled: false
        },
        sparkline: {
            enabled: true
        },
        animations: {
            enabled: false, // Включаем анимацию
        }
    },
    plotOptions: {},
    legend: {
        show: false
    },
    dataLabels: {
        enabled: false
    },
    fill: {
        type: 'solid',
        opacity: 1
    },
    stroke: {
        curve: 'smooth',
        show: true,
        width: 2,
        colors: [baseColor]
    },
    xaxis: {                 
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false
        },
        labels: {
            show: false                   
        },
        crosshairs: {
            show: false,
            position: 'front',
            stroke: {
                color: strokeColor,
                width: 1,
                dashArray: 3
            }
        },
        tooltip: {
            enabled: false
        }
    },
    yaxis: {
        labels: {
            show: false                     
        }
    },
    states: {
        normal: {
            filter: {
                type: 'none',
                value: 0
            }
        },
        hover: {
            filter: {
                type: 'none',
                value: 0
            }
        },
        active: {
            allowMultipleDataPointsSelection: false,
            filter: {
                type: 'none',
                value: 0
            }
        }
    },
    tooltip: {
        enabled: false
    },
    colors: [lightColor],
    markers: {
        colors: [lightColor],
        strokeColor: [baseColor],
        strokeWidth: 3
    }
  };
};
</script>
