export interface IOAuthData {
  id: string;
  name: string;
  icon: string;
  link?: string;
}

export const oauthData = [
  {
    id: "Telegram",
    name: "Telegram",
    icon: "telegram",
    link: "",
  },
  {
    id: "GoogleOAuth",
    name: "Google",
    icon: "google",
    link: "",
  }
];