// https://github.com/fergiemcdowall/stopword/tree/main

// Списки русских и английских стоп-слов
const rus = ['и','в','во','не','что','он','на','я','с','со','как','а','то','все','она','так','его','но','да','ты','к','у','же','вы','за','бы','по','только','ее','мне','было','вот','от','меня','еще','нет','о','из','ему','теперь','когда','даже','ну','ли','если','уже','или','ни','быть','был','него','до','вас','нибудь','уж','вам','сказал','ведь','там','потом','себя','ничего','ей','может','они','тут','где','есть','надо','ней','для','мы','тебя','их','чем','была','сам','чтоб','без','будто','чего','раз','тоже','себе','под','будет','ж','тогда','кто','этот','того','потому','этого','какой','совсем','ним','этом','почти','мой','тем','чтобы','нее','были','куда','всех','никогда','сегодня','можно','при','об','другой','хоть','после','над','больше','тот','через','эти','нас','про','всего','них','какая','много','разве','эту','моя','свою','этой','перед','иногда','лучше','чуть','том','нельзя','такой','им','более','всегда','конечно','всю','между','это','лишь'];

const eng = ['about','after','all','also','am','an','and','another','any','are','as','at','be','because','been','before','being','between','both','but','by','came','can','come','could','did','do','each','for','from','get','got','has','had','he','have','her','here','him','himself','his','how','if','in','into','is','it','like','make','many','me','might','more','most','much','must','my','never','now','of','on','only','or','other','our','out','over','said','same','should','since','some','still','such','take','than','that','the','their','them','then','there','these','they','this','those','through','to','too','under','up','very','was','way','we','well','were','what','where','which','while','who','with','would','you','your','a','i'];

// Объединяем стоп-слова в один массив
const stopWords = new Set([...rus, ...eng]);

export { rus, eng, stopWords }