<template>
  <div class="card card-flush">
    <div class="card-header flex-nowrap py-5 gap-0 gap-md-5">
      <div class="card-title">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bold fs-3 mb-1">
            {{ $t('pages.searchId.layoutLinks.titleCard') }}
          </span>
          <span class="text-muted mt-1 fw-semibold fs-7">
            {{ $t('pages.searchId.layoutLinks.total') }} 
            <SmoothCounter :targetValue="countData" />
          </span>
        </h3>
      </div>
      
      <!-- Панель инструментов карточки -->
      <div class="card-toolbar">
        <div class="d-flex flex-nowrap">
          <select
            class="form-select form-select-white form-select-sm fw-bold"
            name="platform"
            v-model="selectedPlatform"
            :aria-label="$t('pages.searchId.layoutList.titleTable.platform')"
          >
            <option value="0" selected>
              {{ $t('pages.searchId.layoutList.allPlatforms') }}
            </option>
            <option
              v-if="itemStore.platforms.length > 0"
              v-for="platform in itemStore.platforms"
              :key="platform.id"
              :value="platform.id"
            >
              {{ platform.title }}
            </option>
          </select>

          <button
            type="button" 
            class="btn btn-sm btn-light btn-primary px-4 ms-3 text-nowrap"
            @click="handleLinksModal"
            :disabled="isLoading"
          > 
            <KTIcon icon-name="mouse-square" icon-class="fs-3 me-w d-none d-sm-inline" />
            {{ $t('actions.edit') }}
          </button>
        </div>
      </div>
    </div>

    <div class="card-body pt-0 pb-5">
      <Datatable
        v-if="!isLoading"
        class="table align-middle table-row-dashed gy-3"
        :header="tableHeader"
        :data="sortedFilteredLinks"
        :items-per-page="itemsPerPage"
        :items-per-page-dropdown-enabled="itemsPerPageDropdown"
        :preload-count="10"
        :emptyTableText="$t('dataTable.emptyTable')"
        @on-sort="handleSort"
        @on-items-per-page-change="handleItemsPerPage"
      >
        <template v-slot:link="{ row: item }">
          <div class="d-flex align-items-center min-w-200px">
            <a
              :href="item.link || '#'"
              :aria-label="item.id || 'Unknown'"
              class="symbol symbol-50px"
              target="_blank"
            >
              <div
                class="symbol-label bg-light"
                :style="getBackgroundStyle(item.platform)"
              />
            </a>
  
            <div class="d-flex flex-column ms-5">
              <a
                :href="item.link"
                target="_blank"
                rel="noopener noreferrer"
                :class="[
                  'fw-bold text-hover-primary fs-5 mb-1',
                  item.status == 1 ? 'text-gray-800' : 'text-danger'
                ]"
              >
                {{ formattedTitle(item.link) }}
              </a>
              <div class="text-muted fs-7">
                {{ getPlatformTitle(item.platform) }} 
              </div>
            </div>
          </div>
        </template>
        
        <template v-slot:count="{ row: item }">
          <PriceFormatter :price="item.count" :showCurrency="false"  class="text-nowrap text-gray-600 fw-semibold" />
        </template>

        <template v-slot:success="{ row: item }">
          <div :class="['fw-semibold', item.success > 0 ? 'text-success' : 'text-muted']">
            {{ item.success }}
          </div>
        </template>

        <template v-slot:unavailable="{ row: item }">
          <div :class="['fw-semibold', item.unavailable > 0 ? 'text-warning' : 'text-muted']">
            {{ item.unavailable }}
          </div>
        </template>
        
        <template v-slot:fail="{ row: item }">
          <div :class="['d-flex align-items-center justify-content-end fw-semibold', item.fail > 0 ? 'text-danger' : 'text-muted']">
            <i
              v-if="item.status == 0"
              class="ki-duotone ki-information-5 me-1 fs-5"
              v-tooltip data-bs-custom-class="tooltip-inverse"
              :title="$t('pages.items.modal.error.tooltip')"
            >
             <span class="path1"></span>
             <span class="path2"></span>
             <span class="path3"></span>
            </i>
            {{ item.fail }}
          </div>
        </template>
        
        <template v-slot:status="{ row: item }">
          <StatusBadge
            class="mx-5"
            :status="item.status"
            :archive="item.archive"
            :links="item.isLinks"
          />
        </template>
        
        <template v-slot:next_run="{ row: item }">
          <div :class="['d-flex flex-column', { 'opacity-50' : item.status == 0 }]">
            <template v-if="item.next_run">
              <DateFormatter 
                :date="item.next_run" 
                :format="'d/m/Y'"
                class="text-nowrap fw-bold text-gray-800"
              />
              <DateFormatter 
                :date="item.next_run" 
                :format="'в H ч. i мин.'" 
                class="text-nowrap text-muted fw-semibold text-muted fs-7"
              />
           </template>
            <div v-else>
              <KTIcon icon-name="time" icon-class="fs-3" />
            </div>
          </div>
        </template>

        <template v-slot:links_date_update="{ row: item }">
          <div class="d-flex flex-column">
            <DateFormatter 
              :date="item.links_date_update" 
              :format="'d/m/Y'"
              class="text-nowrap fw-bold text-gray-800"
            />
            <DateFormatter 
              :date="item.links_date_update" 
              :format="'в H ч. i мин.'" 
              class="text-nowrap text-muted fw-semibold text-muted fs-7"
            />
          </div>
        </template>
        
      </Datatable>
      
      <Placeholder
        v-else
        :length="5"
        :table-header="tableHeader"
        :items-per-page="itemsPerPage"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { useRoute } from 'vue-router';
import { useI18n } from "vue-i18n";
import { useConfigStore } from "@/stores/config";
import { useItemStore } from "@/stores/item";
import { truncateTitle } from '@/utils/helpers/listHelpers';
import { getAssetPath } from "@/core/helpers/assets";

import arraySort from 'array-sort';
import Datatable from "@/components/kt-datatable/KTDataTable.vue";
import DateFormatter from '@/components/formatters/DateFormatter.vue';
import Placeholder from '@/components/placeholder/ItemsLinks.vue';
import SmoothCounter from '@/components/formatters/SmoothCounter.vue'
import StatusBadge from "@/components/items/StatusBadge.vue";
import PriceFormatter from '@/components/formatters/PriceFormatter.vue';

export default defineComponent({
  name: "search-id-links",
  components: {
    Datatable,
    DateFormatter,
    Placeholder,
    SmoothCounter,
    StatusBadge,
    PriceFormatter,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const bodyStore = useConfigStore();
    const itemStore = useItemStore();
    
    const sortState = ref({ label: null, order: 'asc' });
    const selectedPlatform = ref('0');
    const itemsPerPage = ref(bodyStore.getLayoutConfig('datatable.itemsPerPage'));

    const tableHeader = ref([
      {
        columnName: t('pages.searchId.layoutLinks.titleTable.link'),
        columnLabel: "link",
        columnClass: "min-w-150px",
        sortEnabled: true,
      },
      {
        columnName: t('pages.searchId.layoutLinks.titleTable.count'),
        columnLabel: "count",
        sortEnabled: true,
        columnClass: "min-w-100px text-end pe-0",
      },
      {
        columnName: t('pages.searchId.layoutLinks.titleTable.success'),
        columnLabel: "success",
        columnClass: "min-w-100px text-end pe-0",
        sortEnabled: true,
      },
      {
        columnName: t('pages.searchId.layoutLinks.titleTable.unavailable'),
        columnLabel: "unavailable",
        columnClass: "min-w-125px text-nowrap text-end pe-0",
        sortEnabled: true,
      },
      {
        columnName: t('pages.searchId.layoutLinks.titleTable.fail'),
        columnLabel: "fail",
        columnClass: "min-w-100px text-end pe-0",
        sortEnabled: true,
      },
      {
        columnName: t('pages.searchId.layoutLinks.titleTable.status'),
        columnLabel: "status",
        columnClass: "min-w-100px text-center pe-0",
        sortEnabled: true,
      },
      {
        columnName: t('pages.searchId.layoutLinks.titleTable.next_run'),
        columnLabel: "next_run",
        columnClass: "min-w-100px text-nowrap text-end pe-0",
        sortEnabled: true,
      },
/*       {
        columnName: t('pages.searchId.layoutLinks.titleTable.links_date_update'),
        columnLabel: "links_date_update",
        columnClass: "min-w-125px text-nowrap text-end pe-0",
        sortEnabled: true,
      }, */
    ]);

    // Устанавливает новое состояние сортировки
    const handleSort = (sort: { label: string; order: 'asc' | 'desc' }) => {
      sortState.value = sort;
    };

    // Указывает, находится ли компонент в состоянии загрузки
    const isLoading = computed(() => {
      return !itemStore.searchs.some(item => Number(item.id) === Number(route.params.id));
    });
    
    // Возвращает количество отфильтрованных элементов, если загрузка завершена
    const countData = computed(() => {
      return !isLoading.value ? filteredItems.value.length : 0;
    });
    
    // Возвращает идентификатор поиска из маршрута
    const searchId = computed(() => Number(route.params.id) || 0);
    
    // Определяет, требуется ли отображать выпадающее меню для элементов на странице
    const itemsPerPageDropdown = computed(() => sortedFilteredLinks.value?.length >= 10);
    
    // Находит данные для текущего поиска по идентификатору
    const searchData = computed(() => {
      const itemIndex = itemStore.searchs.findIndex(item => Number(item.id) === Number(searchId.value));
      return itemIndex !== -1 ? itemStore.searchs[itemIndex] : null;
    });
    
    // Трансформирует данные платформы в массив для дальнейшего использования
    const transformedPlatformData = computed(() => {
      const result: Array<Record<string, any>> = [];
      if (searchData.value?.platform) {
        Object.entries(searchData.value.platform).forEach(([platformId, platformContent]) => {
          if (platformContent.links) {
            Object.keys(platformContent.links).forEach((linkId) => {
              result.push({
                id: linkId,
                link: platformContent.links[linkId],
                count: platformContent.count?.[linkId],
                status: platformContent.status?.[linkId],
                success: platformContent.success?.[linkId],
                unavailable: platformContent.unavailable?.[linkId],
                fail: platformContent.fail?.[linkId],
                next_run: platformContent.next_run?.[linkId],
                links_date_update: platformContent.links_date_update?.[linkId],
                platform: platformId,
              });
            });
          }
        });
      }
      return result;
    });
    
    // Фильтрует элементы в зависимости от выбранной платформы
    const filteredItems = computed(() => {
      return transformedPlatformData.value?.filter(item => selectedPlatform.value === '0' || item.platform === selectedPlatform.value);
    });
    
    // Сортирует и фильтрует элементы в зависимости от текущего состояния сортировки
    const sortedFilteredLinks = computed(() => {
      const items = filteredItems.value.slice(); // Копируем массив перед сортировкой
      if (sortState.value.label && items.length > 0) {
        const reverse = sortState.value.order === 'desc';
        const firstItem = items[0][sortState.value.label];
        const isNumeric = !isNaN(parseFloat(firstItem)) && isFinite(firstItem);
    
        if (isNumeric) {
          // Сортируем числа
          return arraySort(items, (a, b) => {
            const aValue = parseFloat(a[sortState.value.label]);
            const bValue = parseFloat(b[sortState.value.label]);
            return reverse ? bValue - aValue : aValue - bValue;
          });
        } else {
          // Сортируем строки или другие данные
          return arraySort(items, sortState.value.label, { reverse });
        }
      }
      return items;
    });
    
    // Возвращает название платформы по её идентификатору
    const getPlatformTitle = (platformId: string): string => {
      const platform = itemStore.platforms.find(p => p.id === platformId);
      return platform ? platform.title : '';
    };
    
    // Функция для получения стиля фона
    const getBackgroundStyle = (platformId: string) => {
      const platform = itemStore.platforms.find(p => p.id === platformId);
      return `background-image: url('${getAssetPath(`media/svg/brand-logos/${platform.code}.svg`)}');`;
    };
    
    // Форматирует URL, удаляя протокол и `www`
    const getFormattedUrl = (link: string): string => {
      return link
        .replace(/^https?:\/\//, '') // Убираем протокол
        .replace(/^www\./, ''); // Убираем www
    };
    
    // Устанавливает количество элементов на странице в хранилище
    const handleItemsPerPage = (val: number): void => {
      bodyStore.setLayoutConfigProperty("datatable.itemsPerPage", val);
    };

    // Выполняет программный клик по кнопке с указанным ID (target-button-links)
    const handleLinksModal = () => {
      const targetButton = document.getElementById('target-button-links');
      if (targetButton) {
        targetButton.click();
      }
    };
    
    const formattedTitle = (link: string): string => {
      if (/^\d+$/.test(link)) {
        // Проверяем, состоит ли строка `title` только из цифр
        return `SKU ${link}`;
      }
    
      if (searchData.value?.search_by_title == 1) {
        return truncateTitle(searchData.value?.title, 50);
      }
    
      const clearUrl = getFormattedUrl(link);
      return truncateTitle(clearUrl, 35);
    };
    
    return {
      tableHeader,
      countData,
      itemStore,
      searchData,
      itemsPerPageDropdown,
      getPlatformTitle,
      getBackgroundStyle,
      getFormattedUrl,
      selectedPlatform,
      sortedFilteredLinks,
      handleSort,
      handleItemsPerPage,
      isLoading,
      itemsPerPage,
      formattedTitle,
      handleLinksModal,
    };
  },
});
</script>