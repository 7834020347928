<template>
  <!--begin::Page title-->
  <div v-if="pageTitleDisplay" :class="`page-title d-flex flex-${pageTitleDirection} justify-content-center flex-wrap me-3`">
    <template v-if="pageTitle">
      <!--begin::Title-->
      <h1 class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
        {{ pageTitle }}
      </h1>
      <!--end::Title-->

      <span v-if="pageTitleDirection === 'row' && pageTitleBreadcrumbDisplay" class="h-20px border-gray-200 border-start mx-3"></span>

      <!--begin::Breadcrumb-->
      <ul v-if="breadcrumbs && pageTitleBreadcrumbDisplay" class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">
          <router-link to="/" class="text-muted text-hover-primary">{{ $t('menu.home') }}</router-link>
        </li>
        <!--end::Item-->
        <template v-for="(item, i) in breadcrumbs" :key="i">
          <!--begin::Item-->
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-500 w-5px h-2px"></span>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li class="breadcrumb-item text-muted">
            <template v-if="item.path">
              <router-link :to="item.path" class="text-muted text-hover-primary">{{ item.label }}</router-link>
            </template>
            <template v-else>
              {{ item.label }}
            </template>
          </li>
          <!--end::Item-->
        </template>
      </ul>
      <!--end::Breadcrumb-->
    </template>
  </div>
  <div v-else class="align-items-stretch"></div>
  <!--end::Page title-->
</template>

<script lang="ts">
import { defineComponent, computed, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { pageTitleBreadcrumbDisplay, pageTitleDirection, pageTitleDisplay } from "@/layouts/default-layout/config/helper";

export default defineComponent({
  name: "layout-page-title",
  setup() {
    const route = useRoute();
    const { t } = useI18n();

    // Заголовок страницы
    const pageTitle = computed(() => {
      return t(route.meta.pageTitle);
    });

    // Динамическое изменение заголовка документа
    watchEffect(() => {
      if (pageTitle.value) {
        document.title = `${pageTitle.value} - ${import.meta.env.VITE_APP_NAME}`; // Устанавливаем заголовок документа
      }
    });

    // Хлебные крошки
    const breadcrumbs = computed(() => {
      return route.meta.breadcrumbs.map((crumb: any) => {
        return {
          label: t(crumb.label),
          path: crumb.path,
        };
      });
    });
    
    return {
      pageTitle,
      breadcrumbs,
      pageTitleDisplay,
      pageTitleBreadcrumbDisplay,
      pageTitleDirection,
    };
  },
});
</script>