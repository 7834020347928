<template>
  <span v-if="formattedPrice" class="text-nowrap">{{ formattedPrice }}</span>
</template>

<script>
export default {
  name: 'PriceFormatter',
  props: {
    price: {
      type: [Number, String],
      required: false, // Делается необязательным, так как может быть диапазон
    },
    minPrice: {
      type: [Number, String],
      required: false, // Делается необязательным, так как может быть одна цена
    },
    maxPrice: {
      type: [Number, String],
      required: false, // Делается необязательным, так как может быть одна цена
    },
    showCurrency: {
      type: Boolean,
      default: true
    },
    rangeCurrencyPosition: {
      type: String,
      default: 'each', // Значения могут быть 'each', 'end', 'none'
      validator: value => ['each', 'end', 'none'].includes(value)
    }
  },
  computed: {
    formattedPrice() {
      if (this.minPrice !== undefined && this.maxPrice !== undefined) {
        // Если заданы minPrice и maxPrice, форматируем как диапазон
        return this.formatPriceRange(this.minPrice, this.maxPrice);
      } else if (this.price !== undefined) {
        // Если задана только цена, форматируем её
        return this.formatPrice(this.price);
      }
      return '';
    }
  },
  methods: {
    formatPrice(value) {
      if (value === null || value === undefined) return ''; // Только проверка на null или undefined
      value = value.toString().replace(/\s+/g, '');
      value = parseInt(value, 10);
      if (isNaN(value)) return ''; // Проверка на NaN после преобразования
      return value.toLocaleString('ru-RU') + (this.showCurrency ? ' ₽' : '');
    },
    formatPriceRange(min, max) {
      // Преобразуем в число для сравнения
      min = parseInt(min, 10);
      max = parseInt(max, 10);

      if (isNaN(min) || isNaN(max)) return ''; // Если min или max не число, вернем пустую строку

      // Проверяем, нужно ли поменять местами min и max
      if (min > max) {
        [min, max] = [max, min]; // Меняем местами
      }

      // Если min и max равны, выводим только одно число
      if (min === max) {
        return this.formatPrice(min);
      }

      const formattedMin = this.formatPrice(min).replace(' ₽', ''); // Убираем символ рубля, чтобы добавить один раз
      const formattedMax = this.formatPrice(max).replace(' ₽', '');

      if (this.showCurrency) {
        if (this.rangeCurrencyPosition === 'each') {
          // Форматируем диапазон как "100 ₽ - 200 ₽"
          return `${formattedMin} ₽ - ${formattedMax} ₽`;
        } else if (this.rangeCurrencyPosition === 'end') {
          // Форматируем диапазон как "100 - 200 ₽"
          return `${formattedMin} - ${formattedMax} ₽`;
        }
      }
      // Форматируем диапазон без символа валюты "100 - 200"
      return `${formattedMin} - ${formattedMax}`;
    }
  }
};
</script>
