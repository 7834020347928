import { useBreakpoints, breakpointsBootstrapV5 } from '@vueuse/core';
import { ref, computed, watch, nextTick } from 'vue';

// Используем предустановленные breakpoints для Bootstrap5
const breakpoints = useBreakpoints(breakpointsBootstrapV5);
const isMobile = breakpoints.smaller('sm');

// Проверка на standalone режим
const isStandalone = ref(false);
if ('standalone' in navigator) {
  isStandalone.value = navigator.standalone;
}

// Объединенное состояние для isMobile и isStandalone
const isMobileStandalone = computed(() => isMobile.value && isStandalone.value);

// Функция для установки атрибута data-bs-mode на body в зависимости от состояния
const updateBodyMode = async () => {
  await nextTick();

  if (isMobileStandalone.value) {
    document.documentElement.setAttribute('data-bs-mode', 'mobile-standalone');
  } else if (isMobile.value && !isStandalone.value) {
    document.documentElement.setAttribute('data-bs-mode', 'mobile');
  } else if (isStandalone.value) {
    document.documentElement.setAttribute('data-bs-mode', 'standalone');
  } else {
    document.documentElement.setAttribute('data-bs-mode', 'default');
  }
};

// Следим за изменением состояния isStandalone и обновляем атрибут data-bs-mode на body
watch(isStandalone, updateBodyMode, { immediate: true });

// Следим за изменением состояния isMobileStandalone и обновляем атрибут data-bs-mode на body
watch(isMobileStandalone, updateBodyMode, { immediate: true });

// Следим за изменением состояния isMobile и обновляем атрибут data-bs-mode на body
watch(isMobile, updateBodyMode, { immediate: true });

export { isMobile, isStandalone, isMobileStandalone };
