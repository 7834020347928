<template>
  <div v-if="hasWishPrice" :class="wishPriceClass">
    <KTIcon icon-name="wallet" icon-class="fs-4 me-2" :class="iconClass" />
    <PriceFormatter :price="item.wishprice" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useItemStore } from '@/stores/item';

import PriceFormatter from '@/components/formatters/PriceFormatter.vue';

export default defineComponent({
  name: 'wish-price-display',
  components: {
    PriceFormatter,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const itemStore = useItemStore();
    
    const hasWishPrice = computed(() => props.item.wishprice > 0);
    
    const isSuccess = computed(() =>
      itemStore.priceComparison ? itemStore.priceComparison(props.item) : false
    );

    const wishPriceClass = computed(() => ({
      'd-flex align-items-center': true,
      'text-success': isSuccess.value,
      'text-gray-500': !isSuccess.value,
    }));
    
    const iconClass = computed(() => ({
      'text-success': isSuccess.value,
      'text-gray-500': !isSuccess.value,
    }));

    return {
      hasWishPrice,
      wishPriceClass,
      iconClass,
    };
  },
});
</script>
