<template>
  <div class="d-flex justify-content-end">
    <button
      class="btn btn-sm btn-icon btn-light-primary me-3"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom"
      data-kt-menu-flip="top-end"
    >
      <KTIcon icon-name="arrows-loop" icon-class="fs-3" />
    </button>

    <!--begin::Menu-->
    <div
      class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-4 w-175px mt-2 fs-6"
      data-kt-menu="true"
    >
      <!--begin::Menu CSV-->
      <div class="menu-item px-5">
        <div class="menu-content text-start text-muted pb-2 px-5 fs-7 text-uppercase">
          {{ $t('modal.csv.file') }}
        </div>
      </div>
      <!--end::Menu CSV-->
  
      <!--begin::Menu Import-->
      <div class="menu-item px-5">
        <div 
          data-bs-toggle="modal"
          data-bs-target="#modal_import_csv"
          class="menu-link px-4"
        > 
          <KTIcon icon-name="entrance-left" icon-class="fs-3 me-3" />
          {{ $t('modal.csv.import') }}
        </div>
      </div>
      <!--end::Menu Import-->
      
      <!--begin::Menu Export-->
      <div class="menu-item px-5">
        <div 
          data-bs-toggle="modal"
          data-bs-target="#modal_export_csv"
          class="menu-link px-4"
        > 
          <KTIcon icon-name="exit-right" icon-class="fs-3 me-3" />
          {{ $t('modal.csv.export') }}
        </div>
      </div>
      <!--end::Menu Export-->
    </div>
    <!--end::Menu-->
  </div>
  
  <ImportCSVModal />
  <ExportCSVModal />
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";

import ImportCSVModal from "@/components/modals/forms/ImportCSVModal.vue";
import ExportCSVModal from "@/components/modals/forms/ExportCSVModal.vue";

// Инициализация меню при монтировании компонента
onMounted(() => {
  MenuComponent.reinitialization();
});
</script>