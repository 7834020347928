import i18n from "@/core/plugins/i18n";

export interface MessageTG {
  access: boolean;
  connected: boolean;
}

export interface NotifyChannels {
  id?: number;
  type: string;
  name: string;
  icon: string;
  field?: string;
  oauth?: boolean;
  checked?: boolean;
}

export interface Notification {
  type: string;
  icon: string;
  checked: boolean;
}

export const notifyChannelsData = [
  {
    type: "email",
    name: i18n.global.t('email'),
    icon: "sms",
    field: "email",
  },
  {
    type: "telegram",
    name: i18n.global.t('messager.telegram'),
    icon: "messages",
    oauth: true,
  },
/*   {
    type: "phone",
    name: i18n.global.t('phone'),
    icon: "phone",
    field: "mobile_phone",
  } */
];

export const notificationData = [
  {
    type: "items",
    icon: "purchase",
    checked: true,
  },
  {
    type: "bills",
    icon: "cheque",
    checked: true,
  },
  {
    type: "newDevice",
    icon: "monitor-mobile",
    checked: true,
  },
/*   {
    id: "news",
    icon: "message-text",
  }, */
];
