<template>
  <div aria-hidden="true" class="d-flex flex-wrap flex-sm-nowrap mb-3 placeholder-glow">
    <div class="me-7 mb-4 order-2 order-sm-1">
      <div class="symbol symbol-125px symbol-sm-150px symbol-lg-160px symbol-fixed placeholder bg-secondary">
        <span class="symbol-label placeholder bg-secondary"></span>
      </div>
    </div>
    
    <template v-if="isMobile">
      <div class="flex-fill order-3 card min-w-150px py-3 px-4 mb-5">
        <div class="fs-3 rounded-1 placeholder bg-secondary col-10"></div>
        <div class="fs-7 rounded-1 placeholder bg-secondary col-10 mt-3"></div>
        <div class="fs-7 rounded-1 placeholder bg-secondary col-10 mt-3"></div>
        <div class="fs-6 rounded-1 placeholder bg-secondary col-12 mt-auto"></div>
      </div>
      <div class="d-flex flex-wrap flex-fill order-4 mt-3 gap-7">
        <div class="card flex-fill min-w-125px py-3 px-4">
          <div class="fs-3 rounded-1 placeholder bg-secondary col-10"></div>
          <div class="fs-8 rounded-1 placeholder bg-secondary col-10 mt-1"></div>
          <div class="fs-6 rounded-1 placeholder bg-secondary col-8 mt-3"></div>
        </div>
        <div class="card flex-fill min-w-125px py-3 px-4">
          <div class="fs-3 rounded-1 placeholder bg-secondary col-10"></div>
          <div class="fs-8 rounded-1 placeholder bg-secondary col-10 mt-1"></div>
          <div class="fs-6 rounded-1 placeholder bg-secondary col-8 mt-3"></div>
        </div>
      </div>
    </template>
    
    <div class="flex-grow-1 order-1 order-sm-2 w-100 w-sm-auto">
      <div class="d-flex justify-content-between align-items-start flex-nowrap">
        <div class="d-flex flex-column w-100 mt-1">
          <div class="fs-2 rounded-1 placeholder bg-secondary placeholder-lg col-10 col-md-6"></div>
          <div class="fs-4 d-flex flex-wrap my-4 gap-4">
            <div class="rounded-1 placeholder bg-secondary col-3 col-md-1 mb-2"></div>
            <div class="rounded-1 placeholder bg-secondary col-6 col-md-2 mb-2"></div>
          </div>
        </div>
        <div class="rounded-1 placeholder bg-secondary placeholder-lg h-35px w-35px"></div>
      </div>
      <div v-if="!isMobile" class="d-flex flex-wrap flex-row gap-5">
        <div class="card min-w-150px py-3 px-4">
          <div class="fs-3 rounded-1 placeholder bg-secondary col-10"></div>
          <div class="fs-8 rounded-1 placeholder bg-secondary col-10 mt-2"></div>
          <div class="fs-6 rounded-1 placeholder bg-secondary col-12 mt-4"></div>
        </div>
        <div class="card min-w-125px py-3 px-4">
          <div class="fs-3 rounded-1 placeholder bg-secondary col-10"></div>
          <div class="fs-8 rounded-1 placeholder bg-secondary col-10 mt-2"></div>
          <div class="fs-6 rounded-1 placeholder bg-secondary col-8 mt-4"></div>
        </div>
        <div class="card min-w-125px py-3 px-4">
          <div class="fs-3 rounded-1 placeholder bg-secondary col-10"></div>
          <div class="fs-8 rounded-1 placeholder bg-secondary col-10 mt-2"></div>
          <div class="fs-6 rounded-1 placeholder bg-secondary col-8 mt-4"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { isMobile } from '@/core/helpers/mobile';

export default defineComponent({
  name: 'placeholder-items-search-details',
  setup() {
    return {
      isMobile,
    };
  },
});
</script>