<template>
  <tbody class="fw-semibold text-gray-600">
    <template v-for="(row, i) in data.slice(0, visibleItemCount)" :key="i">
      <tr>
        <td v-if="checkboxEnabled">
          <div
            class="form-check form-check-sm form-check-custom form-check-solid"
          >
            <input
              class="form-check-input"
              type="checkbox"
              :value="row[checkboxLabel]"
              v-model="selectedItems"
              @change="onChange"
            />
          </div>
        </td>
        <template v-for="(properties, j) in header" :key="j">
          <td
            :class="{
              [properties.columnClass]: true,
              'text-end': j === header.length - 1
            }"
          >
            <slot :name="`${properties.columnLabel}`" :row="row">
              {{ row }}
            </slot>
          </td>
        </template>
      </tr>
    </template>
  </tbody>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from "vue";

export default defineComponent({
  name: "table-body-row",
  components: {},
  props: {
    header: { type: Array as () => Array<any>, required: true },
    data: { type: Array as () => Array<any>, required: true },
    currentlySelectedItems: { type: Array, required: false, default: () => [] },
    checkboxEnabled: { type: Boolean, required: false, default: false },
    checkboxLabel: {
      type: String as () => string,
      required: false,
      default: "id",
    },
    preloadCount: { type: Number, required: false },
  },
  emits: ["on-select"],
  setup(props, { emit }) {
    const selectedItems = ref<Array<any>>([]);
    const visibleItemCount = ref<number>(props.preloadCount);

    watch(
      () => props.data.length,
      (newLength) => {
          visibleItemCount.value = newLength;
      }
    );
    
    onMounted(() => {
      // Через короткое время увеличиваем количество видимых элементов до общего количества данных
      setTimeout(() => {
        visibleItemCount.value = props.data.length;
      }, 0);
    });

    watch(
      () => [...props.currentlySelectedItems],
      (currentValue) => {
        if (props.currentlySelectedItems.length !== 0) {
          selectedItems.value = [
            ...new Set([...selectedItems.value, ...currentValue]),
          ];
        } else {
          selectedItems.value = [];
        }
      }
    );

    const onChange = () => {
      emit("on-select", selectedItems.value);
    };

    return {
      selectedItems,
      onChange,
      visibleItemCount,
    };
  },
});
</script>
