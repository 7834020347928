<template>
  <div ref="dropzoneRef" class="dropzone mb-2" id="kt_dropzone_import_csv">
    <div class="dz-message needsclick">
      <KTIcon icon-name="file-up" icon-class="fs-3x text-primary" />
      <div class="ms-4">
        <h3 class="fs-5 fw-bold text-gray-900 mb-1">
          {{ $t('modal.csv.dropzone.label') }}
        </h3>
        <span class="fs-7 fw-semibold text-gray-500">
          {{ $t('modal.csv.dropzone.sublabel') }}
        </span>
      </div>
    </div>
    <div class="dropzone-items w-100">
      <!-- Шаблон для добавленных файлов -->
      <div ref="previewNode" class="dropzone-item d-none">
        <div class="dropzone-file">
          <div class="dropzone-filename" title="some_file_name.csv">
            <span data-dz-name>some_file_name.csv</span>
            <strong> (<span data-dz-size>340kb</span>)</strong>
          </div>
          <div class="dropzone-error" data-dz-errormessage></div>
        </div>
        <div class="dropzone-toolbar">
          <span class="dropzone-delete" data-dz-remove>
            <i class="ki-outline ki-cross cursor-pointer fs-2"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, nextTick } from 'vue';
import { Dropzone } from 'dropzone';
import Papa from 'papaparse';

export default defineComponent({
  name: 'DropzoneUploader',
  emits: ['file-parsed'],
  setup(_, { emit }) {
    const dropzoneRef = ref<HTMLDivElement | null>(null);
    const previewNode = ref<HTMLElement | null>(null);
    let dropzone: Dropzone;

    // Функция для парсинга CSV файла
    const parseCSV = (file: File) => {
      Papa.parse(file, {
        header: true,
        delimiter: ';',
        complete: (result) => {
          const csvData = result.data.map((row: any) => {
            // Приводим все ключи в нижний регистр
            const normalizedRow: Record<string, any> = {};
            for (const key in row) {
              if (row.hasOwnProperty(key)) {
                normalizedRow[key.toLowerCase()] = row[key];
              }
            }
            return normalizedRow;
          });
          emit('file-parsed', { csvData });
        },
        error: (error) => {
          console.error('Ошибка парсинга CSV:', error);
        }
      });
    };
    
    // Метод для сброса Dropzone
    const resetDropzone = () => {
      if (dropzone) {
        dropzone.removeAllFiles(true); // Удалить все файлы из Dropzone
        dropzoneRef.value?.classList.remove('dropzone-queue');
        emit('file-parsed', { csvData: [] }); // Передаем пустые массивы
      }
    };

    onMounted(async () => {
      await nextTick();

      if (!dropzoneRef.value || !previewNode.value) return;

      // Сохраняем шаблон элемента и удаляем его из DOM
      const previewTemplate = previewNode.value.outerHTML;
      previewNode.value.remove();

      dropzone = new Dropzone(dropzoneRef.value, {
        url: "#", // Фиктивный URL, так как загрузка не требуется
        maxFiles: 1, // Ограничение на загрузку только одного файла
        maxFilesize: 1, // Максимальный размер файла в MB
        autoProcessQueue: false, // Отключаем автоматическую загрузку
        previewTemplate,
        previewsContainer: dropzoneRef.value.querySelector('.dropzone-items'),
        acceptedFiles: ".csv",
      });

      // Обработка добавления файла
      dropzone.on('addedfile', (file) => {
        if (file.previewElement) {
          file.previewElement.classList.remove('d-none');
        }

        // Скрываем область загрузки после добавления файла
        dropzoneRef.value?.classList.add('dropzone-queue');
        
        parseCSV(file);
      });

      // Обработка удаления файла
      dropzone.on('removedfile', () => {
        // Снова показываем область загрузки
        dropzoneRef.value?.classList.remove('dropzone-queue');
        
        const dropzoneItems = dropzoneRef.value?.querySelectorAll('.dropzone-item');
        dropzoneItems?.forEach(item => {
          item.classList.add('d-none');
        });
        
        // После удаления файла передаем пустые массивы
        emit('file-parsed', { csvData: [] });
      });
    });
    
    return {
      dropzoneRef,
      previewNode,
      resetDropzone,
    };
  },
});
</script>
