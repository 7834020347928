/**
 * Сервис для шифрования данных с использованием AES-GCM.
 */
export default {
  /**
   * Генерирует токен для шифрования.
   *
   * @returns {string} Токен для шифрования, полученный из переменной среды окружения.
   */
  generateAuthToken(): string {
    return import.meta.env.VITE_APP_API_AUTH_TOKEN;
  },

  /**
   * Генерирует Initialization Vector (IV) для AES-GCM.
   *
   * @returns {Uint8Array} Массив байт (12 байт) для IV.
   */
  generateIV(): Uint8Array {
    return crypto.getRandomValues(new Uint8Array(12));
  },

  /**
   * Преобразует массив байтов в HEX строку.
   *
   * @param {Uint8Array} byteArray - Массив байтов для преобразования.
   * @returns {string} HEX строка, представляющая массив байтов.
   */
  toHex(byteArray: Uint8Array): string {
    return Array.from(byteArray).map(b => b.toString(16).padStart(2, '0')).join('');
  },

  /**
   * Шифрует пароль с использованием AES-GCM.
   *
   * @param {string} password - Пароль для шифрования.
   * @returns {Promise<string>} Зашифрованные данные в формате HEX.
   */
  async encryptPassword(password: string): Promise<string> {
    const authToken: string = this.generateAuthToken();
    const iv: Uint8Array = this.generateIV();
    const enc = new TextEncoder();
    const encodedKey = enc.encode(authToken);

    // Импорт ключа для использования с AES-GCM
    const keyMaterial = await crypto.subtle.importKey(
      'raw',
      encodedKey,
      'AES-GCM',
      false,
      ['encrypt']
    );

    const encodedPassword = enc.encode(password);

    // Шифрование пароля
    const encryptedData = await crypto.subtle.encrypt(
      { name: 'AES-GCM', iv: iv },
      keyMaterial,
      encodedPassword
    );

    // Преобразуем IV и зашифрованный пароль в HEX строку
    const ivHex: string = this.toHex(iv);
    const encryptedPasswordHex: string = this.toHex(new Uint8Array(encryptedData));

    // Возвращаем комбинацию IV и зашифрованного пароля в HEX формате
    return ivHex + encryptedPasswordHex;
  }
};
