<template>
  <div class="kt-terms">
    <!--begin::Button-->
    <span
      class="btn btn-sm btn-light-body btn-text-gray-600 btn-active-text-primary fw-semibold rotate"
      data-kt-menu-trigger="click"
      data-kt-menu-attach="parent"
      data-kt-menu-placement="top-start"
    >
      <img
        class="w-15px h-15px rounded-circle me-2"
        :src="currentLangugeLocale.flag"
        alt=""
      />
      <span class=" me-1">
        {{ currentLangugeLocale.name }}
      </span>
      <span class="d-flex flex-center rotate-180">
        <KTIcon icon-name="down" icon-class="fs-5 text-muted m-0" />
      </span>
    </span>
    <!--end::Button-->

    <!--begin::Menu-->
    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-state-bg-light-primary fw-semibold py-3 fs-base w-150px" data-kt-menu="true">
      <!--begin::Menu item-->
      <div class="menu-item px-3" v-for="(country, key) in countriesList" :key="key">
        <div
          @click="setLang(key)"
          class="menu-link ps-3 pe-5 py-2"
          :class="{ active: currentLanguage === key }"
        >
          <span class="symbol symbol-20px symbol-circle me-4">
            <img :src="country.flag" alt="" />
          </span>
          {{ country.name }}
        </div>
      </div>
      <!--end::Menu item-->
    </div>
    <!--end::Menu-->
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { localization } from "@/core/plugins/i18n";

const { locale } = useI18n();

const countriesList = computed(() => localization);

const setLang = (lang: string) => {
  localStorage.setItem("lang", lang);
  locale.value = lang;
};

const currentLanguage = computed(() => {
  return locale.value;
});

const currentLangugeLocale = computed(() => {
  return localization[locale.value as keyof typeof localization];
});
</script>