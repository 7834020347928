<template>
  <RouterView />
  <Toast />
  <Terms />
</template>

<script lang="ts">
import { defineComponent, nextTick, onBeforeMount, onMounted } from "vue";
import { useRoute, RouterView } from "vue-router";
import { useConfigStore } from "@/stores/config";
import { useThemeStore } from "@/stores/theme";
import { useBodyStore } from "@/stores/body";
import { themeConfigValue } from "@/layouts/default-layout/config/helper";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { usePushPullStore } from "@/stores/pushPull";
import Toast from '@/components/Toast.vue'
import Terms from '@/layouts/default-layout/components/modals/Terms.vue';

// Импорт для регистрации сервис-воркера с автоматической перезагрузкой
import { registerSW } from 'virtual:pwa-register';

export default defineComponent({
  name: "app",
  components: {
    RouterView,
    Toast,
    Terms,
  },
  setup() {
    const route = useRoute();
    const configStore = useConfigStore();
    const themeStore = useThemeStore();
    const bodyStore = useBodyStore();
    const pushPullStore = usePushPullStore();

    onBeforeMount(() => {
      /**
       * Overrides the layout config using saved data from localStorage
       * remove this to use static config (@/layouts/default-layout/config/DefaultLayoutConfig.ts)
       */
      configStore.overrideLayoutConfig();

      // Sets a mode from configuration
      themeStore.setThemeMode(themeConfigValue.value);
    });

    onMounted(() => {
      nextTick(() => {
        // Инициализация компонентов
        initializeComponents();

        // Регистрация сервис-воркера с автоматической перезагрузкой
        registerSW({ immediate: true });

        // Удаление класса загрузки с body
        bodyStore.removeBodyClassName("page-loading");

        // Инициализация подключения через WebSocket без ожидания завершения
        Promise.resolve().then(() => pushPullStore.init(route));
      });
    });
  },
});
</script>

<style lang="scss">
@import "@vueform/multiselect/themes/default.css";
@import "sweetalert2/dist/sweetalert2.css";
@import "apexcharts/dist/apexcharts.css";
@import "animate.css";

/* Main style scss */
@import "assets/keenicons/duotone/style.css";
@import "assets/keenicons/outline/style.css";
@import "assets/sass/element-ui.dark";
@import "assets/sass/plugins";
@import "assets/sass/style";

// Plugins
@import "nouislider/dist/nouislider.css";
@import "dropzone/dist/dropzone.css";
@import '@yaireo/tagify/dist/tagify.css';

/*
// Icon
@import "assets/keenicons/solid/style.css";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "line-awesome/dist/line-awesome/css/line-awesome.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "socicon/css/socicon.css";

// Plugins
@import "quill/dist/quill.snow.css";
@import "prism-themes/themes/prism-shades-of-purple.css";
@import "element-plus/dist/index.css";
*/

#app {
  display: contents;
}
</style>