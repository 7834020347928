const de = {
  loadingText: "Laden...",
  returnHome: "Zurück zur Startseite",
  goHome: "Zur Startseite",
  offline: "Keine Internetverbindung",
  
  theme: {
    light: "Hell",
    dark: "Dunkel",
    system: "System"
  },

  info: "Information",
  // terms: "Bedingungen",
  about: "Über das Projekt",
  contact: "Kontakte",
  support: "Technischer Support",
  userAgreement: "Nutzungsvereinbarung",
  privacyPolicy: "Datenschutzrichtlinie",

  city: "Stadt",
  phone: "Telefon",
  email: "E-Mail",
  avatar: "Foto",
  search: "Suche",

  actions: {
    edit: "Bearbeiten",
    archive: "Archivieren",
    dearchive: "Wiederherstellen",
    delete: "Löschen",
    clear: "Leeren",
  },
  buttons: {
    discard: "Verwerfen",
    cancel: "Abbrechen",
    submit: "Absenden",
    save: "Speichern",
    wait: "Bitte warten...",
    continue: "Fortsetzen",
    back: "Zurück",
    connect: "Verbinden",
    allow: "Erlauben",
    update: "Aktualisieren",
    change: "Ändern",
    agree: "Zustimmen",
    close: "Schließen",
    remove: "Entfernen",
  },
  messager: {
    telegram: "Telegram",
    google: "Google",
    apple: "Apple",
  },
  cookieMessage: {
    introText: "Wir verwenden",
    cookieText: "Cookies",
  },
  messages: {
    text: {
      success: {
        signIn: "Sie haben sich erfolgreich angemeldet!",
        signUp: "Registrierung erfolgreich!",
        passwordReset: "Eine E-Mail wurde an Ihre Adresse gesendet!",
        passwordChange: "Das Passwort wurde erfolgreich geändert!",
        emailChange: "Die E-Mail-Adresse wurde erfolgreich geändert!",
        userUpdate: "Profilinformationen aktualisiert!",
        
        addGroup: "Gruppe erfolgreich hinzugefügt!",
        editGroup: "Gruppe erfolgreich bearbeitet!",
        deleteGroup: "Gruppe erfolgreich gelöscht!",
        
        addSearch: "Suche erfolgreich hinzugefügt!",
        editSearch: "Suche erfolgreich bearbeitet!",
        archive: "Suche erfolgreich archiviert!",
        dearchive: "Suche erfolgreich wiederhergestellt!",
        deleteSearch: "Suche erfolgreich gelöscht!",

        clearItems: "Artikelliste erfolgreich geleert!",
        clearAnalytics: "Analyse erfolgreich geleert!",
        
        deleteAnalyticsItems: "Artikel erfolgreich gelöscht!",

        switchOnNotices: "Benachrichtigungen über {messager} erfolgreich aktiviert.",
        switchOffNotices: "Benachrichtigungen über {messager} erfolgreich deaktiviert.",
        switchOffAccount: "Konto {service} erfolgreich deaktiviert.",
        switchOnAccount: "Konto {service} erfolgreich aktiviert.",
      },
      error: {
        oauth: "Fehler bei der Authentifizierung. Bitte versuchen Sie es erneut!",

        addGroup: "Fehler beim Hinzufügen der Gruppe. Bitte versuchen Sie es erneut!",
        editGroup: "Fehler beim Bearbeiten der Gruppe. Bitte versuchen Sie es erneut!",
        deleteGroup: "Fehler beim Löschen der Gruppe. Bitte versuchen Sie es erneut!",

        addSearch: "Fehler beim Hinzufügen der Suche. Bitte versuchen Sie es erneut!",
        editSearch: "Fehler beim Bearbeiten der Suche. Bitte versuchen Sie es erneut!",
        archive: "Fehler beim Archivieren der Suche. Bitte versuchen Sie es erneut!",
        dearchive: "Fehler beim Wiederherstellen der Suche aus dem Archiv. Bitte versuchen Sie es erneut!",
        deleteSearch: "Fehler beim Löschen der Suche. Bitte versuchen Sie es erneut!",

        clearItems: "Fehler beim Leeren der Artikelliste. Bitte versuchen Sie es erneut!",
        clearAnalytics: "Fehler beim Leeren der Analyse. Bitte versuchen Sie es erneut!",

        deleteItems: "Fehler beim Löschen der Artikel. Bitte versuchen Sie es erneut!",
        
        switchOnNotices: "Fehler beim Aktivieren der Benachrichtigungen über {messager}. Bitte versuchen Sie es erneut!",
        switchOffNotices: "Fehler beim Deaktivieren der Benachrichtigungen über {messager}. Bitte versuchen Sie es erneut!",
        switchOnAccount: "Fehler beim Aktivieren des Kontos {service}. Bitte versuchen Sie es erneut!",
        switchOffAccount: "Fehler beim Deaktivieren des Kontos {service}. Bitte versuchen Sie es erneut!",
        
        geoCoords: "Fehler beim Abrufen der Koordinaten!",
        geoCity: "Fehler beim Abrufen der Stadt!",
        geoCityNotFound: "Stadt nicht gefunden",
      },
      warning: {
        passwordChangeFail: "Verwenden Sie den Link in der E-Mail zum Zurücksetzen des Passworts!",

        addGroup: "Eine Gruppe mit diesem Namen existiert bereits!",
        invalidGroupName: "Der Gruppenname darf keine Zahl sein!",
        maxLengthExceeded: "Der Gruppenname darf nicht länger als {maxLength} Zeichen sein!",
        
        deleteGroup: "Möchten Sie die Gruppe wirklich löschen: {title}?",
        deleteSearch: "Möchten Sie die Suche wirklich löschen: {title}?",
        archive: "Möchten Sie die Suche wirklich archivieren: {title}?",
        dearchive: "Möchten Sie die Suche wirklich wiederherstellen: {title}?",
        clearItems: "Möchten Sie die Artikelliste für die Suche wirklich leeren: {title}?",
        clearAnalytics: "Möchten Sie die Analysedaten für die Suche wirklich leeren: {title}?",
      
        deleteAnalyticsItems: "Ausgewählte Artikel löschen?",

        switchOffNotices: "Möchten Sie die Benachrichtigungen über {messager} wirklich deaktivieren?",
        switchOffAccount: "Möchten Sie das Konto {service} wirklich deaktivieren?",
        geoNotAvailable: "Standortbestimmung ist nicht verfügbar!",
      }
    },
    button: {
      okGotIt: "Ok, verstanden!",
      noCancel: "Nein, abbrechen",
      tryAgain: "Erneut versuchen!",
      archive: "Archivieren!",
      dearchive: "Wiederherstellen!",
      delete: "Löschen!",
      clear: "Leeren!",
      switchOff: "Deaktivieren"
    },
    any: {
      success: "Erfolg",
      warning: "Warnung",
      error: "Fehler",
    }
  },
  menu: {
    home: "Startseite",
    dashboard: "Dashboard",
    layoutBuilder: "Ersteller",
    groups: "Gruppen",
    search: "Suche",
    itemsId: "Artikel",
    profile: "Profil",
    setting: "Einstellungen",
    userAccount: {
      profile: "Profil",
      language: "Sprache",
      logs: "Sitzungen",
      notifications: "Benachrichtigungen",
      settings: "Einstellungen",
      signOut: "Abmelden"
    }
  },
  navbar: {
    alert: {
      notifications: "Benachrichtigungen"
    }
  },
  toolbar: {
    upgradePlan: "Abonnement"
  },
  pages: {
    authentication: {
      title: "Schneller und effizienter persönlicher Assistent",
      subtitle: "Palert ist Ihr persönlicher Assistent für den Online-Shopping-Bereich für diejenigen, die bei teuren Produkten sparen möchten. Mit unserer App sind Sie stets über die besten Angebote Ihrer Lieblingsprodukte informiert. Mit Palert verpassen Sie kein Schnäppchen!",
      signIn: {
        pageTitle: "Anmelden",
        subtitle: "über Dienste",
        noAccount: "Kein Konto?",
        signUp: "Registrieren",
        forgotPassword: "Passwort zurücksetzen",
        button: "Anmelden",
      },
      signUp: {
        pageTitle: "Registrieren",
        subtitle: "über Dienste",
        alreadyAccount: "Bereits ein Konto?",
        signIn: "Anmelden",
        firstName: "Vorname",
        lastName: "Nachname",
        textPassword: "Verwenden Sie 8 oder mehr Zeichen: Buchstaben, Zahlen und Symbole.",
        textAgree: "Ich akzeptiere die Bedingungen des ",
        textTermsUserAgreement: "Nutzungsvertrags für die Informationssysteme und den persönlichen Assistenten Palert",
        textAgreePrefix: " und habe die ",
        textTermsPrivacyPolicy: "Datenschutzrichtlinie zur Kenntnis genommen",
        button: "Registrieren",
      },
      passwordReset: {
        pageTitle: "Passwort zurücksetzen",
        subtitle: "Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen",
        button: "Zurücksetzen",
        wait: "Zurücksetzen...",
      },
      passwordChange: {
        pageTitle: "Neues Passwort festlegen",
        subtitle: "Haben Sie Ihr Passwort bereits zurückgesetzt?",
      },
      passwordConfirmation: {
        pageTitle: "Passwort geändert",
        title: "Passwort geändert",
        subtitle: "Ihr Passwort wurde erfolgreich geändert!",
      },
      welcome: {
        pageTitle: "Willkommen",
        title: "Willkommen bei Palert",
        subtitle: "Wir freuen uns, Sie bei unserem Projekt zu begrüßen. Ihr Konto wurde erfolgreich erstellt. Eine E-Mail mit einem Aktivierungslink und weiteren Anweisungen wurde an die bei der Registrierung angegebene Adresse gesendet.",
      },
      verifyEmail: {
        pageTitle: "E-Mail bestätigen",
        title: "E-Mail bestätigt",
        titleError: "Fehler bei der E-Mail-Bestätigung",
        subtitle: "Ihre E-Mail wurde erfolgreich bestätigt!",
        processGoHome: "Sie werden in {second} Sek. zur Startseite weitergeleitet.",
      },
      acceptAgreement: {
        pageTitle: "Akzeptieren Sie die Nutzungsvereinbarung",
        title: "Akzeptieren Sie die Nutzungsvereinbarung",
        subtitle: "Um Palert zu nutzen, müssen Sie die Vereinbarung akzeptieren.",
      },
      accountDeactivated: {
        pageTitle: "Konto deaktiviert",
        title: "Ihr Konto wurde deaktiviert",
        subtitle: "Vielen Dank, dass Sie so lange bei uns waren!",
      },
      error404: {
        pageTitle: "Fehler 404",
        title: "Ups!",
        subtitle: "Wir können diese Seite nicht finden."
      },
      error500: {
        pageTitle: "Fehler 500",
        title: "Systemfehler",
        subtitle: "Etwas ist schiefgelaufen! Bitte versuchen Sie es später noch einmal."
      },
      email: "E-Mail",
      password: "Passwort",
      confirmPassword: "Passwort bestätigen",
      or: "ODER",
      wait: "Bitte warten...",
      errorOAuthLink: "Fehler beim Abrufen des Authentifizierungslinks:"
    },
    dashboard: {
      breadcrumbs: "Arbeitsplatz",
      pageTitle: "Arbeitsplatz",
      platforms: {
        titleCard: "Marktplätze",
        subTitleCard: "Aktuelle Daten",
        formatter: {
          single: "Link",
          few: "Links",
          many: "Links"
        },
      },
    },
    account: {
      breadcrumbs: {
        items: "Profil",
      },
      pageTitleOverview: "Profil",
      pageTitleSettings: "Profileinstellungen",
      pageTitleNotifications: "Benachrichtigungen",
      pageTitleLogs: "Protokolle",
      nav: {
        overview: "Übersicht",
        settings: "Einstellungen",
        notifications: "Benachrichtigungen",
        logs: "Protokolle",
      },
      layoutOverview: {
        profileDetails: "Profildetails",
        fullName: "Vollständiger Name",
        mobilePhone: "Mobiltelefon",
        mobileTitle: "Telefonnummer muss aktiv sein",
        avatarTitle: "Laden Sie ein passendes Bild hoch, das in Palert angezeigt wird",
        communication: "Kommunikation",
      },
      layoutSettings: {
        linkedAccounts: "Verknüpfte Konten",
        numberPhone: "Telefonnummer",
        signInMethod: "Anmeldemethode",
        changeEmail: "E-Mail ändern",
        newEmail: "Neue E-Mail-Adresse",
        
        place: "Ort",
        coordinates: "Koordinaten",
        latitude: "Breitengrad",
        longitude: "Längengrad",
        filled: "Automatisch ausgefüllt",
        filledTitle: "Das Feld {label} wird automatisch ausgefüllt, wenn eine Stadt hinzugefügt wird.",

        updatePassword: "Passwort aktualisieren",
        currentPassword: "Aktuelles Passwort",
        newPassword: "Neues Passwort",
        confirmNewPassword: "Neues Passwort bestätigen",        
        accountNotConnected: "Konto nicht verbunden",
        
        deactivateAccount: "Konto deaktivieren",
        deactivateWrapperTitle: "Sie deaktivieren Ihr Konto",
        deactivateWrapperText: "Zur zusätzlichen Sicherheit müssen Sie Ihre E-Mail-Adresse bestätigen, um Ihr Konto zu deaktivieren.",
        deactivateConfirm: "Bestätigen Sie die Deaktivierung des Kontos",
      },
      layoutNotifications: {
        notifyChannels: "Benachrichtigungskanäle",
        notifications: "Benachrichtigungen",
        notifyNotLabel: "nicht vorhanden",

        infoNotifiTGTitle: "Benachrichtigungen in Telegram aktivieren",
        infoNotifiTGText: "Bitte gehen Sie zu unserem Telegram-Bot {telegramBot} und klicken Sie auf \"<strong>Start</strong>\". Dadurch können Sie Benachrichtigungen in Telegram erhalten.",

        infoConnectedTGTitle: "Telegram-Konto verbinden",
        infoConnectedTGText: "Bitte verbinden Sie Ihr Telegram-Konto in ",

        infoConnectedPhoneTitle: "Telefonnummer angeben",
        infoConnectedPhoneText: "Bitte geben Sie Ihre Handynummer in den ",

        infoConnectedLink: "Profileinstellungen",
        
        notifyNotDisturb: "Nicht stören",
        notifyNotDisturbText: "Aktivieren Sie die Funktion \"Nicht stören\", um alle Benachrichtigungen auszuschalten und sich während bestimmter Stunden oder Aufgaben zu konzentrieren.",
        notifyNotDisturbButton: "Benachrichtigungspause",

        label: {
          items: "Artikel",
          bills: "Rechnungen",
          newDevice: "Neues Gerät",
          news: "Nachrichten",
        },
        subLabel: {
          items: "Benachrichtigung über das Erreichen des Wunschpreises",
          bills: "Benachrichtigung über neue und unbezahlte Rechnungen",
          newDevice: "Benachrichtigung über Anmeldungen von neuen Geräten",
          news: "Interessante Nachrichten bei Veröffentlichung",
        },
      },
      layoutLogs: {
        loginSessions: "Sitzungen",
        titleTable: {
          device: "Gerät",
          location: "Ort",
          browser: "Browser",
          ip: "IP-Adresse",
          date: "Datum",
        },
        select: {
          day1: "1 Tag",
          day7: "7 Tage",
          day30: "30 Tage",
        }
      },
    },
    groups: {
      breadcrumbs: {
        groups: "Gruppen",
      },
      pageTitle: "Gruppenverwaltung",
      addGroup: "Hinzufügen",
      titleCard: "Gruppen",
      titleTable: {
        groups: "Namen",
        status: "Status",
        action: "Aktionen",
      },
      formatter: {
        total: "Gesamt",
        single: "Gruppe",
        few: "Gruppen",
        many: "Gruppen"
      },
      modal: {
        title: {
          new: "Neue Gruppe hinzufügen",
          edit: "Gruppe bearbeiten",
        },
        label: {
          description: "Beschreibung"
        },
        placeholder: {
          title: "Gruppenname",
          description: "Beschreibung eingeben"
        },
        subLabel: {
          title: "Verwenden Sie einen Namen, der Ihnen gefällt.",
          description: "Bitte geben Sie eine Beschreibung für ein besseres Verständnis an, was in der Gruppe sein wird.",
          pict: "Von ChatGPT generieren lassen",
        },
        other: {
          checkImage: "Hochladen",
        }
      }
    },
    items: {
      breadcrumbs: {
        items: "Suche",
      },
      pageTitle: "Liste der beobachteten Artikel",
      addItem: "Hinzufügen",
      filterItem: "Filter",
      filter: {
        title: "Filteroptionen",
        groups: "Gruppen",
        allGroups: "Alle Gruppen",
        platforms: "Plattformen",
        wishprice: "Wunschpreis",
        executed: "Ausgeführt",
        status: "Status",
        active: "Aktiv",
        archive: "Archiv",
        any: "Beliebig",
        reset: "Zurücksetzen"
      },
      titleCard: "Suchanfragen",
      titleCardLast: "Letzte Suchanfragen",
      titleTable: {
        search: "Suche",
        price: "Min. Preis",
        platform: "Plattformen",
        items: "Artikel",
        updated: "Aktualisiert",
        chart: "Diagramm",
        status: "Status",
        action: "Aktionen",
      },
      formatter: {
        total: "Gesamt",
        single: "Artikel",
        few: "Artikel",
        many: "Artikel"
      },
      active: "Aktiv",
      deactive: "Inaktiv",
      archive: "Archiv",
      isLinks: "Keine Links",
      modal: {
        title: {
          new: "Neue Suche hinzufügen",
          edit: "Suche bearbeiten"
        },
        stepper: {
          main: "Parameter",
          links: "Links",
          filters: "Filter",
        },
        label: {
          requiredWords: "Erforderliche Wörter",
          excludedWords: "Auszuschließende Wörter",
          link: "Links oder Artikelnummern",
          wishprice: "Wunschpreis",
          group: "Gruppe",
        },
        placeholder: {
          title: "Suchname",
          requiredWords: "Wort eingeben",
          excludedWords: "Wort eingeben",
          wishprice: "10.000 €",
          group: {
            add: "Gruppennamen eingeben",
            select: "Gruppe auswählen",
            emptyList: "Gruppenliste ist leer",
            notFound: "Gruppe nicht gefunden"
          },
        },
        subLabel: {
          title: "Geben Sie einen Suchnamen an, mit dem AI (Künstliche Intelligenz) Artikel von Marktplätzen filtern wird.",
          requiredWords: "Geben Sie Wörter an, die unbedingt im Artikelnamen enthalten sein müssen.",
          excludedWords: "Geben Sie Wörter an, die im Artikelnamen nicht vorkommen sollen.",
          link: "Links oder Artikelnummern (SKU) auf {platform}.",
          wishprice: "Geben Sie den Preis an, bei dem Sie Benachrichtigungen erhalten möchten.",
          group: {
            add: "Erstellen Sie eine Gruppe, um die Suche hinzuzufügen.",
            select: "Wählen Sie eine Gruppe, um die Suche hinzuzufügen.",
          },
          pict: "Aus Artikeln laden"
        },
        other: {
          optionGroup: "Gruppe auswählen...",
          checkImage: "Hochladen",
        }
      }
    },
    searchId: {
      breadcrumbs: {
        items: "Artikel",
      },
      pageTitleList: "Artikelliste",
      pageTitleAnalytics: "Artikelanalyse",
      bestOffer: "Bestes Angebot",
      added: "Hinzugefügt",
      modified: "Geändert",
      requiredWords: "Erforderliche Wörter",
      excludedWords: "Auszuschließende Wörter",
      itemRemoved: "Artikel entfernt",
      actionsMenu: {
        search: "Suche",
        products: "Artikel",
        analytics: "Analyse"
      },
      nav: {
        list: "Liste",
        analytics: "Analyse"
      },
      layoutList: {
        total: "gesamt",
        active: "Alle",
        allPlatforms: "Alle Plattformen",
        titleCard: "Artikel",
        titleTable: {
          items: "Namen",
          price: "Preis",
          platform: "Plattformen",
          brand: "Marke",
          rating: "Bewertung",
          ai_score: "AI-Score",
          sku: "SKU",
          date: "Aktualisiert am",
        }
      },
      layoutAnalytics: {
        bestGraph: {
          title: "Preisverlauf",
          subtitle: "Zeitraum: {range}",
          period: {
            year: "Jahr",
            month: "Monat",
            week: "Woche"
          }
        },
        modal: {
          uniqueWords: {
            title: "Empfehlungen",
            subtitle: "Um ähnliche Artikel in Ihrer Suche zu vermeiden, fügen Sie Erforderliche und/oder Auszuschließende Wörter hinzu.",
            requiredWords: "Wir empfehlen, die folgenden Wörter zu Erforderliche Wörter hinzuzufügen",
            excludedWords: "Wir empfehlen, die folgenden Wörter zu Auszuschließende Wörter hinzuzufügen",
          }
        }
      }
    }
  },
  modal: {
    label: {
      title: "Titel",
      thumbnail: "Bild",
      status: "Status",
      active: "Aktiv",
      ai_score: "AI-Score",
    },
    subLabel: {
      status: "Sofort aktivieren",
      ai_filter: "Geben Sie ein Mindestniveau für die Bewertung an, unterhalb dessen Artikel, die nicht mit dem Titel übereinstimmen, aus der Suche ausgeschlossen werden. Die KI (Künstliche Intelligenz) hilft, solche Artikel basierend auf der gewählten Bewertung zu filtern.", 
    },
    upgradePlan: {
      title: "Upgrade des Abonnements",
      subtitle: "Wählen Sie einen Plan, der Ihren Anforderungen entspricht, und genießen Sie die Echtzeit-Preisverfolgung.",
      currentPlan: "Aktuell",
      question: "Abonnement {plan}",
      nav: {
        monthly: "Monatlich",
        annual: "Jährlich"
      },
      month: "Monat",
      year: "Jahr",
      btn: {
        findPrice: "Preis finden",
        cancel: "Abbrechen",
        upgrade: "Abonnement aktualisieren"
      }
    }
  },
  validation: {
    required: "Dieses Feld ist erforderlich",
    requiredField: "Das Feld {field} ist erforderlich",
    requiredConsent: "Sie müssen den Bedingungen zustimmen",
    url: "Ungültiger Link",
    urlOrSKU: "Ungültiger Link oder Artikelnummer",
    
    email: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
    min: "Minimale Länge {min}",
    max: "Maximale Länge {max}",
    
    passwordsMustMatch: "Passwörter müssen übereinstimmen",
    passwordMustBeDifferent: "Das neue Passwort darf nicht mit dem aktuellen übereinstimmen",
    emailMustBeDifferent: "Die neue E-Mail-Adresse darf nicht mit der aktuellen übereinstimmen",
  },
  components: {
    ImageUploader: {
      subLabel_1: "Das Bild wird auf ein Quadrat zugeschnitten.",
      subLabel_2: "Nur Bilddateien der Formate *.png, *.jpg, *.jpeg und *.webp sind zulässig.",
      change: "Bild ändern",
      remove: "Bild entfernen",
    }
  },
  error: {
    noResponseServer: "Keine Antwort vom Server. Bitte überprüfen Sie Ihre Internetverbindung!",
    generalError: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut!",
    unknown: "Unbekannter Fehler. Bitte versuchen Sie es später erneut!",
    server: "Serverfehler. Bitte versuchen Sie es später erneut!",    

    AUTH_FAILED: "Fehler bei der Authentifizierung. Bitte versuchen Sie es später erneut!",
    REGISTER_FAILED: "Fehler bei der Registrierung. Bitte versuchen Sie es später erneut!",
    PASSWORD_FAILED: "Fehler beim Ändern des Passworts. Bitte versuchen Sie es später erneut!",
    EMAIL_FAILED: "Fehler beim Ändern der E-Mail-Adresse. Bitte versuchen Sie es später erneut!",
    
    USER_BLOCKED: "Benutzerkonto gesperrt. Bitte wenden Sie sich an den Support.",
    USER_NOT_FOUND: "Benutzerkonto nicht gefunden. Bitte verwenden Sie den Link aus der E-Mail!",
    EMAIL_ALREADY_VERIFIED: "Benutzer-E-Mail bereits bestätigt.",
    VERIFY_CODE_MISSING: "Bestätigungscode für die E-Mail fehlt. Bitte verwenden Sie den Link aus der E-Mail!",
    VERIFY_CODE_INVALID: "Ungültiger Bestätigungscode. Bitte verwenden Sie den Link aus der E-Mail!",
    VERIFY_EMAIL_FAILED: "Fehler bei der E-Mail-Bestätigung. Bitte wenden Sie sich an den Support.",

    PASSWORD_MUST_DIFFERENT: "Das neue Passwort darf nicht mit dem aktuellen übereinstimmen.",
    EMAIL_MUST_DIFFERENT: "Die neue E-Mail-Adresse darf nicht mit der aktuellen übereinstimmen.",
    EMAIL_ALREADY_EXISTS: "Die neue E-Mail-Adresse wird bereits verwendet.",

    AGREEMENT_NOT_ACCEPTED: "Sie müssen den Bedingungen der Nutzungsvereinbarung zustimmen.",
    AGREEMENT_ACCEPT_FAILED: "Fehler bei der Annahme der Vereinbarung. Bitte wenden Sie sich an den Support.",
    
    AUTH_INVALID_CREDENTIALS: "Ungültige E-Mail oder Passwort.",
    AUTH_INVALID_EMAIL_FORMAT: "Ungültiges E-Mail-Format.",
    AUTH_INVALID_PASSWORD: "Ungültiges aktuelles Passwort.",
    AUTH_INVALID_PASSWORD_LENGTH: "Das Passwort muss mindestens 8 Zeichen lang sein.",
    AUTH_REQUIRED_FIELDS_MISSING: "Die Felder E-Mail und Passwort sind erforderlich.",
    AUTH_REQUIRED_FIELDS_EMAIL: "Das Feld E-Mail ist erforderlich.",
    AUTH_REQUIRED_FIELDS_PASSWORD: "Das Feld Passwort ist erforderlich.",
    AUTH_USER_ALREADY_EXISTS: "Ein Benutzer mit dieser E-Mail ist bereits registriert.",
    AUTH_USER_NOT_FOUND: "Ein Benutzer mit dieser E-Mail ist nicht registriert.",
    AUTH_SEND_PASSWORD_FAILED: "Fehler beim Senden der E-Mail zum Zurücksetzen des Passworts. Bitte versuchen Sie es später erneut!",
    USER_UPDATE_FAILED: "Fehler beim Aktualisieren der Profildaten. Bitte versuchen Sie es erneut!",
    USER_UPDATE_NOTIFY_FAILED: "Fehler beim Ändern des Benachrichtigungskanals des Benutzers. Bitte versuchen Sie es erneut!",

    GROUP_LIMIT_EXCEEDED: "Gruppenlimit überschritten.",
    SEARCH_LIMIT_EXCEEDED: "Suchlimit überschritten.",

    DEMO_ACCESS_DENIED_UPDATE_USER: "In einem Demokonto können die Profildaten nicht geändert werden.",
    DEMO_ACCESS_DENIED_ADD_GROUP: "In einem Demokonto können keine neuen Gruppen hinzugefügt werden.",
    DEMO_ACCESS_DENIED_EDIT_GROUP: "In einem Demokonto können Gruppen nicht bearbeitet werden.",
    DEMO_ACCESS_DENIED_DELETE_GROUP: "In einem Demokonto können erstellte Gruppen nicht gelöscht werden.",
    DEMO_ACCESS_DENIED_ADD_SEARCH: "In einem Demokonto können keine neuen Suchanfragen hinzugefügt werden.",
    DEMO_ACCESS_DENIED_EDIT_SEARCH: "In einem Demokonto können Suchparameter nicht geändert werden.",
    DEMO_ACCESS_DENIED_ARCHIVE_SEARCH: "In einem Demokonto können Suchanfragen nicht archiviert werden.",
    DEMO_ACCESS_DENIED_DELETE_SEARCH: "In einem Demokonto können bestehende Suchanfragen nicht gelöscht werden.",
    DEMO_ACCESS_DENIED_CLEAR_ITEMS: "In einem Demokonto kann die Artikelliste nicht geleert werden.",
    DEMO_ACCESS_DENIED_CLEAR_ANALYTICS: "In einem Demokonto können Analyzedaten nicht gelöscht werden.",
  },
  dataTable: {
    emptyTable: "Keine Daten gefunden",
    zeroRecords: "Keine übereinstimmenden Einträge",
    paginate: {
      first: "Erste",
      previous: "Vorherige",
      next: "Nächste",
      last: "Letzte"
    },
    lengthMenu: "_MENU_ Einträge anzeigen",
    info: "Zeige _START_ bis _END_ von _TOTAL_ Einträgen",
    infoEmpty: "Zeige 0 bis 0 von 0 Einträgen",
    infoFiltered: "(gefiltert aus _MAX_ Einträgen)",
    search: "Suche:",
    processing: "Verarbeitung...",
    loadingRecords: "Lade Einträge...",
    aria: {
      sortAscending: ": aktivieren, um die Spalte aufsteigend zu sortieren",
      sortDescending: ": aktivieren, um die Spalte absteigend zu sortieren"
    },
    select: {
      rows: {
        _: "%d Einträge ausgewählt",
        0: "Zum Auswählen auf einen Eintrag klicken",
        1: "1 Eintrag ausgewählt"
      }
    },
    buttons: {
      print: "Drucken",
      colvis: "Spaltensichtbarkeit",
      copy: "Kopieren",
      copyTitle: "In Zwischenablage kopiert",
      copySuccess: {
        _: "%d Zeilen kopiert",
        1: "1 Zeile kopiert"
      }
    },
    decimal: ",",
    thousands: ".",
    datetime: {
      previous: "Zurück",
      next: "Weiter",
      hours: "Stunden",
      minutes: "Minuten",
      seconds: "Sekunden",
      amPm: ["AM", "PM"],
      unknown: "Unbekannt"
    }
  }
};

export default de;
