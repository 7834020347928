<template>
  <div class="card py-3 px-5 min-w-150px">
    <div class="d-flex align-items-center gap-10">
      <BestOffer :item="bestOffer" />
      <SearchChart :chart-data="bestOffer.chart" />
    </div>
      
    <template v-if="label">
      <div class="mt-auto fw-semibold text-gray-500">{{ $t('pages.searchId.bestOffer') }}</div>
    </template>
  </div>  
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import BestOffer from "@/components/items/BestOffer.vue";
import SearchChart from "@/components/chart/SearchChart.vue";

export default defineComponent({
  name: 'best-offer-detail',
  components: {
    BestOffer,
    SearchChart,
  },
  props: {
    bestOffer: {
      type: Object,
      required: true,
    },
    label: {
      type: Boolean,
      required: false,
      default: false
    },
  },
});
</script>
