<template>
  <div class="card min-w-125px py-3 px-4">
    <DateFormatter 
      :date="date" 
      :format="'d/m/Y'"
      class="d-flex text-nowrap text-gray-800 fw-bold fs-6"
    />
    <DateFormatter 
      :date="date" 
      :format="'в H ч. i мин.'" 
      class="d-flex text-nowrap text-muted fw-semibold fs-7"
    />
    <div class="mt-3 fw-semibold text-gray-500">{{ text }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DateFormatter from '@/components/formatters/DateFormatter.vue';

export default defineComponent({
  name: 'date-card',
  components: {
    DateFormatter,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
});
</script>
