<template>
  <div v-if="isMobile" class="tabbar">
    <div class="tabbar-list">
      <template v-for="(menuItem, i) in MainMenuConfig[0].tabbar" :key="i">
        <router-link
          v-if="menuItem.heading && menuItem.route"
          :class="['tabbar-item', { 'active': hasActiveChildren(menuItem.route) }]"
          :to="menuItem.route"
        >
          <div class="nav-icon">
            <i
              v-if="headerMenuIcons === 'bootstrap'"
              :class="menuItem.bootstrapIcon"
              class="bi fs-1"
            ></i>
            <KTIcon
              v-if="headerMenuIcons === 'keenthemes'"
              :icon-name="menuItem.keenthemesIcon"
              :icon-type="hasActiveChildren(menuItem.route) ? 'duotone' : 'outline'"
              :icon-class="`fs-2x ${hasActiveChildren(menuItem.route) ? 'text-gray-900' : ''}`"
            />
          </div>
          <span class="nav-text fs-8">
            {{ translate(menuItem.heading) }}
          </span>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { isMobile } from "@/core/helpers/mobile";
import { headerMenuIcons } from "@/layouts/default-layout/config/helper";
import MainMenuConfig from "@/layouts/default-layout/config/MainMenuConfig";

export default defineComponent({
  name: "MenuTabbar",
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();

    const hasActiveChildren = (match: string) => {
      return route.path.startsWith(match);
    };
    
    const translate = (text: string) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      isMobile,
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
      translate,
    };
  },
});
</script>

<style scoped>
.tabbar {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}
.tabbar-list {
  display: flex;
  justify-content: space-around;
  align-self: flex-end;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  background-color: var(--bs-drawer-bg-color);
}
.tabbar-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  width: 60px;
  height: 60px;
  color: var(--bs-gray-600);
}
.tabbar-item .nav-text {
  margin-top: 1px;
}
.tabbar-item.active {
  color: var(--bs-gray-900);
}
</style>