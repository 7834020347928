<template>
  <div class="d-inline-flex">
    <div v-if="mode === 'full'" class="d-flex flex-nowrap">
      <div v-for="i in 5" :key="i">
        <i :ref="el => (starRefs[i - 1] = el)" class="ki-duotone ki-star fs-5" :style="getStarStyle(i, 'full')"></i>
      </div>
    </div>
    <div v-else class="d-flex align-items-center">
      <i ref="starRef" class="ki-duotone ki-star fs-5" :style="shortStarStyle"></i>
      <span class="ms-2">{{ rating }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, nextTick, watchEffect } from 'vue';

const props = defineProps({
  rating: {
    type: Number,
    required: true,
  },
  mode: {
    type: String,
    default: 'full', // Может быть 'full' или 'short'
  },
});

const starRefs = ref<HTMLElement[]>([]);
const starRef = ref<HTMLElement | null>(null);
const fullStarWidth = ref(0);
const shortStarStyle = ref({});

onMounted(async () => {
  await nextTick();
  watchEffect(() => {
    let element;
    if (props.mode === 'full' && starRefs.value.length > 0) {
      element = starRefs.value[0];
    } else if (props.mode === 'short' && starRef.value) {
      element = starRef.value;
    }

    if (element) {
      const computedStyle = getComputedStyle(element);
      const fontSize = parseFloat(computedStyle.fontSize);
      if (!isNaN(fontSize)) {
        fullStarWidth.value = fontSize;
        if (props.mode === 'short') {
          updateShortStarStyle();
        }
      }
    }
  });
});

const getStarStyle = (index: number, mode: 'full' | 'short') => {
  let partialFill;
  if (mode === 'full') {
    partialFill = Math.min(Math.max(props.rating - index + 1, 0), 1);
  } else {
    partialFill = Math.min(props.rating / 5, 1);
  }
  const fillWidth = fullStarWidth.value * partialFill;

  return generateStarStyle(fillWidth);
};

const updateShortStarStyle = () => {
  shortStarStyle.value = getStarStyle(1, 'short');
};

const generateStarStyle = (fillWidth: number) => {
  return {
    width: `${fullStarWidth.value}px`,
    background: `linear-gradient(to right, #f3b041 ${fillWidth}px, #636674 ${fillWidth}px)`,
    WebkitBackgroundClip: 'text',
    color: 'transparent',
  };
};
</script>