<template>
  <textarea v-if="mode === 'textarea'" ref="tagifyInput" />
  <input v-else ref="tagifyInput" />
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted, onBeforeUnmount } from 'vue';
import Tagify from '@yaireo/tagify';

export default defineComponent({
  name: 'tagify-component',
  props: {
    mode: {
      type: String,
      default: 'input'
    },
    settings: {
      type: Object,
      default: () => ({})
    },
    modelValue: {
      type: [String, Array],
      default: () => []
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit, expose }) {
    const tagifyInstance = ref<Tagify | null>(null);
    const tagifyInput = ref(null);

    // Обработчик обновления modelValue
    const updateModelValue = (e: CustomEvent) => {
      emit('update:modelValue', e.detail.value);
    };

    onMounted(() => {
      // Инициализация Tagify с настройками
      tagifyInstance.value = new Tagify(tagifyInput.value, props.settings);

      // Установка обработчиков для событий input, change и remove
      tagifyInstance.value.on('change', updateModelValue);
      tagifyInstance.value.on('remove', updateModelValue);

      // Загрузка начального значения
      tagifyInstance.value.loadOriginalValues(props.modelValue);
    });

    // Следим за изменениями modelValue и обновляем Tagify
    watch(() => props.modelValue, (newVal) => {
      tagifyInstance.value?.loadOriginalValues(newVal);
    });

    onBeforeUnmount(() => {
      // Уничтожаем экземпляр Tagify при размонтировании компонента
      tagifyInstance.value?.destroy();
    });

    // Метод для добавления тегов
    const addTags = (tags: string | string[]) => {
      tagifyInstance.value?.addTags(tags);
    };
    
    // Сброс значений Tagify
    const resetTags = (newVal) => {
      tagifyInstance.value?.removeAllTags();
      tagifyInstance.value?.loadOriginalValues(newVal);
    };
    
    // Делаем метод доступным для родительского компонента
    expose({ addTags, resetTags });

    return {
      tagifyInput
    };
  }
});
</script>
