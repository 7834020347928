<template>
  <div :class="{ 'overlay overlay-block' : isLoading }">
    <div class="card card-flush">
      <!-- Заголовок карточки с периодами -->
      <div class="card-header py-5 gap-0 gap-md-5">
        <div class="card-title">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bold fs-3 mb-1">
              {{ $t('pages.searchId.layoutAnalytics.bestGraph.title') }}
            </span>
            <span class="text-muted mt-1 fw-semibold fs-7">
              {{ $t('pages.searchId.layoutAnalytics.bestGraph.subtitle') }}
              <PriceFormatter
                :minPrice="minPrice ?? 0"
                :maxPrice="maxPrice ?? 0"
                :showCurrency="true"
                rangeCurrencyPosition="end"
              />
            </span>
          </h3>
        </div>
        
        <!-- Кнопки выбора периода аналитики -->
        <div class="card-toolbar" data-kt-buttons="true">
          <a
            v-for="(label, key, index) in periods"
            :key="key"
            :class="[
              'btn btn-sm btn-light btn-color-gray-600 px-4',
              {
                'ms-2': index !== 0,
                'btn-active-primary active': period === key,
                'btn-active-light-primary': period !== key
              }
            ]"
            @click="updatePeriod(key)"
          >
            {{ label }}
          </a>
        </div>
      </div>
      
      <!-- Секция графика аналитики -->
      <div class="card-body d-flex align-items-end p-0">
        <BestPrice :height="300" :data="dataChart" />
      </div>
    </div>
    
    <!-- Оверлей, отображающий индикатор загрузки -->
    <div v-if="isLoading" class="overlay-layer card-rounded">
      <div class="spinner-border text-primary" role="status"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from 'vue-router';
import { useConfigStore } from "@/stores/config";
import { usePriceChartStore } from "@/stores/priceChart";

import BestPrice from "@/components/chart/BestPrice.vue";
import PriceFormatter from '@/components/formatters/PriceFormatter.vue';

export default defineComponent({
  name: "search-id-analytics-chart",
  components: {
    BestPrice,
    PriceFormatter,
  },
  props: {
    chartUpdate: {
      type: Number,
      required: true,
    },
  },
  emits: ['update-loading-status'],
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const bodyStore = useConfigStore();
    const dataChart = usePriceChartStore();
    
    const period = ref(bodyStore.getLayoutConfig('analytic.period'));
    const isLoading = ref(true);
    
    // Периоды аналитики и их переводы
    const periods = ref({
      year: t('pages.searchId.layoutAnalytics.bestGraph.period.year'),
      month: t('pages.searchId.layoutAnalytics.bestGraph.period.month'),
      week: t('pages.searchId.layoutAnalytics.bestGraph.period.week'),
    });
    
    // Обновляем периоды при изменении локали
    watch(locale, () => {
      periods.value = {
        year: t('pages.searchId.layoutAnalytics.bestGraph.period.year'),
        month: t('pages.searchId.layoutAnalytics.bestGraph.period.month'),
        week: t('pages.searchId.layoutAnalytics.bestGraph.period.week'),
      };
    });
    
    // Получает и обновляет данные графика
    const fetchChartData = async () => {
      isLoading.value = true;
      await dataChart.fetchData({ id: Number(route.params.id), period: period.value });
      isLoading.value = false;
/* 
      router.push({ hash: '#chart' }).then(() => {
        const element = document.getElementById('chart');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      });
*/
    };

    // Обновляет период и перезапрашивает данные графика, если период изменился
    const updatePeriod = async (newPeriod: string) => {
      // Проверка, чтобы избежать лишнего запроса
      if (period.value === newPeriod) return; 
      
      // Сохранение выбранного периода в конфигурации макета
      bodyStore.setLayoutConfigProperty("analytic.period", newPeriod);

      period.value = newPeriod;
      await fetchChartData();
    };
    
    // Минимальная цена среди данных графика
    const minPrice = computed(() => {
      const prices = dataChart.dataObj?.price || [];
      const filteredPrices = prices.filter(price => price !== null && price !== undefined);
      return filteredPrices.length === 0 ? null : Math.min(...filteredPrices);
    });
    
    // Максимальная цена среди данных графика
    const maxPrice = computed(() => {
      const prices = dataChart.dataObj?.price || [];
      const filteredPrices = prices.filter(price => price !== null && price !== undefined);
      return filteredPrices.length === 0 ? null : Math.max(...filteredPrices);
    });
    
    onMounted(async() => {
      emit('update-loading-status', isLoading.value);
      await fetchChartData();
      emit('update-loading-status', isLoading.value);
    });

    // Следим за изменениями в ссылке для обновления данных
    watch(
      () => [route.query.clear, route.fullPath],
      async ([newCleared, newPath], [oldCleared, oldPath]) => {
        if (newCleared !== oldCleared && newCleared) {
          await fetchChartData(); // Обновляем данные, если был запрос на очистку
        } else if (newPath !== oldPath) {
          await dataChart.resetLoading(); // Сбрасываем состояние загрузки
        }
      }
    );
    
    // Следим за изменениями chartUpdate для обновления данных
    watch(() => props.chartUpdate, async (newVal) => {
      if (newVal) {
        await fetchChartData();
      }
    });
    
    return {
      isLoading,
      dataChart,
      period,
      periods,
      updatePeriod,
      minPrice,
      maxPrice,
    };
  }
});
</script>