<template>
  <div class="card mb-5 mb-xl-10">
    <div
      class="card-header collapsible cursor-pointer rotate min-h-60px"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_profile_details"
      aria-expanded="true"
      aria-controls="kt_account_profile_details"
    >
      <h3 class="card-title fw-bold m-0">
        {{ $t('pages.account.layoutOverview.profileDetails') }}
      </h3>
      <div class="card-toolbar rotate-180">
        <i class="ki-duotone ki-down fs-1"></i>
      </div>
    </div>

    <div id="kt_account_profile_details" class="collapse show">
      <VForm
        id="kt_account_profile_details_form"
        class="form"
        novalidate
        @submit="onSubmitUpdate"
        :validation-schema="profileValidator"
      >
        <div class="card-body p-9">
          <div class="row mb-6">
            <label class="col-lg-3 col-form-label required fw-semibold fs-6">
              {{ $t('pages.account.layoutOverview.fullName') }}
            </label>

            <div class="col-lg-9">
              <div class="row">
                <div class="col-sm-6 fv-row">
                  <Field
                    type="text"
                    name="name"
                    class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    :placeholder="$t('pages.authentication.signUp.firstName')"
                    v-model="userData.name"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 fv-row">
                  <Field
                    type="text"
                    name="last_name"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="$t('pages.authentication.signUp.lastName')"
                    v-model="userData.last_name"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="last_name" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="row mb-6">
            <label class="col-lg-3 col-form-label required fw-semibold fs-6">
              {{ $t('city') }}
            </label>
            
            <div class="col-lg-9 fv-row">
              <div class="d-flex align-items-center gap-5">
                <div class="is-loading w-100">
                  <Field
                    type="text"
                    name="city"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="$t('city')"
                    v-model="userData.city.name"
                    @blur="getCoordsByCity"
                    @keydown.enter.prevent="getCoordsByCity"
                  />
                  <div v-if="isProcessCity" class="spinner-border spinner-border-sm align-middle me-2"></div>
                </div>

                <button
                  type="button"
                  class="btn btn-icon btn-light-primary w-50px"
                  @click="getCityByCoords"
                >
                  <KTIcon icon-name="geolocation" icon-class="fs-2" />
                </button>
              </div>
              
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="city" />
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-3 col-form-label fw-semibold fs-6">
              {{ $t('pages.account.layoutSettings.place') }}
              <i
                class="ki-duotone ki-information-5 ms-1 fs-5"
                v-tooltip data-bs-custom-class="tooltip-inverse"
                :title="$t('pages.account.layoutSettings.filledTitle', { label: $t('pages.account.layoutSettings.place') })"
              >
               <span class="path1"></span>
               <span class="path2"></span>
               <span class="path3"></span>
              </i>
            </label>
            
            <div class="col-lg-9 fv-row">
              <div class="is-loading">
                <Field
                  type="text"
                  name="city_place"
                  class="form-control form-control-lg form-control-solid"
                  :placeholder="$t('pages.account.layoutSettings.filled')"
                  v-model="userData.city.place"
                  disabled="disabled"
                />
                <div v-if="isProcessCity" class="spinner-border spinner-border-sm align-middle me-2"></div>
              </div>

              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="city_place" />
                </div>
              </div>
            </div>
          </div>
          
          <div class="row mb-6">
            <label class="col-lg-3 col-form-label fw-semibold fs-6">
              {{ $t('pages.account.layoutSettings.coordinates') }}
              <i
                class="ki-duotone ki-information-5 ms-1 fs-5"
                v-tooltip data-bs-custom-class="tooltip-inverse"
                :title="$t('pages.account.layoutSettings.filledTitle', { label: $t('pages.account.layoutSettings.coordinates') })"
              >
               <span class="path1"></span>
               <span class="path2"></span>
               <span class="path3"></span>
              </i>
            </label>
          
            <div class="col-lg-9">
              <div class="row">
                <div class="col-6 fv-row">
                  <div class="is-loading">
                    <Field
                      type="text"
                      name="city_lat"
                      class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      :placeholder="$t('pages.account.layoutSettings.latitude')"
                      v-model="userData.city.lat"
                      disabled="disabled"
                    />
                    <div v-if="isProcessCity" class="spinner-border spinner-border-sm align-middle me-2"></div>
                  </div>

                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="city_lat" />
                    </div>
                  </div>
                </div>
          
                <div class="col-6 fv-row">
                  <div class="is-loading">
                    <Field
                      type="text"
                      name="city_lon"
                      class="form-control form-control-lg form-control-solid"
                      :placeholder="$t('pages.account.layoutSettings.longitude')"
                      v-model="userData.city.lon"
                      disabled="disabled"
                    />
                    <div v-if="isProcessCity" class="spinner-border spinner-border-sm align-middle me-2"></div>
                  </div>
                  
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="city_lon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-3 col-form-label fw-semibold fs-6">
              {{ $t('pages.account.layoutOverview.mobilePhone') }}
              <i
                class="ki-duotone ki-information-5 ms-1 fs-5"
                v-tooltip data-bs-custom-class="tooltip-inverse"
                :title="$t('pages.account.layoutOverview.mobileTitle')"
              >
               <span class="path1"></span>
               <span class="path2"></span>
               <span class="path3"></span>
              </i>
            </label>

            <div class="col-lg-9 fv-row">
              <Cleave
                type="tel"
                name="mobile_phone"
                class="form-control form-control-lg form-control-solid"
                :placeholder="$t('pages.account.layoutSettings.numberPhone')"
                :options="cleaveOptions"
                v-model="userData.mobile_phone"
                :raw="false"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="mobile_phone" />
                </div>
              </div>
            </div>
          </div>
          
          <div class="row mb-6">
            <label class="col-lg-3 col-form-label fw-semibold fs-6">
              {{ $t('avatar') }}
              <i
                class="fas fa-exclamation-circle ms-1 fs-7"
                v-tooltip data-bs-custom-class="tooltip-inverse"
                :title="$t('pages.account.layoutOverview.avatarTitle')"
              ></i>
            </label>
            
            <div class="col-lg-9">
              <ImageUploader
                ref="imageUploaderRef"
                v-model="userData.photo_url"
                @file-change="handleFileChange"
              />
            </div>
          </div>
          
<!--           <div class="row mb-6">
            <label class="col-lg-3 col-form-label fw-semibold fs-6">
              {{ $t('pages.account.layoutOverview.communication') }}
            </label>

            <div class="col-lg-9 fv-row">
              <div class="d-flex align-items-center mt-3">
                <label class="form-check form-check-inline form-check-solid me-5">
                  <input
                    class="form-check-input"
                    name="communication[]"
                    type="checkbox"
                    v-model="userData.communication.email"
                  />
                  <span class="fw-semibold ps-2 fs-6"> {{ $t('pages.authentication.email') }} </span>
                </label>

                <label class="form-check form-check-inline form-check-solid">
                  <input
                    class="form-check-input"
                    name="communication[]"
                    type="checkbox"
                    v-model="userData.communication.phone"
                  />
                  <span class="fw-semibold ps-2 fs-6"> {{ $t('phone') }} </span>
                </label>
                
                <label class="form-check form-check-inline form-check-solid">
                  <input
                    class="form-check-input"
                    name="communication[]"
                    type="checkbox"
                    v-model="userData.communication.telegram"
                  />
                  <span class="fw-semibold ps-2 fs-6"> {{ $t('messager.telegram') }} </span>
                </label>
              </div>
            </div>
          </div> -->
        </div>

        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button
            type="reset"
            class="btn btn-light btn-active-light-primary me-2"
            @click="resetForm"
          >
            {{ $t('buttons.discard') }}
          </button>

          <button
            type="submit"
            id="kt_account_profile_details_submit"
            ref="submitButton"
            class="btn btn-primary"
          >
            <span class="indicator-label"> {{ $t('buttons.save') }} </span>
            <span class="indicator-progress">
              {{ $t('buttons.wait') }}
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
        </div>
      </VForm>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import { useAuthStore, type User } from "@/stores/auth";
import { useI18n } from "vue-i18n";
import { addToast } from '@/utils/toastManager';
import { ErrorMessage, Field, Form as VForm } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import _ from 'lodash';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.ru';
import { useGeolocation } from '@vueuse/core';
import { fetchCityByCoords, fetchCoordsByCity, isValidCoords } from "@/core/services/GeolocationService";

import ImageUploader from "@/components/files/ImageUploader.vue";

export default defineComponent({
  name: "account-settings-profile-details",
  components: {
    ImageUploader,
    ErrorMessage,
    Field,
    VForm,
    Cleave,
  },
  setup() {
    const { t } = useI18n();
    const userStore = useAuthStore();
    const userData = ref(_.cloneDeep(userStore.user));
    const isProcessCity = ref(false);
    const submitButton = ref<null | HTMLButtonElement>(null);
    const imageUploaderRef = ref<null | { resetImage: () => void }>(null);
    const selectedFile = ref<File | null>(null);

    const createProfileValidatorSchema = () => {
      return Yup.object().shape({
        name: Yup.string().required(t('validation.requiredField', { field: t('pages.authentication.signUp.firstName') })),
        last_name: Yup.string(),
        city: Yup.string().required(t('validation.requiredField', { field: t('city') })),
      });
    };
    
    const profileValidator = computed(() => createProfileValidatorSchema());
    
    const cleaveOptions = {
      phone: true,
      phoneRegionCode: 'RU'
    };
    
    const handleFileChange = (file: File) => {
      selectedFile.value = file;
    };

    const resetForm = () => {
      userData.value.mobile_phone = '';
      selectedFile.value = 'del';
      imageUploaderRef.value?.resetImage();
    };
    
    // Функция для получения координат по названию города, введенному пользователем
    const getCoordsByCity = async () => {
      if (!userData.value.city?.name) return;
    
      isProcessCity.value = true;
      try {
        // Запускаем запрос и тайм-аут одновременно
        const data = await fetchCoordsByCity(userData.value.city.name);
    
        // Обновляем userData с новыми координатами и городом
        userData.value.city = { ...data };
      } catch (err) {
        addToast(
          t('messages.any.error'),
          t(`messages.text.error.geoCoords`),
          'danger'
        );
        // console.error("Ошибка при получении координат:", err);
      } finally {
        isProcessCity.value = false;
      }
    };
    
    // Функция для создания тайм-аута
    const timeout = (ms: number) => new Promise((_, reject) => 
      setTimeout(() => reject(
        new Error(t(`messages.text.warning.geoNotAvailable`)),
      ), ms)
    );
    
    // Функция для получения города по координатам
    const getCityByCoords = async () => {    
      const { coords, error: errorGeo, pause, resume } = useGeolocation();

      // Обработка ошибок геолокации
      if (errorGeo.value) {
        addToast(
          t('messages.any.warning'),
          t(`messages.text.warning.geoNotAvailable`),
          'warning'
        );
        return;
      }
    
      resume();  // Возобновляем обновление геолокации
      isProcessCity.value = true;
    
      // Используем переменную для хранения функции наблюдателя
      const stopWatching = watch(
        () => coords.value, // наблюдаем за изменениями координат
        async (newCoords) => {
          // Проверяем, если координаты валидны
          if (isValidCoords(newCoords?.latitude) && isValidCoords(newCoords?.longitude)) {
            try {
              userData.value.city.name = await fetchCityByCoords(newCoords.latitude, newCoords.longitude);
              await getCoordsByCity();
            } catch (err) {
              addToast(
                t('messages.any.error'),
                t(`messages.text.error.geoCity`),
                'danger'
              );
              // console.error("Ошибка при получении города:", err);
            } finally {
              isProcessCity.value = false;  // Скрываем индикатор процесса
            }
          }
        },
        { immediate: true } // Запускаем сразу, чтобы проверить текущие координаты
      );
    
      // Используем Promise.race для ожидания либо выполнения, либо тайм-аута
      try {
        await Promise.race([
          timeout(10000),  // Тайм-аут на 10 секунд
          new Promise((resolve) => {
            // Слушаем завершение процесса
            watch(() => isProcessCity.value, (newVal) => {
              if (!newVal) resolve(); // Завершение обработки
            });
          })
        ]);
      } catch (err) {
        // Выводим сообщение, если сработал тайм-аут
        addToast(
          t('messages.any.warning'),
          t(`messages.text.warning.geoNotAvailable`),
          'warning'
        );
        console.error(err.message); // Выведет "Время ожидания истекло"
      } finally {
        // Останавливаем наблюдение и приостанавливаем обновление геолокации
        stopWatching();
        pause(); // Приостанавливаем обновление геолокации
        isProcessCity.value = false;  // Скрываем индикатор процесса
      }
    };

    const onSubmitUpdate = async () => {
      if (!submitButton.value) {
        return;
      }

      submitButton.value!.disabled = true;
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      
      const formData = new FormData();
      
      const fields = {
        "NAME": userData.value.name,
        "LAST_NAME": userData.value.last_name,
        "MOBILE_PHONE": userData.value.mobile_phone,
        "CITY_NAME": userData.value.city.name ?? '',
        "CITY_PLACE": userData.value.city.place ?? '',
        "CITY_LAT": userData.value.city.lat ?? '',
        "CITY_LON": userData.value.city.lon ?? '',
      }
      
      Object.entries(fields).forEach(([key, value]) => {
        if (value !== undefined) {
          formData.append(key, value);
        }
      });
      
      if (selectedFile.value) {
        formData.append("PERSONAL_PHOTO", selectedFile.value);
      }
    
      // Send updateUser request
      await userStore.updateUser(formData);
    
      const error = Object.values(userStore.errors);
    
      if (error.length === 0) {
        addToast(t('messages.any.success'), t('messages.text.success.userUpdate'), 'success');
      } else {
        Swal.fire({
          text: error[0] as string,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t('messages.button.okGotIt'),
          heightAuto: false,
          customClass: {
            confirmButton: "btn fw-semibold btn-light-danger",
          },
        }).then(() => {
          userStore.errors = {};
        });
      }
    
      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
      submitButton.value!.disabled = false;
    };

    return {
      userData,
      imageUploaderRef,
      onSubmitUpdate,
      profileValidator,
      submitButton,
      handleFileChange,
      resetForm,
      cleaveOptions,
      getCoordsByCity,
      getCityByCoords,
      isProcessCity,
    };
  },
});
</script>
