<template>
  <!--begin::Form-->
  <VForm
    class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
    novalidate
    @submit="onSubmitRegister"
    id="kt_login_signup_form"
    :validation-schema="registration"
  >
    <!--begin::Heading-->
    <div class="text-center mb-10">
      <!--begin::Title-->
      <h1 class="text-gray-900 fw-bolder mb-3">
        {{ $t('pages.authentication.signUp.pageTitle')}}
      </h1>
      <!--end::Title-->
      <!--begin::Subtitle-->
      <div class="text-gray-500 fw-semibold fs-6">
        {{ $t('pages.authentication.signUp.subtitle')}}
      </div>
      <!--end::Subtitle--->
    </div>
    <!--end::Heading-->

    <!--begin::OAuth-->
    <OAuth />
    <!--end::OAuth-->

    <!--begin::Separator-->
    <div class="d-flex align-items-center my-10">
      <div class="border-bottom border-gray-300 mw-50 w-100"></div>
      <span class="fw-semibold text-gray-400 fs-8 mx-2">
        {{ $t('pages.authentication.or')}}
      </span>
      <div class="border-bottom border-gray-300 mw-50 w-100"></div>
    </div>
    <!--end::Separator-->

    <div class="fv-row mb-8">
      <Field
        class="form-control form-control-lg form-control-solid"
        type="text"
        :placeholder="$t('pages.authentication.signUp.firstName')"
        name="name"
        autocomplete="off"
      />
      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          <ErrorMessage name="name" />
        </div>
      </div>
    </div>

    <!--begin::Input group-->
    <div class="fv-row mb-8">
      <Field
        class="form-control form-control-lg form-control-solid"
        type="email"
        :placeholder="$t('pages.authentication.email')"
        name="email"
        autocomplete="off"
        :validate-on-input="false"
      />
      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          <ErrorMessage name="email" />
        </div>
      </div>
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="fv-row mb-8" data-kt-password-meter="true">
      <!--begin::Wrapper-->
      <div class="mb-1">
        <!--begin::Input wrapper-->
        <div class="position-relative">
          <Field
            class="form-control form-control-lg form-control-solid"
            type="password"
            :placeholder="$t('pages.authentication.password')"
            name="password"
            autocomplete="off"
            :validate-on-input="false"
          />
          <!--begin::Visibility toggle-->
          <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
              data-kt-password-meter-control="visibility">
            <KTIcon icon-name="eye-slash" icon-class="fs-1" />
            <KTIcon icon-name="eye" icon-class="fs-1 d-none" />
          </span>
          <!--end::Visibility toggle-->
        </div>
        <div class="fv-plugins-message-container mb-3">
          <div class="fv-help-block">
            <ErrorMessage name="password" />
          </div>
        </div>
        <!--end::Input wrapper-->
        <!--begin::Meter-->
        <div
          class="d-flex align-items-center mb-3"
          data-kt-password-meter-control="highlight"
        >
          <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
          <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
          <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
          <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
        </div>
        <!--end::Meter-->
      </div>
      <!--end::Wrapper-->
      <!--begin::Hint-->
      <div class="text-muted">
        {{ $t('pages.authentication.signUp.textPassword')}}
      </div>
      <!--end::Hint-->
    </div>
    <!--end::Input group--->

    <!--begin::Input group-->
    <div class="fv-row mb-8" data-kt-password-meter="true">
      <div class="position-relative">
        <Field
          class="form-control form-control-lg form-control-solid"
          type="password"
          :placeholder="$t('pages.authentication.confirmPassword')"
          name="confirm_password"
          autocomplete="off"
        />
        <!--begin::Visibility toggle-->
        <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
            data-kt-password-meter-control="visibility">
          <KTIcon icon-name="eye-slash" icon-class="fs-1" />
          <KTIcon icon-name="eye" icon-class="fs-1 d-none" />
        </span>
        <!--end::Visibility toggle-->
      </div>
      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          <ErrorMessage name="confirm_password" />
        </div>
      </div>
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="fv-row mb-8">
      <div class="form-check form-check-custom form-check-solid">
        <Field
          v-model="agreementChecked"
          class="form-check-input align-self-start me-2"
          type="checkbox"
          name="agreement_accepted"
          value="1"
        />
        <span class="form-check-label fw-semibold text-gray-700 fs-6">
          {{ $t('pages.authentication.signUp.textAgree')}} 
          <span class="link-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#kt_modal_user_agreement">
            {{ $t('pages.authentication.signUp.textTermsUserAgreement')}}
          </span> 
          {{ $t('pages.authentication.signUp.textAgreePrefix')}} 
          <span class="link-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#kt_modal_privacy_policy">
            {{ $t('pages.authentication.signUp.textTermsPrivacyPolicy')}}
          </span>
        </span>
      </div>
      <div class="fv-plugins-message-container ms-11">
        <div class="fv-help-block">
          <ErrorMessage name="agreement_accepted" />
        </div>
      </div>
    </div>
    <!--end::Input group-->

    <!--begin::Submit button-->
    <button
      type="submit"
      id="kt_sign_up_submit"
      :class="['btn btn-lg w-100 mb-10', isSubmitDisabled ? 'btn-light' : 'btn-primary']"
      :data-kt-indicator="isSubmitting ? 'on' : null"
      :disabled="isSubmitDisabled || isSubmitting"
    >
      <span v-if="!isSubmitting" class="indicator-label">
        {{ $t('pages.authentication.signUp.button') }}
      </span>
      <span v-else class="indicator-progress">
        {{ $t('pages.authentication.wait') }}
        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
      </span>
    </button>
    <!--end::Submit button-->
    
    <!--begin::Sign up-->
    <div class="text-gray-500 text-center fw-semibold fs-6">
      {{ $t('pages.authentication.signUp.alreadyAccount')}}
      <router-link to="/sign-in" class="link-primary fw-semibold ms-1">
        {{ $t('pages.authentication.signUp.signIn')}}
      </router-link>
    </div>
    <!--end::Sign up-->
  </VForm>
  <!--end::Form-->
</template>

<script lang="ts" setup>
import { nextTick, onMounted, ref, computed } from "vue";
import { ErrorMessage, Field, Form as VForm } from "vee-validate";
import { useAuthStore, type User } from "@/stores/auth";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { PasswordMeterComponent } from "@/assets/ts/components";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import OAuth from "@/components/oauth/OAuth.vue";

const { t } = useI18n();
const userStore = useAuthStore();
const router = useRouter();

const isSubmitting = ref<boolean>(false);
const agreementChecked = ref<boolean>(false);

// Вычисляемое свойство для включения/выключения кнопки отправки
const isSubmitDisabled = computed(() => !agreementChecked.value);

const createRegistrationSchema = () => {
  return Yup.object().shape({
    name: Yup.string()
      .required(t('validation.requiredField', { field: t('pages.authentication.signUp.firstName') })),
    email: Yup.string()
      .required(t('validation.requiredField', { field: t('pages.authentication.email') }))
      .email(t('validation.email'))
      .min(6, t('validation.min', { min: 6 })),
    password: Yup.string()
      .required(t('validation.requiredField', { field: t('pages.authentication.password') }))
      .min(8, t('validation.min', { min: 8 })),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password")], t('validation.passwordsMustMatch')),
    agreement_accepted: Yup.string().required(t('validation.requiredConsent'))
  });
};

const registration = computed(() => createRegistrationSchema());

onMounted(() => {
  nextTick(() => {
    PasswordMeterComponent.bootstrap();
  });
});

const onSubmitRegister = async (values: any) => {
  values = values as User;
  // userStore.logout(); // Очистка существующих ошибок

  isSubmitting.value = true; // Активируем индикатор

  // Отправляем запрос на регистрацию
  await userStore.register(values);

  const error = Object.values(userStore.errors);

  if (error.length === 0) {
    // Переход на страницу после успешной регистрации
    router.push({ name: "welcome" });
  } else {
    Swal.fire({
      text: error[0] as string,
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: t('messages.button.tryAgain'),
      heightAuto: false,
      customClass: {
        confirmButton: "btn fw-semibold btn-light-danger",
      },
    }).then(() => {
      userStore.errors = {};
    });
  }

  isSubmitting.value = false; // Деактивируем индикатор
};
</script>