<template>
  <!--begin::Modal - Cookie Policy-->
  <div
    class="modal fade"
    id="kt_modal_cookie_policy"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content shadow-none bg-gray-200">
        <div class="modal-header justify-content-end border-bottom-0 px-lg-10 pt-lg-10 pb-5">
          <div class="btn btn-sm btn-light btn-active-light-primary ms-2"  data-bs-dismiss="modal">
            {{ $t('buttons.close') }}
          </div>
        </div>
  
        <div class="modal-body ff-open-sans w-100">
          <div class="d-flex flex-column align-items-center">
            <h1 class="mw-800px fs-2x text-center">
              Политика использования файлов cookie
            </h1>
            <div class="fs-5 text-start mw-800px mt-md-5 mt-4">
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">Уведомление об использовании cookie файлов</h2>
                  <p>Наш сайт, как и большинство других, использует файлы cookie и аналогичные технологии (например, пиксельные тэги) для предоставления услуг, наиболее подходящих вашим интересам, а также для сбора статистической и маркетинговой информации с целью улучшения наших сервисов.</p>
                  <p>Используя данный сайт, вы соглашаетесь на использование файлов cookie в соответствии с настоящей Политикой. Если вы не согласны с их использованием, вы можете изменить настройки вашего браузера или прекратить использование нашего сайта. Обратите внимание, что блокировка cookie может повлиять на функциональность сайта.</p>
                  <p>Файлы cookie, сохраняемые через наш сайт, не содержат сведений, по которым можно вас идентифицировать.</p>
                </div>
              </div>

              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">Что такое файлы cookie и аналогичные технологии?</h2>
                  <p>Файлы cookie — это небольшие текстовые файлы, сохраняемые на вашем устройстве (компьютере, смартфоне и др.), которые позволяют сайтам запоминать информацию о вашем посещении. Также могут использоваться пиксельные тэги и веб-маяки — электронные изображения, такие как GIF-файлы, для сбора информации о взаимодействии с сайтом.</p>
                  <p>Cookie могут быть установлены как нашим сайтом (собственные cookie), так и сторонними операторами (сторонние cookie).</p>
                  <p>Мы используем два типа файлов cookie:</p>
                  <ul>
                    <li><strong>Сессионные cookie</strong> — временные файлы, удаляющиеся при закрытии браузера.</li>
                    <li><strong>Постоянные cookie</strong> — файлы, остающиеся на вашем устройстве до их удаления или по истечении их срока действия.</li>
                  </ul>
                </div>
              </div>

              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">Типы файлов cookie, которые мы используем</h2>
                    <p><strong>Необходимые файлы cookie</strong>: обеспечивают работу сайта и его основных функций. Без них сайт не сможет работать корректно.</p>
                    <p><strong>Производственные и аналитические файлы cookie</strong>: позволяют нам анализировать посещения сайта, улучшать его содержание и оценивать эффективность рекламных кампаний.</p>
                    <p><strong>Функциональные файлы cookie</strong>: запоминают ваши предпочтения (например, язык или регион) для более удобного использования сайта.</p>
                    <p><strong>Рекламные файлы cookie</strong>: собирают информацию о ваших интересах, чтобы показывать релевантную рекламу.</p>
                </div>
              </div>
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">Использование файлов cookie в электронной почте</h2>
                  <p>Мы можем отслеживать, открыли ли вы наши письма, чтобы улучшить качество предоставляемой информации. Вы всегда можете отписаться от рассылки, нажав на ссылку «Отписаться» внизу письма.</p>
                </div>
              </div>
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">Внешние веб-сервисы</h2>
                  <p>Для отображения контента (изображений, видео и т. п.) мы можем использовать сторонние веб-сервисы, которые также могут собирать информацию о вашем взаимодействии с контентом на нашем сайте.</p>
                </div>
              </div>
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">Как управлять файлами cookie?</h2>
                  <p>Большинство интернет-браузеров настроены на автоматическое принятие файлов cookie. Вы можете изменить настройки вашего браузера, чтобы блокировать cookie или предупреждать вас о их отправке. Отключение файлов cookie может повлиять на работу сайта.</p>
                  <p>Если вы используете несколько устройств и браузеров, настройки нужно будет изменить на каждом из них.</p>
                </div>
              </div>
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">Изменения в политике</h2>
                  <p>Мы можем время от времени обновлять настоящую Политику. Обо всех изменениях мы будем уведомлять на этой странице.</p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Modal - Cookie Policy-->
</template>
