import i18n from "@/core/plugins/i18n";

/**
 * Обрабатывает ошибки API и добавляет их в массив ошибок.
 * @param {string[]} errors - Существующий массив ошибок.
 * @param {any} error - Ошибка, полученная от запроса.
 * @param {string} localizedError - Ключ локализованного сообщения об ошибке.
 * @returns {void}
 */
export function handlerApiError(errors: string[], error: any, localizedError: string = ''): void {
  if (error.response) {
    const errorCode = error.response.data.error;
    const errorDescription =
      i18n.global.t(`messages.text.error.${localizedError}`) ||
      // error.response.data.error_description ||
      i18n.global.t('error.unknown');
    
    // Проверяем, существует ли локализация для errorCode
    const localizedMessage = i18n.global.te(`error.${errorCode}`)
      ? i18n.global.t(`error.${errorCode}`) // Используем локализованное сообщение
      : errorDescription; // Используем сообщение по умолчанию

    setError(errors, localizedMessage);
  } else if (error.request) {
    // Сообщение для случая, когда нет ответа от сервера
    setError(errors, i18n.global.t('error.noResponseServer'));
  } else {
    // Универсальное сообщение для всех остальных ошибок
    setError(errors, i18n.global.t('error.generalError'));
  }
}

/**
 * Устанавливает ошибки в массив.
 * @param {string[]} errors - Существующий массив ошибок.
 * @param {string | string[]} error - Ошибка, которая должна быть добавлена.
 * @returns {void}
 */
function setError(errors: string[], error: string | string[]): void {
  if (Array.isArray(error)) {
    errors.push(...error);
  } else {
    errors.push(error);
  }
}

/**
 * Универсальная функция для обновления или добавления элементов в массив.
 * Если элемент с таким же значением ключевого свойства уже существует, он заменяется, иначе добавляется новый элемент.
 *
 * @param {Array} targetArray - Массив, в котором будут обновлены или добавлены элементы.
 * @param {Array} newItems - Массив новых элементов для добавления или обновления.
 * @param {string} key - Ключевое свойство, по которому будут идентифицированы уникальные элементы.
 */
export function updateOrAddItems(targetArray: any[], newItems: any[], key: string): void {
  newItems.forEach(newItem => {
    const existingItem = targetArray.find(item => item[key] === newItem[key]);

    // Если элемент с таким ключевым значением уже существует, обновляем его
    if (existingItem) {
      Object.assign(existingItem, newItem);
    } else {
      // Если элемента нет, добавляем его в массив
      targetArray.push(newItem);
    }
  });
}
