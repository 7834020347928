<template>
  <tbody>
    <template v-for="(item, i) in placeholderItems" :key="i">
      <tr class="border-bottom-0">
        <td colspan="4" class="pb-0">
          <div class="rounded-1 placeholder bg-secondary placeholder-lg col-8"></div>
        </td>
      </tr>
      <tr>
        <td class="vw-100">
          <div class="d-flex align-items-center">
            <div class="symbol symbol-50px placeholder bg-secondary">
              <span class="symbol-label placeholder bg-secondary"></span>
            </div>
            <div class="d-flex flex-column w-100 gap-5 ms-5">
              <div class="rounded-1 placeholder bg-secondary placeholder-lg col-10"></div>
              <div class="rounded-1 placeholder bg-secondary placeholder-sm col-6"></div>
            </div>
          </div>
        </td>
        <td class="text-end px-0">
          <div class="d-flex align-items-start align-items-sm-end flex-column gap-4">
            <div class="rounded-1 placeholder bg-secondary placeholder-lg col-11"></div>
            <div class="rounded-1 placeholder bg-secondary placeholder-sm col-9"></div>
          </div>
        </td>
        <td class="text-end px-0">
          <div class="rounded-1 placeholder bg-secondary placeholder-lg h-50px w-50px"></div>
        </td>
        <td class="text-end pe-0 d-none d-sm-table-cell d-md-none d-lg-table-cell">
          <div class="rounded-1 placeholder bg-secondary placeholder-lg h-30px w-80px ms-5"></div>
        </td>
      </tr>
    </template>
  </tbody>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'placeholder-dashboard-last-search',
  props: {
    length: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const placeholderItems = Array.from({ length: props.length }, () => ({}));

    return {
      placeholderItems,
    };
  },
});
</script>