<template>
  <div class="d-flex flex-column flex-sm-row align-items-sm-center">
    <div class="d-flex flex-row order-2 order-sm-1 mt-7 mt-sm-0">
      <router-link
        :to="`/search/${item.id}`"
        class="symbol symbol-75px"
        :aria-label="item.title"
      >
        <DynamicSymbol 
          :title="item.title" 
          :image="item.image" 
          fontSizeClass="fs-2"
        />
        <SymbolStatus
          :status="item.status"
          :archive="item.archive"
          :links="item.isLinks"
        />
      </router-link>
      
      <template v-if="isMobile">
        <div
          v-lozad
          class="d-flex flex-grow-1 justify-content-around align-items-center ms-14"
        >
          <template v-if="isVisibility">
            <BestOffer :item="item" class="animate__animated animate__fadeIn" />
            <SearchChart :chart-data="item.chart" class="animate__animated animate__fadeIn" />
          </template>
        </div>
      </template>
    </div>

    <div class="d-flex flex-column order-1 order-sm-2 ms-0 ms-sm-5 me-15 me-sm-0">
      <router-link :to="`/search/${item.id}`" :class="titleClass">
        {{ item.title }}
      </router-link>

      <div :class="[
        'd-flex',
        item.title.length > 50 ? 'flex-row' : 'flex-row flex-sm-column'
      ]">
        <GroupLink
          :item="item"
          :group="group"
          class="mt-2 me-4"
        />
        <WishPriceDisplay
          :item="item"
          class="mt-2"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useItemStore } from '@/stores/item';
import { isMobile } from "@/core/helpers/mobile";
import lozad from 'lozad';

import SymbolStatus from '@/components/items/SymbolStatus.vue';
import BestOffer from "@/components/items/BestOffer.vue";
import SearchChart from "@/components/chart/SearchChart.vue";
import DynamicSymbol from '@/components/formatters/DynamicSymbol.vue';
import GroupLink from '@/components/items/GroupLink.vue';
import WishPriceDisplay from '@/components/items/WishPriceDisplay.vue';

export default defineComponent({
  name: 'item-card',
  components: {
    SymbolStatus,
    BestOffer,
    SearchChart,
    DynamicSymbol,
    GroupLink,
    WishPriceDisplay,
  },
  directives: {
    lozad: {
      mounted(el: HTMLElement, binding: any) {
        const observer = lozad(el, {
          loaded: () => {
            if (binding.instance) {
              binding.instance.isVisibility = true;
            }
          },
        });
        observer.observe();
      },
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const itemStore = useItemStore();
    const isVisibility = ref(false);

    // Используем функцию сравнения цены из хранилища
    const titleClass = computed(() => ({
      'fw-bold mb-1 text-hover-primary fs-5': true,
      'text-gray-800': !itemStore.priceComparison(props.item),
      'text-success': itemStore.priceComparison(props.item),
    }));
        
    return {
      isMobile,
      titleClass,
      isVisibility,
    };
  },
});
</script>
