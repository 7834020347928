<template>
  <div @click="confirmAction">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { useItemStore } from "@/stores/item";
import { addToast } from '@/utils/toastManager';
import Swal from "sweetalert2/dist/sweetalert2.js";

const props = defineProps({
  itemData: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();
const itemStore = useItemStore();

const confirmAction = async () => {
  try {
    // Находим индекс элемента
    const itemIndex = itemStore.searchs.findIndex(item => Number(item.id) === Number(props.itemData.id));
    
    // Проверяем, что элемент найден
    if (itemIndex !== -1) {      
      await itemStore.clearImageSearch({ id: props.itemData.id }, "clearImage");

      // Проверяем, что нет ошибок
      if (!Object.keys(itemStore.errors).length) {
        itemStore.searchs[itemIndex].image = '';
        addToast(t('messages.any.success'), t('messages.text.success.clearImage'), 'success');
      } else {
        handleError(itemStore.errors);
      }
    } else {
      handleError(t('messages.text.error.clearImage'));
    }
  } catch (error) {
    handleError(error);
  }
};

// Константа для обработки ошибок
const handleError = (error) => {
  Swal.fire({
    text: error,
    icon: "error",
    buttonsStyling: false,
    confirmButtonText: t('messages.button.okGotIt'),
    heightAuto: false,
    customClass: {
      confirmButton: "btn btn-danger",
    },
  }).then(() => {
    itemStore.errors = [];
  });
}
</script>
