<template>
  <!--begin::Navbar-->
  <div class="card mb-5 mb-xxl-8">
    <div class="card-body pt-6 pt-sm-9 px-6 px-sm-9 pb-0">
      <!--begin::Details-->
      <div
        v-if="!isLoading && searchData"
        class="d-flex flex-wrap flex-sm-nowrap mb-3"
      >
        <div class="me-7 mb-4 order-2 order-sm-1">
          <div class="symbol symbol-125px symbol-sm-150px symbol-lg-160px symbol-fixed">
            <DynamicSymbol 
              :title="searchData.title" 
              :image="searchData.image" 
              fontSizeClass="fs-1" 
            />
            <SymbolStatus
              :status="searchData.status"
              :archive="searchData.archive"
              :links="searchData.isLinks"
              :mobile="false"
              class="ms-7 border-4 h-20px w-20px"
            />
          </div>
        </div>

        <template v-if="isMobile">
          <div class="flex-fill align-items-center order-3 mb-7">
            <BestOfferDetail
              :bestOffer="searchData"
              :label="true"
              class="h-125px"
            />
          </div>
          
          <div class="d-flex flex-wrap flex-fill order-4 gap-5">
            <DateCard
              v-if="searchData.created_at"
              :date="searchData.created_at"
              :text="$t('pages.searchId.added')"
              class="flex-fill"
            />
            <DateCard
              v-if="searchData.timestamp_x"
              :date="searchData.timestamp_x"
              :text="$t('pages.searchId.modified')"
              class="flex-fill"
            />
            <WordsCard
              v-if="searchData.required_words"
              :words="searchData.required_words"
              :text="$t('pages.searchId.requiredWords')"
              class="flex-fill"
            />
            <WordsCard
              v-if="searchData.excluded_words"
              :words="searchData.excluded_words"
              :text="$t('pages.searchId.excludedWords')"
              class="flex-fill"
            />
          </div>
        </template>

        <!--begin::Info-->
        <div class="flex-grow-1 order-1 order-sm-2 w-100 w-sm-auto">

          <div class="d-flex justify-content-between">
            <ItemHeader
              :item="searchData"
              :group="itemStore.groups"
            />
            <ActionsSearch
              v-if="searchData"
              :data-store="searchData"
              :is-setting-disabled="isSettingDisabled"
              :is-menu-preload="true"
              @data-modal="openEditItemModal"
              @data-clear-products="updateProductsData"
              @data-clear-analytics="updateAnalyticsData"
            />
          </div>

          <div
            v-if="!isMobile"
            class="d-flex flex-wrap flex-row gap-5"
          >
            <BestOfferDetail :bestOffer="searchData" />
            <DateCard
              v-if="searchData.created_at"
              :date="searchData.created_at"
              :text="$t('pages.searchId.added')"
            />
            <DateCard
              v-if="searchData.timestamp_x"
              :date="searchData.timestamp_x"
              :text="$t('pages.searchId.modified')"
            />
            <WordsCard
              v-if="searchData.required_words"
              :words="searchData.required_words"
              :text="$t('pages.searchId.requiredWords')"
            />
            <WordsCard
              v-if="searchData.excluded_words"
              :words="searchData.excluded_words"
              :text="$t('pages.searchId.excludedWords')"
            />
          </div>
          
        </div>
        <!--end::Info-->
      </div>
      
      <Placeholder v-else />
      <!--end::Details-->

      <!--begin::Navs-->
      <div class="d-flex overflow-auto h-55px">
        <ul
          class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold flex-nowrap"
        >
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              :to="{ name: 'search-id-list', params: { id: searchId } }"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              {{ $t('pages.searchId.nav.list') }}
            </router-link>
          </li>
          <li class="nav-item" id="chart">
            <router-link
              :to="{ name: 'search-id-analytics', params: { id: searchId } }"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              {{ $t('pages.searchId.nav.analytics') }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'search-id-links', params: { id: searchId } }"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              {{ $t('pages.searchId.nav.links') }}
            </router-link>
          </li>
          <!--end::Nav item-->
        </ul>
      </div>
      <!--begin::Navs-->
    </div>
  </div>
  <!--end::Navbar-->
  <router-view></router-view>
  
  <ItemsModal 
    :edit-mode="true" 
    :item-data="itemData"
    :step-page="stepPage"
    @data-updated="fetchSearchData" 
  ></ItemsModal>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onUnmounted, watch, provide } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { useItemStore } from "@/stores/item";
import { isMobile } from "@/core/helpers/mobile";
import cloneDeep from 'lodash/cloneDeep';

import ItemHeader from "@/components/items/ItemHeader.vue";
import ItemsModal from "@/components/modals/wizards/ItemsModal.vue";
import SymbolStatus from '@/components/items/SymbolStatus.vue';
import BestOfferDetail from "@/components/items/BestOfferDetail.vue";
import DateCard from '@/components/items/DateCard.vue';
import WordsCard from '@/components/items/WordsCard.vue';
import DynamicSymbol from '@/components/formatters/DynamicSymbol.vue';
import ActionsSearch from "@/components/dropdown/ActionsSearch.vue";
import Placeholder from '@/components/placeholder/ItemsSearchDetails.vue'; // Импортируем компонент

export default defineComponent({
  name: "search-id",
  components: {
    ItemHeader,
    ItemsModal,
    SymbolStatus,
    BestOfferDetail,
    DateCard,
    WordsCard,
    DynamicSymbol,
    ActionsSearch,
    Placeholder,
  },
  setup() {
    let isMounted = true;
    const route = useRoute();
    const router = useRouter();
    const itemStore = useItemStore();
    const itemData = ref<Record<string, any>>({});
    const isSettingDisabled = ref(true);
    const stepPage = ref<String | null>(null);

    const searchId = computed(() => Number(route.params.id) || 0);

    const searchData = computed(() => {
      const itemIndex = itemStore.searchs.findIndex(item => {
        return Number(item.id) === searchId.value;
      });
    
      return itemIndex !== -1 ? itemStore.searchs[itemIndex] : null;
    });
    
    const isLoading = computed(() => {
      return searchData.value === null;
    });
    
    const fetchSearchData = async () => {
      isSettingDisabled.value = true;
      await itemStore.fetchSearchPartData({ id: searchId.value });
      // Проверяем, что компонент все еще смонтирован
      if (isMounted && searchData.value === null) {
        router.push({ name: '404' });
      }
      isSettingDisabled.value = false;
    };
    
    const deleteSearchData = (itemId: number) => {
      const index = itemStore.searchs.findIndex(item => item.id === itemId);
    
      // Проверяем, что элемент найден
      if (index !== -1) {
        itemStore.searchs.splice(index, 1);
        
        // Безопасно уменьшаем счётчик, если он больше 0
        itemStore.countSearch = Math.max(0, itemStore.countSearch - 1);
      }
      
      router.push({ name: 'search' });
    };
    
    const updateProductsData = async (itemId) => {
      router.push({ name: 'search-id-list', params: { id: itemId }, query: { clear: true } });
    }

    const updateAnalyticsData = async (itemId) => {
      router.push({ name: 'search-id-analytics', params: { id: itemId }, query: { clear: true } });
      await fetchSearchData();
    }
    
    const openEditItemModal = (data) => {
      itemData.value = cloneDeep(data.item);
      stepPage.value = data.step;
    };

    onMounted(async () => {
      isMounted = true;
      await itemStore.fetchGroupData();
      fetchSearchData();
    });
    
    onUnmounted(() => {
      isMounted = false;
    });
    
    // Следим за изменениями в ссылке для обновления данных
    watch(() => route.params.id, async (newVal) => {
      if (newVal) {
        await fetchSearchData(); // Обновляем данные
      }
    });
    
    provide('actions', {
      'item-deleted': deleteSearchData,
      'item-archived': fetchSearchData
    });

    return {
      route,
      isMobile,
      searchData,
      itemData,
      itemStore,
      stepPage,
      fetchSearchData,
      openEditItemModal,
      updateProductsData,
      updateAnalyticsData,
      isLoading,
      isSettingDisabled,
      searchId,
    };
  },
});
</script>
