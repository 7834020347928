<template>
  <!--begin::Menu-->
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
    data-kt-menu="true"
  >
    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <div class="menu-content d-flex align-items-center px-3">
        <!--begin::Avatar-->
        
        <div class="symbol symbol-45px me-5">
          <span 
            v-if="userStore.user.photo_url"
            class="symbol-label bg-dark text-inverse-info" 
            :style="`background-image: url('${ getAssetUrl(userStore.user.photo_url) }')`"
          ></span>
            <span v-else-if="userStore.user.name" class="symbol-label bg-dark text-inverse-info">
            {{ userStore.user.name.slice(0, 1).toUpperCase() }}
          </span>
          <span v-else-if="userStore.user.email" class="symbol-label bg-dark text-inverse-info">
            {{ userStore.user.email.slice(0, 1).toUpperCase() }}
          </span>
          <span v-else class="symbol-label bg-dark text-inverse-info">H</span>
        </div>
        <!--end::Avatar-->

        <!--begin::Username-->
        <div class="d-flex flex-column">
          <div v-if="userStore.user" class="fw-bold d-flex align-items-center">
            <span v-if="userStore.user.name" class="fs-5 me-2">{{ userStore.user.name }}</span>
            <span
              v-if="userStore.user.subscription"
              class="badge fw-semibold fs-8 px-2 py-1"
              :class="`badge-light-${userStore.user.subscription.color}`"
            >{{ userStore.user.subscription.type }}</span>
          </div>
          <div class="fw-semibold text-muted text-hover-primary fs-7">{{ userStore.user.email }}</div>
        </div>
        <!--end::Username-->
      </div>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu separator-->
    <div class="separator my-2"></div>
    <!--end::Menu separator-->

    <!--begin::Profile-->
    <div class="menu-item px-5">
      <router-link to="/account/overview" class="menu-link pe-5 ps-2">
        <span class="menu-icon" data-kt-element="icon">
          <KTIcon icon-name="profile-circle" icon-class="fs-3" />
        </span>
        {{ $t('menu.userAccount.profile') }}
      </router-link>
    </div>
    <!--end::Profile-->
    
    <!--begin::Settings-->
    <div class="menu-item px-5">
      <router-link to="/account/settings" class="menu-link pe-5 ps-2">
        <span class="menu-icon" data-kt-element="icon">
          <KTIcon icon-name="setting-2" icon-class="fs-3" />
        </span>
        {{ $t('menu.userAccount.settings') }}
      </router-link>
    </div>
    <!--end::Settings-->
    
    <!--begin::Language-->
    <div
      class="menu-item px-5"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="center, top"
    >
      <div class="menu-link pe-5 ps-2">
        <span class="menu-title position-relative">
          <span class="menu-icon" data-kt-element="icon">
            <KTIcon icon-name="icon" icon-class="fs-3" />
          </span>
          {{ $t('menu.userAccount.language') }}
          <span class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
            {{ currentLanguageLocale.name }}
            <img
              class="w-15px h-15px rounded-circle ms-2"
              :src="currentLanguageLocale.flag"
            />
          </span>
        </span>
      </div>

      <!--begin::Menu sub-->
      <div class="menu-sub menu-sub-dropdown w-175px py-4">
        <!--begin::Menu item-->
        <div class="menu-item px-3" v-for="(country, key) in countriesList" :key="key">
          <div
            @click="setLang(key)"
            class="menu-link d-flex px-5"
            :class="{ active: currentLanguage === key }"
          >
            <span class="symbol symbol-20px symbol-circle me-4">
              <img :src="country.flag" alt="" />
            </span>
            {{ country.name }}
          </div>
        </div>
        <!--end::Menu item-->
      </div>
      <!--end::Menu sub-->
    </div>
    <!--end::Language-->

    <!--begin::Notifications-->
<!--     <div class="menu-item px-5">
      <router-link to="/account/notifications" class="menu-link pe-5 ps-2">
        <span class="menu-icon" data-kt-element="icon">
          <KTIcon icon-name="notification-circle" icon-class="fs-3" />
        </span>
        {{ $t('menu.userAccount.notifications') }}
      </router-link>
    </div> -->
    <!--end::Notifications-->
    
    <!--begin::Logs-->
    <div class="menu-item px-5">
      <router-link to="/account/logs" class="menu-link pe-5 ps-2">
        <span class="menu-icon" data-kt-element="icon">
          <KTIcon icon-name="monitor-mobile" icon-class="fs-3" />
        </span>
        {{ $t('menu.userAccount.logs') }}
      </router-link>
    </div>
    <!--end::Logs-->

    <!--begin::signOut-->
    <div class="menu-item px-5 mt-1">
      <div @click="signOut()" class="btn btn-sm btn-outline btn-light text-gray-600 justify-center w-100">
        {{ $t('menu.userAccount.signOut') }}
      </div>
    </div>
    <!--end::signOut-->
  </div>
  <!--end::Menu-->
</template>

<script lang="ts">
import { getAssetUrl } from "@/core/helpers/assets";
import { defineComponent, onMounted, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";
import { localization } from "@/core/plugins/i18n";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const userStore = useAuthStore();
    const { locale } = useI18n();
    
    const countriesList = computed(() => localization);

    const signOut = () => {
      userStore.logout();
      router.push({ name: "sign-in" });
    };

    const setLang = (lang: string) => {
      localStorage.setItem("lang", lang);
      locale.value = lang;
    };
    
    const currentLanguage = computed(() => {
      return locale.value;
    });
    
    const currentLanguageLocale = computed(() => {
      return localization[locale.value as keyof typeof localization];
    });
    
    onMounted(() => {
      watch(
        () => userStore.user?.agreement_accepted,
        (newValue) => {
          if (newValue === false) {
            // Переход на страницу принятия соглашения
            router.push({ name: "accept-agreement" });
          }
        },
        { immediate: true }
      );
    });

    return {
      userStore,
      signOut,
      setLang,
      countriesList,
      currentLanguage,
      currentLanguageLocale,
      getAssetUrl,
    };
  },
});
</script>
