import { ref, nextTick, onMounted, onUnmounted } from "vue";
import { DrawerComponent } from "@/assets/ts/components";

export function useDrawer() {
  const isDrawerVisible = ref(false);
  const drawerRef = ref<HTMLElement | null>(null);
  let drawerInstance: DrawerComponent | null = null;
  
  const initializeDrawer = () => {
    DrawerComponent.reinitialization();
    
    if (!drawerRef.value || !drawerRef.value.id) return;
  
    // Получаем экземпляр компонента через ID элемента
    drawerInstance = DrawerComponent.getInstance(drawerRef.value.id);
  
    if (drawerInstance) {
      // Слушаем событие toggle, чтобы переключать состояние видимости
      drawerInstance.on("kt.drawer.toggle", () => {
        isDrawerVisible.value = true;
      });

      // Слушаем событие после скрытия
      drawerInstance.on("kt.drawer.after.hidden", () => {
        // Добавляем задержку для ожидания завершения анимации скрытия
        setTimeout(() => {
          isDrawerVisible.value = false;
        }, 200);
      });
    }
  };

  const cleanupDrawer = () => {
    if (drawerInstance) {
      drawerInstance.off("kt.drawer.toggle");
      drawerInstance.off("kt.drawer.after.hidden");
      drawerInstance = null;
    }
  };
  
  onMounted(() => {
    nextTick(() => {
      initializeDrawer();
    });
  });

  onUnmounted(() => {
    cleanupDrawer();
  });

  return {
    drawerRef,
    isDrawerVisible,
  };
}
