<template>
  <!--begin::Connected Accounts-->
  <div class="card mb-5 mb-xl-10" id="oauth">
    <!--begin::Card header-->
    <div
      class="card-header collapsible cursor-pointer rotate min-h-60px"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_connected_accounts"
      aria-expanded="true"
      aria-controls="kt_account_connected_accounts"
    >
      <h3 class="card-title fw-bold m-0">
        {{ $t('pages.account.layoutSettings.linkedAccounts') }}
      </h3>
      <div class="card-toolbar rotate-180">
        <i class="ki-duotone ki-down fs-1"></i>
      </div>
    </div>
    <!--end::Card header-->
  
    <!--begin::Content-->
    <div id="kt_account_connected_accounts" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body p-9">
  
        <!--begin::Items-->
        <div class="py-2">
          <!--begin::Items Loop-->
          <div v-for="(oauth, index) in userStore.oauthStore" :key="index">

            <div class="d-flex flex-stack">
              <div class="d-flex">
                <img
                  :src="getOAuthIconPath(oauth.icon)"
                  class="w-30px me-6"
                  :alt="`Logo ${oauth.name}`"
                />
                <div class="d-flex flex-column">
                  <div class="fs-5 text-gray-900 fw-bold">
                    {{ oauth.name }}
                  </div>
                  <div class="fs-6 fw-semibold text-gray-500">
                    <span v-if="!oauth.user">
                      {{ $t('pages.account.layoutSettings.accountNotConnected') }}
                    </span>
                    <span v-else>
                      {{ oauth.user.view_name }}
                    </span>
                  </div>
                </div>
              </div>
              
              <div class="d-flex justify-content-end">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input w-40px h-25px"
                    type="checkbox"
                    :id="`${oauth.icon}switch`"
                    :checked="!!oauth.user"
                    :disabled="oauth.can_delete === 'N'"
                    @change="(event) => toogleOauth(event.target.checked, oauth, event)"
                  />
                  <label class="form-check-label" :for="`${oauth.icon}switch`"></label>
                </div>
              </div>
            </div>
            
            <div v-if="index < userStore.oauthStore.length - 1" class="separator my-5"></div>
          </div>
          <!--end::Items Loop-->          
        </div>
        <!--end::Items-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Connected Accounts-->
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, ref } from "vue";
import { getAssetPath } from "@/core/helpers/assets";
import { useAuthStore } from "@/stores/auth";
import { useThemeStore } from "@/stores/theme";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { addToast } from '@/utils/toastManager';
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "account-settings-connected-account",
  setup() {
    const { t } = useI18n();
    const userStore = useAuthStore();
    const themeStore = useThemeStore();
    const router = useRouter();
    
    const errorSwal = (oauthName, switchState) => {
      const messageKey = switchState === 'on' ? 'messages.text.error.switchOnAccount' : 'messages.text.error.switchOffAccount';
    
      Swal.fire({
        text: t(messageKey, { service: oauthName }),
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: t('messages.button.okGotIt'),
        heightAuto: false,
        customClass: {
          confirmButton: 'btn btn-danger',
        },
      });
    };

    const getOAuthIconPath = (id: string) => {
      if (id === 'google') {
        return getAssetPath('media/svg/brand-logos/google-icon.svg');
      }
      if (id === 'telegram') {
        return getAssetPath('media/svg/brand-logos/telegram.svg');
      }
      if (id === 'apple') {
        return themeStore.resolvedMode === 'dark' 
          ? getAssetPath('media/svg/brand-logos/apple-black-dark.svg') 
          : getAssetPath('media/svg/brand-logos/apple-black.svg');
      }
      return '';
    };
    
    const toogleOauth = async (isChecked: boolean, oauth: any, event: Event) => {
      // Сохранение текущего состояния чекбокса
      const originalChecked = !isChecked;
    
      if (userStore.isDemo) {
        setTimeout(() => {
          revertCheckboxState(event, originalChecked);
        }, 3000); // Задержка в 3 секунды
        return;
      }
    
      if (!isChecked && oauth.user && oauth.user.id) {
        await handleSwitchOff(oauth, event, originalChecked);
      } else if (isChecked && oauth.link) {
        await handleSwitchOn(oauth, event, originalChecked);
      }
    };
    
    // Вспомогательная функция для переключения на выключение
    async function handleSwitchOff(oauth: any, event: Event, originalChecked: boolean) {
      Swal.fire({
        text: t('messages.text.warning.switchOffAccount', { service: oauth.name }),
        icon: 'warning',
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: t('messages.button.switchOff'),
        cancelButtonText: t('messages.button.noCancel'),
        heightAuto: false,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const success = await userStore.oauthRemove(oauth.user.id);
            if (success) {
              await fetchItemsData();
              addToast(
                t('messages.any.success'),
                t('messages.text.success.switchOffAccount',
                { service: oauth.name }),
                'success'
              );
            } else {
              handleError(oauth.name, 'off', event, originalChecked);
            }
          } catch (error) {
            handleError(oauth.name, 'off', event, originalChecked);
          }
        } else {
          revertCheckboxState(event, originalChecked);
        }
      });
    }
    
    // Вспомогательная функция для переключения на включение
    async function handleSwitchOn(oauth: any, event: Event, originalChecked: boolean) {
      try {
        // Проверяем, что oauth.link существует и является строкой
        if (oauth.link && typeof oauth.link === 'string') {
          // Сохраняем объект в sessionStorage в виде строки JSON
          sessionStorage.setItem('oauthData', JSON.stringify({
              type: 'connected',
              provider: oauth.name,
              backurl: { path: router.currentRoute.value.path, hash: 'oauth' }
            }));

          window.location.href = oauth.link;
        } else {
          return; // Для других сервисов перенаправляем пользователя и выходим из функции
        }
      } catch (error) {
        handleError(oauth.name, 'on', event, originalChecked);
      }
    }
    
    // Вспомогательная функция для обработки ошибок
    function handleError(serviceName: string, action: 'on' | 'off', event: Event, originalChecked: boolean) {
      errorSwal(serviceName, action);
      revertCheckboxState(event, originalChecked);
    }
    
    // Вспомогательная функция для возврата состояния чекбокса
    function revertCheckboxState(event: Event, originalChecked: boolean) {
      (event.target as HTMLInputElement).checked = originalChecked;
    }
        
    const fetchItemsData = async () => {      
      await userStore.oauthList();
    };
    
    onMounted(async () => {
      await fetchItemsData();
    });

    return {
      getOAuthIconPath,
      toogleOauth,
      userStore,
    };
  },
});
</script>
