import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { ThemeModeComponent } from "@/assets/ts/layout";

export type Mode = "light" | "dark" | "system";
export type ColorMode = "light" | "dark";

export const THEME_MODE_LS_KEY = "kt_theme_mode_value";
export const THEME_MENU_MODE_LS_KEY = "kt_theme_mode_menu";

export const useThemeStore = defineStore("theme", () => {
  const mode = ref<Mode>(
    localStorage.getItem(THEME_MODE_LS_KEY) as Mode
  );

  const menuMode = ref<Mode>(
    localStorage.getItem(THEME_MENU_MODE_LS_KEY) as Mode
  );

  const resolvedMode = computed<ColorMode>(() => {
    return getResolvedMode(mode.value);
  });
  
  function getResolvedMode(currentMode: Mode): ColorMode {
    if (currentMode === "system") {
      return ThemeModeComponent.getSystemMode();
    }
    return currentMode;
  }

  function setThemeMode(payload: Mode) {
    let currentMode = getResolvedMode(payload);

    localStorage.setItem(THEME_MODE_LS_KEY, payload);
    mode.value = payload;

    localStorage.setItem(THEME_MENU_MODE_LS_KEY, payload);
    menuMode.value = payload;

    document.documentElement.setAttribute("data-bs-theme", currentMode);
    ThemeModeComponent.init();
  }

  function updateMode(payload: Mode) {
    mode.value = payload;
  }

  return {
    mode,
    menuMode,
    setThemeMode,
    updateMode,
    resolvedMode,
  };
});
