<template>
  <!--begin::Authentication Layout -->
  <div class="d-flex flex-column flex-lg-row flex-column-fluid">
    <!--begin::Body-->
    <div class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-md-12 p-4 order-2 order-lg-1">
      <!--begin::Wrapper-->
      <div class="bg-body d-flex flex-column flex-center rounded-4 w-md-500px w-100 p-10">
        <!--begin::Form-->
        <div class="d-flex flex-center flex-column flex-lg-row-fluid w-100">
          <!--begin::Content-->
          <div class="w-md-500px w-100 p-0 p-sm-10 pb-5">
            <router-view></router-view>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Form-->

        <!--begin::Footer-->
        <div class="d-flex flex-row flex-stack mt-20 w-100">
          <!--begin::Language-->
          <DropLanguage />
          <!--end::Language-->
          <!--begin::Terms-->
          <DropTerms />
          <!--begin::Terms-->
        </div>
        <!--end::Footer-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Body-->

    <!--begin::Aside-->
    <div class="d-flex flex-lg-row-fluid order-1 order-lg-2">
      <!--begin::Content-->
      <div class="d-flex flex-column flex-center p-10 pb-0 pb-lg-10 w-100">
        <!--begin::Image-->
        <img
          class="theme-light-show mx-auto mw-100 w-100px w-lg-300px mb-10 mb-lg-20"
          :src="getAssetPath('media/auth/agency.png')"
          alt=""
        />
        <img
          class="theme-dark-show mx-auto mw-100 w-100px w-lg-300px mb-10 mb-lg-20"
          :src="getAssetPath('media/auth/agency-dark.png')"
          alt=""
        />
        <!--end::Image-->
        <!--begin::Title-->
        <h1 class="text-gray-800 fs-2qx fw-bold text-center mb-7">{{ $t('pages.authentication.title')}}</h1>
        <!--end::Title-->
        <!--begin::Text-->
        <div class="text-gray-600 fs-base text-center w-md-600px w-lg-auto w-xl-600px  d-none d-md-flex">{{ $t('pages.authentication.subtitle')}}</div>
        <!--end::Text-->
      </div>
      <!--end::Content-->
    </div>
    <!--begin::Aside-->
  </div>
  <!--end::Authentication Layout -->
</template>

<script lang="ts">
import { defineComponent, onMounted, nextTick } from "vue";
import { getAssetPath } from "@/core/helpers/assets";
import LayoutService from "@/core/services/LayoutService";
import { useBodyStore } from "@/stores/body";
import { reinitializeComponents } from "@/core/plugins/keenthemes";

import DropLanguage from "@/components/dropdown/DropLanguage.vue";
import DropTerms from "@/components/dropdown/DropTerms.vue";

export default defineComponent({
  name: "auth-layout",
  components: {
    DropLanguage,
    DropTerms,
  },
  setup() {
    const bodyStore = useBodyStore();

    onMounted(() => {
      LayoutService.emptyElementClassesAndAttributes(document.body);

      bodyStore.addBodyClassname("app-blank");
      bodyStore.addBodyClassname("bg-body");
      
      nextTick(() => {
        reinitializeComponents();
      });
    });

    return {
      getAssetPath,
    };
  },
});
</script>
