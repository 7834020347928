<template>
  <span class="text-nowrap">{{ formattedDate }}</span>
</template>

<script lang="ts" setup>
/*
Аналог Bitrix class FormatDate
https://dev.1c-bitrix.ru/api_help/main/functions/date/formatdate.php
*/

import { computed, defineProps } from 'vue';

interface Props {
  format: string;
  timestamp?: number | string;
  date?: string | Date;
  now?: number | string;
}

const props = defineProps<Props>();

// Вычисляем форматированную дату
const formattedDate = computed(() => {
  return formatDate(props.format, props.timestamp, props.date, props.now ?? Date.now());
});

/**
 * Форматирует дату согласно заданному формату
 */
function formatDate(format: string, timestamp?: number | string, date?: string | Date, now: number | string = Date.now()): string {
  let finalTimestamp;

  // Если timestamp передан, преобразуем его в число
  if (timestamp) {
    finalTimestamp = Number(timestamp);
  }
  // Если дата передана как строка в формате "DD.MM.YYYY HH:MM:SS"
  else if (typeof date === 'string' && date.match(/^\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}:\d{2}$/)) {
    const [datePart, timePart] = date.split(' ');
    const [day, month, year] = datePart.split('.').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);
    finalTimestamp = new Date(year, month - 1, day, hours, minutes, seconds).getTime();
  }
  // Если дата уже объект или другая строка
  else {
    finalTimestamp = new Date(date!).getTime();
  }

  const dateObj = new Date(finalTimestamp);
  const currentDate = new Date(Number(now));
  const diffSeconds = Math.floor((currentDate.getTime() - dateObj.getTime()) / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);

  // Приведение дат к полуночи для точного подсчета дней
  const dateAtMidnight = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate());
  const currentDateAtMidnight = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  const diffTime = currentDateAtMidnight.getTime() - dateAtMidnight.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffMonths / 12);

  const replacements: { [key: string]: string } = {
    'sago': `${diffSeconds} ${getDeclension(diffSeconds, 'секунду', 'секунды', 'секунд')} назад`,
    'sdiff': `${diffSeconds} ${getDeclension(diffSeconds, 'секунда', 'секунды', 'секунд')}`,
    'iago': `${diffMinutes} ${getDeclension(diffMinutes, 'минуту', 'минуты', 'минут')} назад`,
    'idiff': `${diffMinutes} ${getDeclension(diffMinutes, 'минута', 'минуты', 'минут')}`,
    'isago': `${diffMinutes} ${getDeclension(diffMinutes, 'минуту', 'минуты', 'минут')} ${diffSeconds % 60} ${getDeclension(diffSeconds % 60, 'секунду', 'секунды', 'секунд')} назад`,
    'Hago': `${diffHours} ${getDeclension(diffHours, 'час', 'часа', 'часов')} назад`,
    'Hdiff': `${diffHours} ${getDeclension(diffHours, 'час', 'часа', 'часов')}`,
    'dago': `${diffDays} ${getDeclension(diffDays, 'день', 'дня', 'дней')} назад`,
    'ddiff': `${diffDays} ${getDeclension(diffDays, 'день', 'дня', 'дней')}`,
    'mdiff': `${diffMonths} ${getDeclension(diffMonths, 'месяц', 'месяца', 'месяцев')}`,
    'Ydiff': `${diffYears} ${getDeclension(diffYears, 'год', 'года', 'лет')}`,
    'F': dateObj.toLocaleString('ru-RU', { month: 'long' }),
    'f': dateObj.toLocaleString('ru-RU', { month: 'long' }).charAt(0).toUpperCase() + dateObj.toLocaleString('ru-RU', { month: 'long' }).slice(1),
    'M': dateObj.toLocaleString('ru-RU', { month: 'short' }),
    'l': dateObj.toLocaleString('ru-RU', { weekday: 'long' }),
    'D': dateObj.toLocaleString('ru-RU', { weekday: 'short' }),
    'x': getXFormat(diffSeconds, diffMinutes, diffHours, diffDays, dateObj),
    'X': getXFormat(diffSeconds, diffMinutes, diffHours, diffDays, dateObj, false),
    'Q': getQFormat(diffDays, diffMonths, diffYears)
  };

  return format.replace(/(sago|sdiff|iago|idiff|isago|Hago|Hdiff|yesterday|today|dago|ddiff|mdiff|Ydiff|F|f|M|l|D|x|X|Q)/g, (match) => replacements[match] || match)
    .replace(/([Yy]+|[mM]+|[dD]+|[Hh]+|[iI]+|[sS]+|j)/g, (match) => {
      switch (match) {
        case 'Y': return dateObj.getFullYear().toString();
        case 'y': return String(dateObj.getFullYear()).slice(-2);
        case 'm': return String(dateObj.getMonth() + 1).padStart(2, '0');
        case 'M': return dateObj.toLocaleString('ru-RU', { month: 'short' });
        case 'd': return String(dateObj.getDate()).padStart(2, '0');
        case 'j': return String(dateObj.getDate()); // Новый формат 'j' — день без ведущего нуля
        case 'D': return dateObj.toLocaleString('ru-RU', { weekday: 'short' });
        case 'H': return String(dateObj.getHours()).padStart(2, '0');
        case 'h': return String(dateObj.getHours() % 12 || 12).padStart(2, '0');
        case 'i': return String(dateObj.getMinutes()).padStart(2, '0');
        case 's': return String(dateObj.getSeconds()).padStart(2, '0');
        default: return match;
      }
    });
}

/**
 * Возвращает форматированное время для X формата
 */
function getXFormat(diffSeconds: number, diffMinutes: number, diffHours: number, diffDays: number, dateObj: Date, withTime = true): string {
  const now = new Date();
  const isToday = now.toDateString() === dateObj.toDateString();
  const isYesterday = new Date(now.setDate(now.getDate() - 1)).toDateString() === dateObj.toDateString();

  if (isToday) {
    return withTime ? `сегодня в ${dateObj.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}` : 'сегодня';
  }

  if (isYesterday) {
    return withTime ? `вчера в ${dateObj.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}` : 'вчера';
  }

  if (diffSeconds < 60) return `${diffSeconds} ${getDeclension(diffSeconds, 'секунду', 'секунды', 'секунд')} назад`;
  if (diffMinutes < 60) return `${diffMinutes} ${getDeclension(diffMinutes, 'минуту', 'минуты', 'минут')} назад`;
  if (diffHours < 24) return `${diffHours} ${getDeclension(diffHours, 'час', 'часа', 'часов')} назад`;
  if (diffDays < 7) return `${diffDays} ${getDeclension(diffDays, 'день', 'дня', 'дней')} назад`;

  return dateObj.toLocaleDateString('ru-RU', { day: 'numeric', month: 'short' }) + (withTime ? ` в ${dateObj.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}` : '');
}

/**
 * Возвращает форматированное количество дней, месяцев или лет
 */
function getQFormat(diffDays: number, diffMonths: number, diffYears: number): string {
  if (diffDays < 30) return `${diffDays} ${getDeclension(diffDays, 'день', 'дня', 'дней')}`;
  if (diffMonths < 12) return `${diffMonths} ${getDeclension(diffMonths, 'месяц', 'месяца', 'месяцев')}`;
  return `${diffYears} ${getDeclension(diffYears, 'год', 'года', 'лет')}`;
}

/**
 * Возвращает правильное окончание для чисел
 */
function getDeclension(number: number, one: string, two: string, five: string): string {
  const n = Math.abs(number) % 100;
  const n1 = n % 10;

  if (n > 10 && n < 20) {
    return five;
  }

  if (n1 > 1 && n1 < 5) {
    return two;
  }

  if (n1 === 1) {
    return one;
  }

  return five;
}
</script>
