<template>
  <!--begin::Footer-->
  <div v-if="footerDisplay" id="kt_app_footer" class="app-footer">
    <!--begin::Footer container-->
    <div
      class="app-container d-flex flex-row flex-stack py-3"
      :class="{
        'container-fluid': footerWidthFluid,
        'container-xxl': !footerWidthFluid,
      }"
    >
      <!--begin::Copyright-->
      <div class="text-gray-600 fw-semibold">
        © 2024 {{ appName }}
      </div>
      <!--end::Copyright-->
      
      <!--begin::Terms-->
      <DropTerms />
      <!--begin::Terms-->
    </div>
    <!--end::Footer container-->
  </div>
  <!--end::Footer-->
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { footerDisplay, footerWidthFluid } from "@/layouts/default-layout/config/helper";

import DropTerms from "@/components/dropdown/DropTerms.vue";

const appName = ref(import.meta.env.VITE_APP_NAME);
</script>