import type { App } from "vue";
import type { AxiosResponse, AxiosRequestConfig } from "axios";
import axios from "axios";
import VueAxios from "vue-axios";
import qs from 'qs';
import { useAuthStore } from "@/stores/auth";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.withCredentials = true;
    ApiService.vueInstance.axios.defaults.baseURL = import.meta.env.VITE_APP_API_URL;
    ApiService.setHeader();
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    const apiToken = useAuthStore().user.api_token;
    const authToken = import.meta.env.VITE_APP_API_AUTH_TOKEN;
    ApiService.vueInstance.axios.defaults.headers.common["X-API-TOKEN"] = apiToken;
    ApiService.vueInstance.axios.defaults.headers.common["X-API-KEY"] = authToken;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] = "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(resource: string, params: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, { params });
  }

  /**
   * @description Sends a GET HTTP request
   * @param {string} resource - The URL resource for the request
   * @param {any} [slug=""] - Query parameters, which can be a string, array, or object
   * @returns {Promise<AxiosResponse>} Response from the server as a promise
   */
  public static get(resource: string, slug: any = ""): Promise<AxiosResponse> {
    if (typeof slug === 'object' && slug !== null) {
      slug = qs.stringify(slug); // Используем qs для сериализации параметров
    } else if (typeof slug !== 'string') {
      slug = String(slug);
    }
    return ApiService.vueInstance.axios.get(`${resource}?${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @param headers?: any
   * @returns Promise<AxiosResponse>
   */
  public static post(resource: string, params: any, headers?: any): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {};
    if (headers) {
      config.headers = headers;
    }
    return ApiService.vueInstance.axios.post(`${resource}`, params, config);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(resource: string, slug: string, params: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(resource: string, params: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string, params: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(`${resource}`, { params });
  }
}

export default ApiService;
