import { ref, nextTick, onUnmounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";

export function useMenu() {
  const isMenuVisible = ref(false);
  const menuDropdown = ref<HTMLElement | null>(null);
  let menuInstance: MenuComponent | null = null;

  const openMenu = () => {
    isMenuVisible.value = true;
    nextTick(() => {
      initializeMenu();
    });
  };

  const initializeMenu = () => {
    MenuComponent.reinitialization();

    if (!menuDropdown.value) return;

    menuInstance = MenuComponent.getInstance(menuDropdown.value);
    if (menuInstance) {
      // Подписка на событие скрытия меню
      menuInstance.on("kt.menu.dropdown.hidden", () => {
        isMenuVisible.value = false;
      });
    }
  };

  const cleanupMenu = () => {
    if (menuInstance) {
      menuInstance.off("kt.menu.dropdown.hidden");
      menuInstance = null;
    }
  };

  onUnmounted(() => {
    cleanupMenu();
  });

  return {
    isMenuVisible,
    menuDropdown,
    openMenu,
  };
}