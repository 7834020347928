import { ref } from 'vue'

interface Toast {
  title: string
  message: string
  time: string
  type: string
}

const toasts = ref<Toast[]>([])
const position = ref('top-0 end-0') // Позиция по умолчанию - Top right

const positions = {
  'top-left': 'top-0 start-0',
  'top-center': 'top-0 start-50 translate-middle-x',
  'top-right': 'top-0 end-0',
  'middle-left': 'top-50 start-0 translate-middle-y',
  'middle-center': 'top-50 start-50 translate-middle',
  'middle-right': 'top-50 end-0 translate-middle-y',
  'bottom-left': 'bottom-0 start-0',
  'bottom-center': 'bottom-0 start-50 translate-middle-x',
  'bottom-right': 'bottom-0 end-0',
}

const addToast = (
  title: string, 
  message: string, 
  type: string = 'primary', 
  pos: keyof typeof positions = 'top-right'
) => {
  const time = new Date().toLocaleTimeString()
  toasts.value.push({ title, message, time, type })
  position.value = positions[pos]

  // Автоматическое удаление тоста через 10 секунд
  setTimeout(() => {
    toasts.value.shift()
  }, 5000)
}

const removeToast = (index: number) => {
  toasts.value.splice(index, 1)
}

export { toasts, addToast, removeToast, position }
