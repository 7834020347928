<script lang="ts" setup>
import { onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/auth";
import { addToast } from '@/utils/toastManager';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { tgAuthResult, getAuthCheckKey, type UserTG } from "@/utils/telegramAuth";

const { t } = useI18n();
const router = useRouter();
const userStore = useAuthStore();

const storedData = JSON.parse(sessionStorage.getItem('oauthData') || '{}');

// Навигация на backurl или на главную страницу
const redirectUrl = () => {
  if (storedData.backurl?.path) {
    router.push({
      path: storedData.backurl.path,
      hash: storedData.backurl.hash ? `#${storedData.backurl.hash}` : '' 
    });
  } else {
    router.push({ path: '/' });
  }
};

// Удаляем класс и элементов из sessionStorage после перехода на другую страницу
const removeLoadingClassAndSessionStorage = () => {
  document.body.classList.remove('page-loading');
  sessionStorage.removeItem('oauthData');
}

const handleAuthError = () => {
  Swal.fire({
    text: t('messages.text.error.oauth'),
    icon: 'error',
    buttonsStyling: false,
    confirmButtonText: t('messages.button.okGotIt'),
    heightAuto: false,
    customClass: {
      confirmButton: 'btn btn-danger',
    },
  });
  
  router.push({ name: "sign-in" });
  
  removeLoadingClassAndSessionStorage();
};

const handleOAuthCallback = async () => {
  try {
    if (storedData.provider === 'Telegram') {
      const data = tgAuthResult();
      const check_key = getAuthCheckKey();

      await userStore.oauthTG(data as UserTG, check_key, true);
    } else {
      // Отправляем запрос для всех остальных oauth
      await userStore.verifyOauthWithSession();
    }
    
    const error = Object.values(userStore.errors);
    if (error.length === 0) {
      // Определяем текст сообщения в зависимости от значения storedData.type
      const messageText = storedData.type === 'sign-in'
        ? t('messages.text.success.signIn')
        : t('messages.text.success.switchOnAccount', { service: storedData.provider });
      
      addToast(t('messages.any.success'), messageText, 'success');

      redirectUrl();
      removeLoadingClassAndSessionStorage();
    } else {
      handleAuthError();
    }
  } catch (error) {
    handleAuthError();
  } finally {
    removeLoadingClassAndSessionStorage();
  }
};

onMounted(() => {
  document.body.classList.add('page-loading');
  handleOAuthCallback();
});

onUnmounted(() => {
  removeLoadingClassAndSessionStorage();
});
</script>
