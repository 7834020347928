<template>
  <CookieMessage />

  <CookiePolicy />
  <PrivacyPolicy />
  <UserAgreement />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import CookieMessage from '@/components/CookieMessage.vue'
import CookiePolicy from "@/components/modals/policies/CookiePolicy.vue"
import PrivacyPolicy from "@/components/modals/policies/PrivacyPolicy.vue"
import UserAgreement from "@/components/modals/policies/UserAgreement.vue"

export default defineComponent({
  name: "global-modals-terms",
  components: {
    CookieMessage,
    CookiePolicy,
    PrivacyPolicy,
    UserAgreement,
  },
});
</script>
