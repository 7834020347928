import { createI18n } from "vue-i18n";

// Импорт локализаций
import ru from '@/locales/ru';
import en from '@/locales/en';
import de from '@/locales/de';
import cn from '@/locales/cn';

// Объединение всех сообщений локализации
const messages = {
  ru,
  en,
  de,
  cn,
};

// Установка языка по умолчанию из localStorage или "ru"
const defaultLocale = localStorage.getItem("lang") || "ru";

// Создание экземпляра i18n
const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  globalInjection: true,
  messages,
});

// Информация о локализациях и флагах
export const localization = {
  ru: {
    flag: '/media/flags/russia.svg',
    name: 'Русский',
  },
  en: {
    flag: '/media/flags/united-states.svg',
    name: 'English',
  },
  de: {
    flag: '/media/flags/germany.svg',
    name: 'Deutsch',
  },
  cn: {
    flag: '/media/flags/china.svg',
    name: '中文',
  },
};

export default i18n;
