interface UserTG {
  id: number;
  first_name: string;
  last_name?: string;
  username?: string;
  photo_url?: string;
  auth_date: number;
  hash: string;
}

export const botNameTG = import.meta.env.VITE_APP_TG_BOT_NAME;
export const linkTG = `https://t.me/${botNameTG}`;

// Метод для извлечения tgAuthResult из URL
export const tgAuthResult = () => {
  // Получаем хэш из URL
  let locationHash = window.location.hash;
  // Регулярное выражение для поиска tgAuthResult
  const re = /[#\?\&]tgAuthResult=([A-Za-z0-9\-_=]*)$/;
  const match = locationHash.match(re);

  try {
    if (match) {
      // Удаляем tgAuthResult из URL
      window.location.hash = locationHash.replace(re, ''); 
      let data = match[1] || '';

      // Преобразуем Base64 URL-safe формат в стандартный Base64
      data = data.replace(/-/g, '+').replace(/_/g, '/');
      
      // Рассчитываем, сколько символов '=' нужно добавить в конце
      const pad = data.length % 4;
      if (pad > 0) { // Исправлено: только если pad > 0, добавляем '='
        data += '='.repeat(4 - pad);
      }

      // Декодируем данные из Base64 и парсим как JSON
      return JSON.parse(window.atob(data)) as UserTG;
    }
  } catch (e) {
    // console.error("Ошибка обработки результата авторизации:", e);
  }

  return false;
};

// Метод для получения значений check_key из параметра state в URL
export const getAuthCheckKey = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const state = urlParams.get('state');

  if (state) {
    const stateParams = new URLSearchParams(state);
    const backurl = stateParams.get('backurl');

    // Проверяем параметр 'backurl' и декодируем его
    if (backurl) {
      const decodedBackurl = decodeURIComponent(decodeURIComponent(backurl));
      
      try {
        // Создаем объект URL только если 'decodedBackurl' является допустимым URL
        const backurlObj = new URL(decodedBackurl, window.location.origin);
        const returnUrl = backurlObj.searchParams.get('check_key');
                
        return returnUrl;
      } catch (error) {
        return null;
      }      
    }
  }

  return null;
};