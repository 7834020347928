<template>
  <!--begin::Modal - User Agreement-->
  <div
    class="modal fade"
    id="kt_modal_user_agreement"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content shadow-none bg-gray-200">
        <div class="modal-header justify-content-end border-bottom-0 px-lg-10 pt-lg-10 pb-5">
          <div class="btn btn-sm btn-light btn-active-light-primary ms-2"  data-bs-dismiss="modal">
            {{ $t('buttons.close') }}
          </div>
        </div>

        <div class="modal-body ff-open-sans w-100">
          <div class="d-flex flex-column align-items-center">
            <h1 class="mw-800px fs-2x text-center">
              Соглашение о пользовании информационной системой и персональным помощником Palert
            </h1>
            <div class="fs-5 text-start mw-800px mt-md-5 mt-4">
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">1. Термины и определения</h2>
                  <p>1.1. <strong>Оператор</strong> — Компания Palert, осуществляющая свою деятельность в соответствии с законодательством Российской Федерации.</p>
                  <p>1.2. <strong>Пароль</strong> — уникальная последовательность буквенно-цифровых символов, формируемая в процессе регистрации, обеспечивающая доступ к сервисам Palert.</p>
                  <p>1.3. <strong>Пользователь</strong> — физическое или юридическое лицо, желающее получить или получившее доступ к информационным системам и персональному помощнику Palert.</p>
                  <p>1.4. <strong>Регистрация</strong> — процесс ввода данных, необходимых для создания учетной записи и получения доступа к функционалу Palert.</p>
                  <p>1.5. <strong>Сервис</strong> — программное приложение Palert, обеспечивающее взаимодействие пользователей с информационными ресурсами и сервисами для отслеживания цен.</p>
                </div>
              </div>
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">2. Общие положения</h2>
                  <p>2.1. Настоящее Соглашение регулирует права и обязанности Оператора и Пользователя при использовании информационной системы и персонального помощника Palert. Использование системы начинается с момента принятия Пользователем условий Соглашения.</p>
                  <p>2.2. Сервисы Palert предоставляются для помощи пользователям в отслеживании и мониторинге цен на товары с различных маркетплейсов.</p>
                  <p>2.3. Продолжая использование системы Palert или пройдя процесс регистрации, Пользователь соглашается с условиями настоящего Соглашения в полном объеме.</p>
                  <p>2.4. Оператор оставляет за собой право вносить изменения в Соглашение без предварительного уведомления. Новая редакция Соглашения вступает в силу с момента ее публикации на официальном сайте Palert. Продолжая использовать сервисы после изменений, Пользователь соглашается с новой редакцией.</p>
                  <p>2.5. Если Пользователь не согласен с условиями Соглашения или требованиями к использованию сервисов, он не имеет права использовать информационную систему и помощника Palert.</p>
                </div>
              </div>
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">3. Регистрация и использование сервисов</h2>
                  <p>3.1. Регистрация Пользователя осуществляется путем предоставления достоверных данных, необходимых для создания учетной записи в системе Palert.</p>
                  <p>3.2. Пользователь обязуется предоставлять актуальную информацию. В случае предоставления ложных данных, Оператор имеет право заблокировать учетную запись Пользователя.</p>
                  <p>3.3. Используя сервис Palert, Пользователь соглашается на обработку своих персональных данных в соответствии с Федеральным законом "О персональных данных".</p>
                  <p>3.4. Пользователь несет ответственность за сохранность своего пароля и иных данных для доступа к сервису Palert.</p>
                  <p>3.5. В случае несанкционированного доступа или нарушения безопасности учетной записи Пользователь обязан немедленно уведомить Оператора. Оператор не несет ответственности за последствия, вызванные нарушением данной обязанности.</p>
                </div>
              </div>
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">4. Права и обязанности сторон</h2>
                  
                  <h3 class="mb-4">4.1. Пользователь обязан:</h3>
                  <p>4.1.1. Обеспечить своими силами и за свой счет доступ в информационно-телекоммуникационную сеть «Интернет», а также настройку и защиту от несанкционированного использования своего оборудования.</p>
                  <p>4.1.2. Обеспечивать сохранность пароля Пользователя и других средств идентификации Пользователя.</p>
                  <p>4.1.3. Принять полную ответственность за все обращения и действия, предпринятые через принадлежащую ему учетную запись после регистрации Пользователя.</p>
                  <p>4.1.4. Соблюдать условия настоящего Соглашения.</p>
              
                  <h3 class="mb-4">4.2. Пользователь имеет право:</h3>
                  <p>4.2.1. Использовать функциональные возможности сервисов Palert в соответствии с условиями настоящего Соглашения.</p>
                  <p>4.2.2. Направлять обращения Оператору по вопросам работы системы и технических сбоев в ее функционировании.</p>
                  <p>4.2.3. Предоставлять согласие на передачу своих данных в иные сервисы или системы, связанных с использованием услуг Palert.</p>
              
                  <h3 class="mb-4">4.3. Пользователь не вправе:</h3>
                  <p>4.3.1. Размещать контент, который нарушает законодательство Российской Федерации, права интеллектуальной собственности, содержит вредоносные программы, нарушает права других лиц или пропагандирует ненависть и дискриминацию.</p>
                  <p>4.3.2. Нарушать права третьих лиц, в том числе несовершеннолетних лиц, причиняя им вред любым способом.</p>
                  <p>4.3.3. Загружать, распространять или передавать несанкционированную рекламу, спам или контент, не имеющий отношения к функционалу системы Palert.</p>
                  <p>4.3.4. Совершать действия, направленные на нарушение работы системы, ее безопасности, а также попытки несанкционированного доступа к данным других пользователей.</p>
              
                  <h3 class="mb-4">4.4. Оператор обязан:</h3>
                  <p>4.4.1. Обеспечить функционирование сервисов Palert в соответствии с настоящим Соглашением.</p>
                  <p>4.4.2. Обеспечить безопасность данных Пользователей, сохранность и неразглашение сведений, предоставленных Пользователем.</p>
                  <p>4.4.3. В случае технических неполадок предпринимать меры по восстановлению работоспособности сервисов в максимально сжатые сроки.</p>
                  <p>4.4.4. Обеспечивать регулярное обновление системы для повышения ее функциональности и безопасности.</p>
              
                  <h3 class="mb-4">4.5. Оператор имеет право:</h3>
                  <p>4.5.1. Отклонить регистрацию Пользователя в случае предоставления недостоверной информации или нарушения условий Соглашения.</p>
                  <p>4.5.2. Приостановить предоставление услуг в случаях:</p>
                  <ul>
                    <li>Нарушения Пользователем условий настоящего Соглашения;</li>
                    <li>Возникновения непредвиденных технических сбоев или обстоятельств, связанных с безопасностью системы;</li>
                    <li>Выявления подозрительной активности со стороны Пользователя, угрожающей безопасности системы.</li>
                  </ul>
                  <p>4.5.3. Направлять Пользователю информационные уведомления, связанные с работой системы Palert, техническими обновлениями и изменениями в функциональности.</p>
                  <p>4.5.4. Привлекать третьих лиц для обеспечения функциональности системы и выполнения технических работ.</p>
                  <p>4.5.5. Проводить профилактические работы, временно приостанавливая работу системы с целью улучшения качества предоставляемых услуг.</p>
              
                  <h3 class="mb-4">4.6. Оператор не несет ответственности за:</h3>
                  <p>4.6.1. Аварии, сбои или перебои в функционировании сервисов Palert, вызванные техническими неполадками оборудования, перебоями в работе систем связи или сетей, обслуживаемых третьими лицами.</p>
                  <p>4.6.2. Ущерб, понесенный Пользователем в результате:</p>
                  <ul>
                    <li>Наличия «вирусов» и иных вредоносных программ в оборудовании, используемом Пользователем;</li>
                    <li>Нарушения Пользователем условий настоящего Соглашения;</li>
                    <li>Неправомерных действий третьих лиц, включая несанкционированный доступ к учетной записи Пользователя.</li>
                  </ul>
                  <p>4.6.3. Качество и своевременность предоставления услуг сторонними организациями и достоверность информации, предоставленной третьими лицами.</p>
                  
                  <h3 class="mb-4">4.7. Прочие положения:</h3>
                  <p>Пользователь и Оператор не несут ответственности за неисполнение обязательств в случае наступления обстоятельств непреодолимой силы, таких как стихийные бедствия, военные действия, террористические акты, перебои в энергоснабжении или другие обстоятельства, неподвластные контролю сторон.</p>
              
                </div>
              </div>
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">5. Согласие на обработку персональных данных</h2>
                  <p>5.1. Настоящим, в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от 27.07.2006 года, свободно, своей волей и в своем интересе, Пользователь выражает свое согласие на обработку его персональных данных Оператором Palert, зарегистрированным в соответствии с законодательством Российской Федерации.</p>
                  <p>5.2. Персональные данные, которые могут быть обработаны Оператором, включают (но не ограничиваются):</p>
                  <ul>
                    <li>Имя и Фамилия;</li>
                    <li>Электронный адрес;</li>
                    <li>Номер телефона;</li>
                    <li>Город проживания;</li>
                    <li>Информация о товарах, добавленных в список отслеживания;</li>
                    <li>Иные данные, предоставленные Пользователем в процессе использования сервиса.</li>
                  </ul>
                  <p>5.3. Оператор вправе совершать следующие действия с персональными данными: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), использование, блокирование, удаление и уничтожение.</p>
                  <p>5.4. Цель обработки данных: предоставление услуг в рамках функционала Palert, отправка уведомлений, касающихся отслеживаемых товаров, ответы на запросы Пользователя, а также направление информации о новых функциях и предложениях сервиса.</p>
                  <p>5.5. Обработка данных может осуществляться как с использованием автоматизированных систем, так и без их использования.</p>
                  <p>5.6. Настоящее согласие действует до момента его отзыва. Пользователь может в любой момент отозвать свое согласие на обработку данных, направив соответствующее уведомление на электронный адрес Оператора или по адресу регистрации Оператора.</p>
                  <p>5.7. В случае отзыва согласия на обработку персональных данных, Оператор вправе продолжить обработку данных без согласия Пользователя в случаях, предусмотренных законодательством Российской Федерации.</p>
                </div>
              </div>
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">6. Ответственность</h2>
                  <p>6.1. Оператор не несет ответственности за сбои, перебои или ошибки в работе системы Palert, вызванные внешними факторами, такими как проблемы с оборудованием или сетями связи, находящимися в ведении третьих лиц.</p>
                  <p>6.2. Пользователь несет полную ответственность за свои действия в системе Palert, включая предоставление ложной информации, нарушение условий настоящего Соглашения и несоблюдение конфиденциальности своих данных для входа в систему.</p>
                  <p>6.3. Оператор не несет ответственности за возможный ущерб, понесенный Пользователем в результате неправомерных действий третьих лиц, в том числе несанкционированного доступа к учетной записи Пользователя, если такие действия не стали возможными по вине Оператора.</p>
                  <p>6.4. Оператор не несет ответственности за качество или своевременность предоставления сторонними лицами услуг, связанных с использованием системы Palert, а также за достоверность предоставленной такими лицами информации.</p>
                
                </div>
              </div>
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">7. Прочие условия</h2>
                  <p>7.1. Настоящее Соглашение регулируется законодательством Российской Федерации.</p>
                  <p>7.2. Все споры и разногласия, возникающие в связи с настоящим Соглашением, разрешаются в соответствии с законодательством Российской Федерации.</p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Modal - User Agreement-->
</template>
