<template>
  <div @click="confirmAction">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { useItemStore } from "@/stores/item";
import { addToast } from '@/utils/toastManager';
import Swal from "sweetalert2/dist/sweetalert2.js";

const props = defineProps({
  itemData: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();
const emit = defineEmits(['item-clear-products']);
const itemStore = useItemStore();

const confirmAction = () => {
  Swal.fire({
    text: t('messages.text.warning.clearItems', { title: props.itemData.title }),
    icon: 'warning',
    buttonsStyling: false,
    showCancelButton: true,
    confirmButtonText: t('messages.button.clear'),
    cancelButtonText: t('messages.button.noCancel'),
    heightAuto: false,
    customClass: {
      confirmButton: 'btn btn-danger',
      cancelButton: 'btn btn-secondary',
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        await itemStore.clearItems({ id: props.itemData.id }, "clearItems");
        
        if (!Object.keys(itemStore.errors).length) {
          emit('item-clear-products', props.itemData.id);
          addToast(t('messages.any.success'), t('messages.text.success.clearItems'), 'success');
        } else {
          handleError(itemStore.errors);
        }
      } catch (error) {
        handleError(error)
      }
    }
  });
};

// Константа для обработки ошибок
const handleError = (error) => {
  Swal.fire({
    text: error,
    icon: "error",
    buttonsStyling: false,
    confirmButtonText: t('messages.button.okGotIt'),
    heightAuto: false,
    customClass: {
      confirmButton: "btn btn-danger",
    },
  }).then(() => {
    itemStore.errors = [];
  });
}
</script>
