import { EventHandlerUtil } from "../_utils";
import { useThemeStore, type Mode, type ColorMode } from "@/stores/theme";

class ThemeMode {
  menu: HTMLElement | null = null;
  element: HTMLElement | null = null;
  mediaQuery: MediaQueryList;  // Вынесли mediaQuery в свойство класса
  hasSystemModeListener: boolean = false; // Флаг для отслеживания существования слушателя

  constructor() {
    // Инициализация mediaQuery при создании экземпляра класса
    this.mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
  }

  private getParamName = (postfix: string): string => {
    const ktName = document.body.hasAttribute("data-kt-name");
    const name = ktName ? ktName + "_" : "";
    return "kt_" + name + "theme_mode_" + postfix;
  };

  public getMode = (): Mode => {
    const modeParam: string = this.getParamName("value");
    const themeMode: Mode | "" = this.getMenuMode();
    const defaultMode = "light";
    if (!localStorage.getItem(modeParam)) {
      return defaultMode;
    }

    const ls = localStorage.getItem(modeParam);
    if (ls) {
      return ls as Mode;
    }

    const dataTheme = this.element?.getAttribute("data-bs-theme");
    if (dataTheme) {
      return dataTheme as Mode;
    }

    if (!themeMode) {
      return defaultMode;
    }

    if (themeMode === "system") {
      return this.getSystemMode();
    }

    return themeMode;
  };

  public setMode = (mode: ColorMode, menuMode: Mode | ""): void => {
    // Проверка входных значений
    if (mode !== "light" && mode !== "dark") {
      return;
    }

    // Получаем имена параметров
    const modeParam: string = this.getParamName("value");
    const menuModeParam: string = this.getParamName("menu");

    // Сбрасываем режим, если системный режим был изменен
    if (menuMode === "system") {
      if (this.getSystemMode() !== mode) {
        mode = this.getSystemMode();
      }
    }

    // Проверяем режим меню
    if (!menuMode) {
      menuMode = mode;
    }

    // Читаем активное значение режима меню
    const activeMenuItem: HTMLElement | null =
      this.menu?.querySelector(
        '[data-kt-element="mode"][data-kt-value="' + menuMode + '"]'
      ) || null;

    // Включаем состояние переключения
    this.element?.setAttribute("data-kt-theme-mode-switching", "true");

    // Устанавливаем режим для целевого элемента
    this.element?.setAttribute("data-bs-theme", mode);

    // Отключаем состояние переключения
    const self = this;
    setTimeout(function () {
      self.element?.removeAttribute("data-kt-theme-mode-switching");
    }, 300);

    // Сохраняем значение режима в хранилище
    if (localStorage) {
      localStorage.setItem(modeParam, mode);
    }

    // Устанавливаем активный элемент меню
    if (activeMenuItem && localStorage) {
      localStorage.setItem(menuModeParam, menuMode);
      this.setActiveMenuItem(activeMenuItem);
    }

    // Переключаем изображения
    this.flipImages();
  };

  public getMenuMode = (): Mode | "" => {
    const menuModeParam = this.getParamName("menu");
    const menuItem = this.menu?.querySelector(
      '.active[data-kt-element="mode"]'
    );
    const dataKTValue = menuItem?.getAttribute("data-kt-value");
    if (dataKTValue) {
      return dataKTValue as Mode;
    }

    if (!menuModeParam) {
      return "";
    }

    const ls = localStorage ? localStorage.getItem(menuModeParam) : null;
    return (ls as Mode) || "";
  };

  public getSystemMode = (): ColorMode => {
    return this.mediaQuery.matches ? "dark" : "light";
  };

  private initMode = (): void => {
    this.setMode(this.getMode(), this.getMenuMode());
    this.initSystemModeListener(); // Слушатель изменений системной темы
    if (this.element) {
      EventHandlerUtil.trigger(this.element, "kt.thememode.init");
    }
  };
  
  private initSystemModeListener(): void {
    if (this.hasSystemModeListener) return; // Проверяем существование слушателя

    const updateThemeBasedOnSystem = () => {
      if (this.getMenuMode() === "system") {
        const systemMode = this.getSystemMode();
        
        this.setMode(systemMode, this.getMenuMode());

        // Обновляем состояние в Pinia Store
        const themeStore = useThemeStore();
        themeStore.updateMode(systemMode); // Используем метод для изменения только `mode`
      }
    };
  
    // Добавляем слушатель на изменение системной темы
    this.mediaQuery.addEventListener("change", updateThemeBasedOnSystem);
    
    this.hasSystemModeListener = true; // Устанавливаем флаг, что слушатель добавлен
  }
  
  private getActiveMenuItem = (): HTMLElement | null => {
    return (
      this.menu?.querySelector(
        '[data-kt-element="mode"][data-kt-value="' + this.getMenuMode() + '"]'
      ) || null
    );
  };

  private setActiveMenuItem = (item: HTMLElement): void => {
    const menuModeParam = this.getParamName("menu");
    const menuMode = item.getAttribute("data-kt-value");
    const activeItem = this.menu?.querySelector(
      '.active[data-kt-element="mode"]'
    );
    if (activeItem) {
      activeItem.classList.remove("active");
    }

    item.classList.add("active");
    if (localStorage && menuMode && menuModeParam) {
      localStorage.setItem(menuModeParam, menuMode);
    }
  };

  private handleMenu = (): void => {
    this.menu
      ?.querySelectorAll<HTMLElement>('[data-kt-element="mode"]')
      ?.forEach((item: HTMLElement) => {
        item.addEventListener("click", (e) => {
          e.preventDefault();

          const menuMode: string | null = item.getAttribute("data-kt-value");
          const mode = menuMode === "system" ? this.getSystemMode() : menuMode;

          if (mode) {
            this.setMode(mode as Mode, menuMode as Mode | "");
          }
        });
      });
  };

  public flipImages = () => {
    document
      .querySelectorAll<HTMLElement>("[data-kt-img-dark]")
      ?.forEach((item: HTMLElement) => {
        if (item.tagName === "IMG") {
          if (
            this.getMode() === "dark" &&
            item.hasAttribute("data-kt-img-dark")
          ) {
            item.setAttribute(
              "data-kt-img-light",
              item.getAttribute("src") || ""
            );
            item.setAttribute(
              "src",
              item.getAttribute("data-kt-img-dark") || ""
            );
          } else if (
            this.getMode() === "light" &&
            item.hasAttribute("data-kt-img-light")
          ) {
            item.setAttribute(
              "data-kt-img-dark",
              item.getAttribute("src") || ""
            );
            item.setAttribute(
              "src",
              item.getAttribute("data-kt-img-light") || ""
            );
          }
        } else {
          if (
            this.getMode() === "dark" &&
            item.hasAttribute("data-kt-img-dark")
          ) {
            item.setAttribute(
              "data-kt-img-light",
              item.getAttribute("src") || ""
            );
            item.style.backgroundImage =
              "url('" + item.getAttribute("data-kt-img-dark") + "')";
          } else if (
            this.getMode() === "light" &&
            item.hasAttribute("data-kt-img-light")
          ) {
            item.setAttribute(
              "data-kt-img-dark",
              item.getAttribute("src") || ""
            );
            item.style.backgroundImage =
              "url('" + item.getAttribute("data-kt-img-light") + "')";
          }
        }
      });
  };

  public on = (name: string, hander: Function) => {
    if (this.element) {
      return EventHandlerUtil.on(this.element, name, hander);
    }
  };

  public off = (name: string, handlerId: string) => {
    if (this.element) {
      return EventHandlerUtil.off(this.element, name, handlerId);
    }
  };

  public init = () => {
    this.menu = document.querySelector<HTMLElement>(
      '[data-kt-element="theme-mode-menu"]'
    );
    this.element = document.documentElement;

    this.initMode();

    if (this.menu) {
      this.handleMenu();
    }
  };
}

const ThemeModeComponent = new ThemeMode();
// Initialize app on document ready => ThemeModeComponent.init()
export { ThemeModeComponent };
