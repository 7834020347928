<template>
  <input
    ref="inputRef"
    type="text"
    :class="computedClass"
    :placeholder="computedPlaceholder"
    @change="handleChange"
    :value="formattedInputValue"
  />
</template>

<script lang="ts" setup>
import { ref, watch, computed, onMounted } from 'vue';
import { useCurrencyInput } from 'vue-currency-input';

const props = defineProps({
  modelValue: {
    type: [String, Number, null],
    required: true,
    default: ""
  },
  options: {
    type: Object,
    default: () => ({})
  },
  placeholder: {
    type: String,
    default: ''
  },
  class: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['update:modelValue', 'change']);

// Устанавливаем значения по умолчанию для options
const defaultOptions = {
  currency: 'RUB',
  locale: 'ru'
};

const mergedOptions = { ...defaultOptions, ...props.options };

const { inputRef, formattedValue, setValue } = useCurrencyInput(mergedOptions);

const computedClass = computed(() => props.class);
const computedPlaceholder = computed(() => props.placeholder);

const inputValue = ref('');

onMounted(() => {
  if (props.modelValue !== null && props.modelValue !== 0 && props.modelValue !== "") {
    setValue(props.modelValue);
    inputValue.value = formattedValue.value;
  } else {
    inputValue.value = '';
  }
});

watch(formattedValue, (newValue) => {
  emit('update:modelValue', newValue);
  emit('change', newValue);
  inputValue.value = newValue !== null && newValue !== 0 && newValue !== "" ? newValue : '';
});

watch(() => props.modelValue, (newValue) => {
  if (newValue !== formattedValue.value) {
    if (newValue !== null && newValue !== 0 && newValue !== "") {
      setValue(newValue);
      inputValue.value = newValue;
    } else {
      inputValue.value = '';
    }
  }
});

const formattedInputValue = computed(() => {
  return inputValue.value !== '' ? formattedValue.value : '';
});

function handleChange(event) {
  const value = event.target.value;
  emit('update:modelValue', value);
  emit('change', value);
}

</script>
