<template>
  <div v-if="item?.best_offer">
    <a :href="item.best_offer.link" target="_blank">
      <PriceFormatter
        :price="item.best_offer.min_price"
        :class="priceClass"
      />
    </a>
    <span class="text-muted fw-semibold d-block fs-7 mt-1">
      <DateFormatter 
        :date="item.best_offer.date"
        format="X"
        class="text-muted fw-semibold d-block fs-7"
      />
    </span>
    <div v-if="item.best_offer.rating" class="mt-2">
      <RatingStars
        v-if="item.best_offer.rating > 0"
        :rating="Number(item.best_offer.rating)"
        mode="short"
      />
    </div>
  </div>
  
  <div v-else >
    <KTIcon icon-name="arrow-mix" icon-class="fs-1 text-gray-800" />
    <div class="fs-7 text-muted">скоро</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useItemStore } from '@/stores/item';

import PriceFormatter from '@/components/formatters/PriceFormatter.vue';
import DateFormatter from '@/components/formatters/DateFormatter.vue';
import RatingStars from '@/components/RatingStars.vue';

export default defineComponent({
  name: 'best-offer',
  components: {
    PriceFormatter,
    DateFormatter,
    RatingStars,
  },
  props: {
    item: {
      type: [Object, null],
      required: true,
    },
  },
  setup(props) {
    const itemStore = useItemStore();

    // Используем функцию сравнения цены из хранилища
    const priceClass = computed(() => ({
      'fw-bold text-hover-primary d-block mb-1 fs-6': true,
      'text-gray-800': !itemStore.priceComparison(props.item),
      'text-success': itemStore.priceComparison(props.item),
    }));

    return {
      priceClass,
    };
  },
});
</script>
