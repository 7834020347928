<template>
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <div class="card-header min-h-60px">
      <h3 class="card-title fw-bold m-0">
        {{ $t('pages.account.layoutOverview.profileDetails') }}
      </h3>

      <router-link to="/account/settings" class="btn btn-sm btn-light align-self-center ">
        {{ $t('actions.edit') }}
      </router-link>
    </div>

    <div class="card-body p-9">
      <div v-for="(item, index) in profileData" :key="index">
        
        <div v-if="item.value" class="d-flex flex-stack">
          <div class="d-flex align-items-center">
            <div class="symbol me-6">
              <svg class="stroke-gray-300 fill-gray-100" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506 18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937 39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill=""></path>
                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506 18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937 39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke=""></path>
              </svg>
              <div class="symbol-badge badge top-50 start-50">
                <i :class="`ki-outline ${item.iconClass} fs-1 text-gray-500`"></i>
              </div>
            </div>
            <div class="d-flex flex-column me-5">
              <div class="fs-6 fw-semibold text-gray-500">
                {{ item.label }}
                <i
                  v-if="item.information"
                  class="ki-duotone ki-information-5 ms-1 fs-5"
                  v-tooltip data-bs-custom-class="tooltip-inverse"
                  :title="item.information"
                >
                 <span class="path1"></span>
                 <span class="path2"></span>
                 <span class="path3"></span>
                </i>
              </div>
              <div class="fs-5 text-gray-900 fw-bold">
                {{ item.value }}
                <span
                  v-if="item.badge"
                  class="badge badge-sm badge-outline badge-success ms-2"
                >{{ item.badge }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.value && index < validProfileDataLength - 1" class="separator my-5"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from "@/stores/auth";
import { useI18n } from "vue-i18n";

const userStore = useAuthStore();
const { t } = useI18n();

const profileData = [
  {
    label: t('pages.account.layoutOverview.fullName'),
    value: `${userStore.user.name ?? ''} ${userStore.user.last_name ?? ''}`,
    iconClass: 'ki-user-square'
  },
  {
    label: t('city'),
    value: userStore.user.city?.place,
    iconClass: 'ki-geolocation'
  },
  {
    label: t('pages.authentication.email'),
    value: userStore.user.email,
    iconClass: 'ki-sms'
  },
  {
    label: t('pages.account.layoutOverview.mobilePhone'),
    value: userStore.user.mobile_phone,
    iconClass: 'ki-phone',
    information: t('pages.account.layoutOverview.mobileTitle'),
    // badge: 'Verified'
  },
/*   {
    label: t('pages.account.layoutOverview.communication'),
    value: `${t('pages.authentication.email')}, ${t('messager.telegram')}`,
    iconClass: 'ki-message-notif'
  } */
];

const validProfileDataLength = profileData.filter(item => item.value).length;

</script>
