<template>
    {{ showNumber ? props.number : '' }} {{ formattedEnding }}
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue';

const props = defineProps<{
  number: number,
  showNumber?: boolean,
  single: string,
  few: string,
  many: string
}>();

const formattedEnding = computed(() => {
  const lastDigit = props.number % 10;
  const lastTwoDigits = props.number % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    return props.many;
  }

  if (lastDigit === 1) {
    return props.single;
  } else if (lastDigit >= 2 && lastDigit <= 4) {
    return props.few;
  } else {
    return props.many;
  }
});
</script>