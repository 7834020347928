<template>
  <div class="position-relative">
    <!-- Max length indicator -->
    <span v-if="showMaxLengthIndicator" :class="maxLengthClass" :style="indicatorStyle">
      {{ typedChars }}{{ separator }}{{ maxLength }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps({
  currentLength: {
    type: Number,
    required: true
  },
  maxLength: {
    type: Number,
    required: true
  },
  showOnReady: {
    type: Boolean,
    default: false
  },
  alwaysShow: {
    type: Boolean,
    default: true
  },
  threshold: {
    type: Number,
    default: 0
  },
  warningClass: {
    type: String,
    default: 'badge badge-primary'
  },
  limitReachedClass: {
    type: String,
    default: 'badge badge-danger'
  },
  separator: {
    type: String,
    default: ' / '
  },
  placement: {
    type: String,
    default: 'bottom-right-inside'
  },
  zIndex: {
    type: Number,
    default: 1099
  }
});

// Reactive state
const typedChars = ref(props.currentLength);
const showMaxLengthIndicator = ref(props.alwaysShow || false);

// Compute the class for the indicator
const maxLengthClass = computed(() => {
  return typedChars.value <= props.maxLength ? props.warningClass : props.limitReachedClass;
});

// Compute the inline styles for the placement
const indicatorStyle = computed(() => {
  return {
    position: 'absolute',
    whiteSpace: 'nowrap',
    zIndex: props.zIndex,
    ...getPlacementStyle()
  };
});

// Watch for changes in the current length and update the visibility of the indicator
watch(() => props.currentLength, (newVal) => {
  typedChars.value = newVal;
  updateIndicatorVisibility();
});

// Update indicator visibility based on the threshold and whether to always show it
const updateIndicatorVisibility = () => {
  const remainingChars = props.maxLength - typedChars.value;
  if (!props.alwaysShow && remainingChars > props.threshold) {
    showMaxLengthIndicator.value = false;
  } else {
    showMaxLengthIndicator.value = true;
  }
};

// Function to calculate position styles based on the placement
const getPlacementStyle = () => {
  switch (props.placement) {
    case 'bottom-right-inside':
      return { bottom: '0', right: '0' };
    case 'top-right-inside':
      return { top: '0', right: '0' };
    case 'bottom-left-inside':
      return { bottom: '0', left: '0' };
    case 'top-left-inside':
      return { top: '0', left: '0' };
    default:
      return { bottom: '0', right: '0' }; // Default to bottom-right-inside
  }
};

// Lifecycle hooks to manage visibility on mount
onMounted(() => {
  if (props.showOnReady) {
    showMaxLengthIndicator.value = true;
  }
});

onBeforeUnmount(() => {
  showMaxLengthIndicator.value = false;
});
</script>
