<template>
  <div class="d-flex flex-column flex-center flex-column-fluid">
    <!--begin::Content-->
    <div class="d-flex flex-column flex-center text-center p-10">
      <!--begin::Wrapper-->
      <div class="card card-flush w-lg-650px py-5">
        <!--begin::Form-->
        <VForm
          @submit="onSubmitAgreement"
          id="kt_accept_agreement"
        >
          <div class="card-body pt-10 pt-lg-20 pb-0">
            <!--begin::Logo-->
            <div class="mb-14">
              <router-link to="/">
                <img
                  :src="getAssetPath('media/logos/default.svg')"
                  class="h-40px theme-light-show"
                  alt="Palert light logo"
                /> 
                <img
                  :src="getAssetPath('media/logos/default-dark.svg')"
                  class="h-40px theme-dark-show"
                  alt="Palert dark logo"
                /> 
              </router-link>
            </div>
            <!--end::Logo-->
            
            <!--begin::Title-->
            <h1 class="fw-bolder text-gray-900 mb-4">
              {{ $t('pages.authentication.acceptAgreement.title') }}
            </h1>
            <!--end::Title-->
            
            <!--begin::Text-->
            <div class="d-flex flex-column flex-center fw-semibold fs-5 text-gray-500 mb-0">
              <div class="mb-8">
              {{ $t('pages.authentication.acceptAgreement.subtitle') }}
              </div>
              <!--begin::Input group-->
              <div class="text-start mw-400px">
                <div class="form-check form-check-custom form-check-solid">
                  <Field
                    v-model="agreementChecked"
                    class="form-check-input align-self-start me-2"
                    type="checkbox"
                    name="agreement_accepted"
                    value="1"
                  />
                  <span class="form-check-label fw-semibold text-gray-700 fs-6">
                    {{ $t('pages.authentication.signUp.textAgree')}} 
                    <span class="link-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#kt_modal_user_agreement">
                      {{ $t('pages.authentication.signUp.textTermsUserAgreement')}}
                    </span> 
                    {{ $t('pages.authentication.signUp.textAgreePrefix')}} 
                    <span class="link-primary cursor-pointer" data-bs-toggle="modal" data-bs-target="#kt_modal_privacy_policy">
                      {{ $t('pages.authentication.signUp.textTermsPrivacyPolicy')}}
                    </span>
                  </span>
                </div>
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Text-->
            
            <!--begin::Submit button-->
            <button
              type="submit"
              id="kt_accept_agreement_submit"
              class="btn btn-sm btn-primary my-10 btn-primary"
              :data-kt-indicator="isSubmitting ? 'on' : null"
              :disabled="isSubmitDisabled || isSubmitting"
            >
              <span v-if="!isSubmitting" class="indicator-label">
                {{ $t('buttons.agree') }}
              </span>
              <span v-else class="indicator-progress">
                {{ $t('buttons.wait') }}
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <!--end::Submit button-->
  
            <!--begin::Illustration-->
            <div class="mb-0">
              <img
                :src="getAssetPath('media/auth/membership.png')"
                class="mw-100 mh-300px theme-light-show"
                alt=""
              />
              <img
                :src="getAssetPath('media/auth/membership-dark.png')"
                class="mw-100 mh-300px theme-dark-show"
                alt=""
              />
            </div>
            <!--end::Illustration-->
          </div>
        </VForm>
        <!--end::Form-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script lang="ts" setup>
import { getAssetPath, getIllustrationsPath } from "@/core/helpers/assets";
import { onMounted, ref, computed, watch } from "vue";
import { Field, Form as VForm } from "vee-validate";
import { useBodyStore } from "@/stores/body";
import { useAuthStore } from "@/stores/auth";
import { themeMode } from "@/layouts/default-layout/config/helper";
import { useRouter } from "vue-router";
import LayoutService from "@/core/services/LayoutService";
import Swal from "sweetalert2/dist/sweetalert2.js";

const bodyStore = useBodyStore();
const userStore = useAuthStore();
const router = useRouter();

const isSubmitting = ref<boolean>(false);
const agreementChecked = ref<boolean>(false);

// Вычисляемое свойство для включения/выключения кнопки отправки
const isSubmitDisabled = computed(() => !agreementChecked.value);

const bgImage =
  themeMode.value !== "dark"
    ? getAssetPath("media/auth/bg5.webp")
    : getAssetPath("media/auth/bg5-dark.webp");

onMounted(() => {
  LayoutService.emptyElementClassesAndAttributes(document.body);

  bodyStore.addBodyClassname("bg-body");
  bodyStore.addBodyAttribute({
    qualifiedName: "style",
    value: `background-image: url("${bgImage}")`,
  });
  
  watch(
    () => userStore.user?.agreement_accepted,
    (newValue) => {
      if (newValue === true) {
        // Переход на страницу dashboard
        router.push({ name: "dashboard" });
      }
    },
    { immediate: true }
  );
});

const onSubmitAgreement = async (values: any) => {
  isSubmitting.value = true; // Активируем индикатор

  // Отправляем запрос принятия соглашения
  await userStore.acceptAgreement(values);

  const error = Object.values(userStore.errors);

  if (error.length === 0) {
    // Переход на страницу dashboard после принятия соглашения
    router.push({ name: "dashboard" });
  } else {
    Swal.fire({
      text: error[0] as string,
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: t('messages.button.tryAgain'),
      heightAuto: false,
      customClass: {
        confirmButton: "btn fw-semibold btn-light-danger",
      },
    }).then(() => {
      userStore.errors = {};
    });
  }

  isSubmitting.value = false; // Деактивируем индикатор
};

</script>