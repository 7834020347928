<template>
  <!--begin::Modal - Privacy Policy-->
  <div
    class="modal fade"
    id="kt_modal_privacy_policy"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content shadow-none bg-gray-200">
        <div class="modal-header justify-content-end border-bottom-0 px-lg-10 pt-lg-10 pb-5">
          <div class="btn btn-sm btn-light btn-active-light-primary ms-2"  data-bs-dismiss="modal">
            {{ $t('buttons.close') }}
          </div>
        </div>
  
        <div class="modal-body ff-open-sans w-100">
          <div class="d-flex flex-column align-items-center">
            <h1 class="mw-800px fs-2x text-center">
              Политика защиты персональной информации пользователей сайта
            </h1>
            <div class="fs-5 text-start mw-800px mt-md-5 mt-4">
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">1. Назначение и область действия</h2>
                  <p>1.1. Настоящий документ (далее – Политика) определяет цели и принципы обработки персональных данных, а также меры защиты прав субъектов персональных данных в компании Palert (далее – «Компания»).</p>
                  <p>1.2. Политика распространяется на весь персонал Компании, включая работников и лиц, заключивших иные договоры с Компанией, а также структурные подразделения.</p>
                  <p>1.3. Политика учитывается при взаимодействии Компании с партнерами, подрядчиками и контрагентами в процессе обработки данных на основании соглашений и договоров.</p>
                </div>
              </div>
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">2. Соответствие законодательству</h2>
                  <p>2.1. Политика разработана в соответствии с законодательством Российской Федерации, включая Федеральный закон №152-ФЗ «О персональных данных» (далее – 152-ФЗ).</p>
                  <p>2.2. Компания зарегистрирована в реестре операторов персональных данных Роскомнадзора. В реестре содержатся сведения о Компании, включая:</p>
                  <ul>
                    <li>Полное наименование и местонахождение;</li>
                    <li>Контактная информация для обращения по вопросам обработки данных;</li>
                    <li>Сведения о местах расположения баз данных;</li>
                    <li>Сведения о трансграничной передаче данных (при наличии);</li>
                    <li>Иные предусмотренные законом сведения.</li>
                  </ul>
                </div>
              </div>
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">3. Принципы обработки персональных данных</h2>
                  <p>3.1. Обработка персональных данных в Компании осуществляется на законной и справедливой основе, включая:</p>
                  <ul>
                    <li>Конституцию Российской Федерации;</li>
                    <li>Трудовой и Гражданский кодексы РФ;</li>
                    <li>Федеральный закон «О персональных данных» №152-ФЗ;</li>
                    <li>Иные нормативные акты и соглашения.</li>
                  </ul>
                  <p>3.2. Обработка персональных данных ограничивается достижением конкретных целей, таких как регистрация пользователей, оказание услуг, проведение маркетинговых мероприятий, а также защита законных интересов Компании.</p>
                  <p>3.3. Компания не осуществляет обработку специальных категорий персональных данных, касающихся расы, национальности, политических взглядов или религиозных убеждений.</p>
                  <p>3.4. Трансграничная передача персональных данных не осуществляется.</p>
                  <p>3.5. Компания принимает все необходимые меры для защиты персональных данных, включая физические и технические средства защиты.</p>
                </div>
              </div>
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">4. Согласие на обработку данных</h2>
                  <p>4.1. Обработка данных осуществляется на основании согласия субъекта данных. Согласие может быть выражено в форме акцепта условий использования сайта или сервисов Компании.</p>
                  <p>4.2. Субъект данных вправе отозвать согласие на обработку в любое время, отправив запрос в Компанию.</p>
                  <p>4.3. В случае отказа субъекта предоставить данные, Компания не может оказать соответствующие услуги или завершить регистрацию пользователя.</p>
                </div>
              </div>
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">5. Обработка данных пользователей, включая cookies</h2>
                  <p>5.1. Компания может собирать данные автоматически с использованием cookies для улучшения работы сайта и персонализации услуг.</p>
                  <p>5.2. Посетители сайтов Компании могут увидеть уведомление о сборе данных cookies с возможностью принятия условий обработки.</p>
                  <p>5.3. Обработка данных cookies необходима для корректной работы сайтов и обеспечения удобства использования сервисов.</p>
                  <p>5.4. Пользователь может отказаться от обработки cookies путем настройки браузера или покинув сайт.</p>
                </div>
              </div>
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">6. Права субъектов данных</h2>
                  <p>6.1. Субъекты данных имеют право на:</p>
                  <ul>
                    <li>Получение информации о факте обработки данных;</li>
                    <li>Требование уточнения, блокирования или уничтожения своих данных;</li>
                    <li>Отзыв согласия на обработку данных;</li>
                    <li>Обжалование действий Компании в Роскомнадзоре или суде.</li>
                  </ul>
                </div>
              </div>
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">7. Конфиденциальность и безопасность</h2>
                  <p>7.1. Компания принимает необходимые меры для защиты данных, включая назначение ответственных лиц, физическую и техническую защиту данных, использование шифрования и резервное копирование данных.</p>
                  <p>7.2. В случае утечки данных, Компания обязана уведомить Роскомнадзор и принять меры по устранению последствий инцидента.</p>
                </div>
              </div>
              
              <div class="card shadow-sm mb-7">
                <div class="card-body pb-3">
                  <h2 class="mb-5">8. Актуализация Политики</h2>
                  <p>8.1. Политика может быть изменена в любой момент без уведомления пользователей. Новая версия вступает в силу с момента публикации на сайте Компании.</p>
                  <p>8.2. Вопросы и замечания по Политике могут быть направлены по адресу электронной почты policy@palert.ru.</p>
                </div>
              </div>
          
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Modal - Privacy Policy-->
</template>
