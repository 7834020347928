<template>
  <div class="card min-w-150px py-3 px-4">
    <div class="mb-auto text-gray-800 fw-bold fs-6">{{ words }}</div>
    <div class="mt-3 fw-semibold text-gray-500">{{ text }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'words-card',
  props: {
    words: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
});
</script>
