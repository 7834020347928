<template>
  <Chart
    class="card mb-5 mb-xxl-8"
    :chart-update="chartUpdateCounter"
    @update-loading-status="updateLoadingStatus"
  />
  <List
    :is-loading="isLoading"
    @items-remove="itemsRemove"
  />
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useItemStore } from "@/stores/item";
import Chart from "@/components/analytics/Chart.vue";
import List from "@/components/analytics/List.vue";

const itemStore = useItemStore();

// Управляемое состояние для обновления Chart
const chartUpdateCounter = ref(0);

// Управляемое состояние загрузки
const isLoading = ref(false);

// Обработчик изменения статуса загрузки
const updateLoadingStatus = (loadingStatus: boolean) => {
  isLoading.value = loadingStatus;
};

// Метод, вызываемый при удалении элементов в List
const itemsRemove = (id?: number) => {
  chartUpdateCounter.value++;
  itemStore.fetchSearchPartData({ id });
};
</script>
