<template>
  <!--begin::User-->
  <div class="d-flex flex-column">
    <!--begin::Name-->
    <div class="d-flex align-items-center mb-2">
      <div 
        v-if="userStore.user.name || userStore.user.last_name"
        class="text-gray-800 fs-2 fw-bold me-3"
      >
        {{ userStore.user.name }} {{ userStore.user.last_name }}
      </div>
      <span
        v-if="userStore.user.subscription"
        class="badge fw-semibold fs-8 px-2 py-1"
        :class="`badge-light-${userStore.user.subscription.color}`"
      >
        {{ userStore.user.subscription.type }}
      </span>
    </div>
    <!--end::Name-->

    <!--begin::Info-->
    <div
      v-if="userStore.user.email"
      class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2"
    >
      <span class="d-flex align-items-center text-gray-500 text-hover-primary mb-2">
        <KTIcon icon-name="sms" icon-class="fs-4 me-1" />
        {{ userStore.user.email }}
      </span>
    </div>
    <!--end::Info-->
  </div>
  <!--end::User-->
</template>

<script lang="ts" setup>
import { useAuthStore } from "@/stores/auth";

const userStore = useAuthStore();
</script>